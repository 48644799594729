import {
  IResourceComponentsProps,
  useExport,
  CrudFilters,
  HttpError,
  useNotification,
} from "@pankod/refine-core";
import {
  List,
  RefreshButton,
  CreateButton,
  Table,
  useTable,
  Space,
  EditButton,
  DeleteButton,
  DateField,
  getDefaultSortOrder,
  Tabs,
  useModalForm,
  Icons,
  DatePicker,
  Form,
  Button,
  FormProps,
  MenuProps,
  FormInstance,
  Popconfirm,
} from "@pankod/refine-antd";
import { ReloadOutlined, UserOutlined } from "@ant-design/icons";
import { useGetIdentity } from "@pankod/refine-core";
import { Menu, Input, UploadProps, Modal } from "antd";
import { message, Upload } from "antd";
import { IBanner, IPostFilterVariablesBanners } from "interfaces";
import { CreateBanner } from "./create";
import { EditBanner } from "./edit";
import React, { useEffect, useState } from "react";
// import { Delete_Banner } from "../../../query";
import { Delete_Banners } from "../../../query";

const { PlusOutlined, CheckOutlined, CloseOutlined } = Icons;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;

const handleMenuClick: MenuProps["onClick"] = (e) => {
  message.info("Click on menu item.");
};

const menu = (
  <Menu
    onClick={handleMenuClick}
    items={[
      {
        label: "1st menu item",
        key: "1",
        icon: <UserOutlined />,
      },
      {
        label: "2nd menu item",
        key: "2",
        icon: <UserOutlined />,
      },
      {
        label: "3rd menu item",
        key: "3",
        icon: <UserOutlined />,
      },
    ]}
  />
);

export const BannersList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const { open, close } = useNotification();
  const userId = user?.metadata?.org_id;
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [maxViewOrder, setMaxViewOrder] = useState<any>(0);

  // const handleDelete = (data: any) => {
  //     Delete_Banner(data?.id).then((repsone) => {
  //     })
  // }

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<
    IBanner,
    HttpError,
    IPostFilterVariablesBanners
  >({
    resource: "banners",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: [
        "id",
        "view_order",
        "ob_org_id",
        { org: ["name"] },
        "customer",
        "privacy_group",
        "active",
        "url",
        "private",
        "image",
        "created_at",
        "heading",
        "description",
        "button",
        "banner_props",
        "mobile_image"
      ],
    },

    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { view_order, created_at } = params;

      filters.push(
        {
          field: "view_order",
          operator: "eq",
          value: view_order !== "" ? view_order : null,
        },
        {
          field: "created_at",
          operator: "gte",
          value: created_at ? created_at[0].toISOString() : undefined,
        },
        {
          field: "created_at",
          operator: "lte",
          value: created_at ? created_at[1].toISOString() : undefined,
        }
      );

      return filters;
    },
  });

  let datacount = tableQueryResult?.data?.total;

  const newTableProps = {
    ...tableProps,
    dataSource: tableProps.dataSource?.filter((item) => item.active),
  };

  const { formProps, modalProps, show } = useModalForm({
    resource: "banners",
    action: "create",
    redirect: false,
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "banners",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "view_order",
        "ob_org_id",
        "customer",
        "privacy_group",
        "active",
        "url",
        "private",
        "image",
        "created_at",
        "heading",
        "description",
        "button",
        "banner_props",
        "mobile_image"
      ],
    },
  });

  const { triggerExport, isLoading } = useExport<IBanner>();

  const EditableContext = React.createContext<FormInstance<any> | null>(null);
  const props: UploadProps = {
    beforeUpload: (file) => {
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSizeInBytes) {
        message.error('File size exceeds the limit (10MB).');
        return false;
      }
      return true;
    },
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  interface EditableRowProps {
    index: number;
  }

  const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const success = () => {
    Modal.success({
      content: "Successfully created banner",
    });
  };

  const handleFormSubmitted = () => setFormSubmitted(true);

  const handleCloseCreateModal = () => setCreateModalVisible(false);
  const handleCloseEditModal = () => setEditModalVisible(false);

  useEffect(() => {
    if (createModalVisible) {
      setFormSubmitted(false);
    }
  }, [createModalVisible]);

  useEffect(() => {
    if (!formSubmitted) return;

    success();

    // return () => setFormSubmitted(false)
  }, [formSubmitted]);
  const handleDelete1 = (data: any) => {
    Delete_Banners(data).then((repsone) => {
      if (repsone) {
        open({
            key: "delete_success",
            type: "success",
            message: "",
            description: "Successfully Deleted"
        });
        setTimeout(() => {
          close("delete_success");
        }, 2000);
        tableQueryResult?.refetch();
      }
    });
  };

  useEffect(() => {
    if(tableProps?.dataSource){
    const maxViewOrder = tableProps?.dataSource?.reduce((max:any, item:any) => {
      const viewOrder = item.view_order;
      return viewOrder > max ? viewOrder : max;
    }, 0);
    if(maxViewOrder){
      setMaxViewOrder(maxViewOrder)
    }
  }
  },[tableProps]); 

  return (
    <List
      title="Orderbit Banner"
      pageHeaderProps={{
        extra: [
          //  <ExportButton onClick={triggerExport} loading={isLoading} />,
          <RefreshButton
          type="primary"
          children={<div style={{ display: "flex", alignItems: "center" }}>
                            <ReloadOutlined style={{ marginRight: "5px" }} />
                            Refresh
                        </div>}
                        icon={false}
          onClick={() => tableQueryResult?.refetch()} />,
          <CreateButton
            type="primary"
            children={<div style={{ display: "flex", alignItems: "center" }}>
                    <PlusOutlined style={{ marginRight: "5px" }} />
                    Add Banners
                </div>}
                icon={false}
            resourceName="store/banners"
            onClick={
              () => setCreateModalVisible(true)
              // show()
            }
          />,
        ],
      }}
    >
      <span>Records: {datacount}</span>

      {/* {tableQueryResult?.data?.data.filter((item)=>item
            if(item.active=== true)
            )} */}

      <Table
        style={{ marginTop: "5px" }}
        {...tableProps}
        rowKey="id"
        size="small"
      >
        <Table.Column dataIndex={['banner_props', 'banner_page']} title="Banner Page" />
        <Table.Column dataIndex={['banner_props', 'banner_type']} title="Banner Type" />
        <Table.Column dataIndex={['banner_props', 'heading','title']} title="Heading" />
        <Table.Column dataIndex="view_order" title="View Order" />
        {user?.defaultRole === "admin" ? (
          <Table.Column<IBanner>
            dataIndex="ob_org_id"
            title="Org"
            render={(_, record) => record?.org?.name}
            defaultSortOrder={getDefaultSortOrder("ob_org_id", sorter)}
            sorter
          />
        ) : (
          ""
        )}
        {/* <Table.Column dataIndex={['banner_props', 'link','link_type']} title="Button" /> */}
        {/* <Table.Column dataIndex={['banner_props','redirect_url']} title="Url" /> */}
        <Table.Column
          title="Active"
          dataIndex="active"
          render={(value) =>
            value == true ? (
              <CheckOutlined style={{ color: "#6ead65" }} />
            ) : (
              <CloseOutlined style={{ color: "#ff7875" }} />
            )
          }
        />
        {/* <Table.Column dataIndex="image" title="Image"
                    render={(value) => <span style={{ overflowWrap: 'anywhere' }}>{value}</span>} /> */}
        <Table.Column
          dataIndex="created_at"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
        <Table.Column<IBanner>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                size="small"
                type="link"
                icon={null}
                recordItemId={record.id}
                resourceName="store/banners"
                onClick={() => {
                  editShow(record.id)
                  setEditModalVisible(true)
                }}
              />
              {/* <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                resourceName="store/banners"
                                recordItemId={record?.id}
                                onClick={() => handleDelete(record)}
                            /> */}
              <Popconfirm
                title="Delete?"
                onConfirm={() => handleDelete1(record.id)}
              >
                <a style={{ color: "red" }}>Delete</a>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      <CreateBanner
        modalProps={modalProps}
        tableQueryResult={tableQueryResult}
        handleFormSubmitted={handleFormSubmitted}
        closeCreateModal={handleCloseCreateModal}
        createModalVisible={createModalVisible}
        formProps={formProps}
        viewOrderMax ={maxViewOrder}
      />
      <EditBanner 
      modalProps={editModalProps}
      formProps={editFormProps}
      tableQueryResult={tableQueryResult}
      handleFormSubmitted={handleFormSubmitted}
      closeEditModal={handleCloseEditModal}
      editModalVisible={editModalVisible}
       />
    </List>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps} style={{ display: "flex" }}>
      <Form.Item label="Search" name="view_order">
        <Input
          allowClear
          placeholder="ID, Title, Content, etc."
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item
        label="Created At"
        name="created_at"
        style={{ marginLeft: "5px" }}
      >
        <RangePicker />
      </Form.Item>
      <Form.Item style={{ marginLeft: "5px", marginTop: "20px" }}>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
