import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
// import { Editor } from '@tinymce/tinymce-react';
import { Col, Row } from 'antd';
import {
  List,
} from "@pankod/refine-antd";
import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
import { message, Upload } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IProduct, IOrg, ICategory, IbrandProduct } from "interfaces";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { nhost } from "utility";
import "./pages.css"



type AppearanceProps = {
  AppearanceData?: {} | any;
};

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const onChange = (key: string) => {
};

const onChangeCheckbox = (e: CheckboxChangeEvent) => {
};

const props: UploadProps = {
  beforeUpload: (file) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSizeInBytes) {
      message.error('File size exceeds the limit (10MB).');
      return false;
    }
    return true;
  },
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
  },
};

export const Appearance: React.FC<AppearanceProps> = ({ AppearanceData }) => {
  const [form] = Form.useForm();
  const { data: user } = useGetIdentity();
  const [open, setOpen] = useState(false);
  const [faviconurlList, setFaviconUrlList] = useState([] as any[]);
  const [footericonurlList, setFooterIconUrlList] = useState([] as any[]);
  const [cataloglogourlList, setCatalogLogoUrlList] = useState([] as any[]);
  const [catalogcoverurlList, setCatalogCoverUrlList] = useState([] as any[]);
  const [pdfimagebordercolorurlList, setPdfImageBorderColorUrlList] = useState([] as any[]);
  const [pdfcategorycolorurlList, setPdfCategoryColorUrlList] = useState([] as any[]);
  const [count, setCount] = useState(0);
  const userId = user?.metadata?.org_id;
  const { selectProps: OBProductSelectProps } = useSelect<IProduct>({
    resource: "ob_products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBProductSelectBuyProps } = useSelect<IProduct>({
    resource: "products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBBrandSelectProps } = useSelect<IbrandProduct>({
    resource: "brands",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBCustomerSelectProps } = useSelect<IbrandProduct>({
    resource: "customers",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const uploadImages = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      {
        const data = new FormData()
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4")
        data.append("cloud_name", "dov89ugan")
        data.append("folder", "orderbit")
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data
        })
          .then(resp => resp.json())
          .then(data => {
            switch (count) {
              case 0:
                setCount(1);
                setFaviconUrlList(urlList => [...urlList, data.secure_url]);
                break;
              case 1:
                setCount(2);
                setFooterIconUrlList(urlList => [...urlList, data.secure_url]);
                break;
              case 2:
                setCount(3);
                setCatalogLogoUrlList(urlList => [...urlList, data.secure_url]);
                break;
              case 3:
                setCount(4);
                setCatalogCoverUrlList(urlList => [...urlList, data.secure_url]);
                break;
              case 4:
                setCount(5);
                setPdfImageBorderColorUrlList(urlList => [...urlList, data.secure_url]);
                break;
              case 5:
                setPdfCategoryColorUrlList(urlList => [...urlList, data.secure_url]);
                break;
              default:
                break;
            }
          })
          .catch(err => console.log(err));
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const apidata = (e: any) => {    
    const MUTATIONAPI = `mutation MyMutation {
        update_ob_orgs(_set: {product_view:"${e.product_view}",primary_color:"${e.primary_color}",
        primary_font:"${e.primary_font}",pdf_category_color:"${e.pdf_category_color}",
        pdf_image_border_color:"${e.pdf_image_border_color}",footer_icon:"${e.footer_icon}",
        catalog_logo:"${e.catalog_logo}",catalog_cover:"${e.catalog_cover}",show_orders:${e.show_orders == null ? false : e.show_orders},
        show_FAQ_page:${e.show_FAQ_page == null ? false : e.show_FAQ_page},
        show_aboutus_page:${e.show_aboutus_page == null ? false : e.show_aboutus_page},
        show_latest_products:${e.show_latest_products == null ? false : e.show_latest_products},show_latest_news:"${e.show_latest_news}",
        display_copyright_in_footer:"${e.display_copyright_in_footer}",customers_PDF_catalog:${e.customers_PDF_catalog == null ? false : e.customers_PDF_catalog}},
        where: { id: { _eq: "${userId}" } }) {
          affected_rows
        }
      }
      `
    const onSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI)
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 3000);
      return data
    }
    onSubmit();
  }
  const handleCancel = () => {
    setOpen(true)
  }
  return (
    <>
      <Form
        // {...formProps}
        // name="basic"
        initialValues={AppearanceData?.data?.data[0]}
        wrapperCol={{ span: 23 }}
        onFinish={(e) => {
          e.favicon = faviconurlList.join(",")
          e.footer_icon = footericonurlList.join(",")
          e.catalog_logo = cataloglogourlList.join(",")
          e.catalog_cover = catalogcoverurlList.join(",")
          e.pdf_image_border_color = pdfimagebordercolorurlList.join(",")
          e.pdf_category_color = pdfcategorycolorurlList.join(",")
          apidata(e)
        }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Product View"
              name="product_view"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select>
                <Select.Option value="product_view_list" name="product_view" >Product View List</Select.Option>
                <Select.Option value="product_view_grid" name="product_view" >Product View Grid</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="primary_color"
              label="Primary Color"
              rules={[{ required: true, message: 'Please input your primary color!' }]}>

              <Input type="color"  onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Primary Font"
              name="primary_font"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select>
                <Select.Option value="Roboto Slab" name="primary_font" >Roboto Slab</Select.Option>
                <Select.Option value="Roboto" name="primary_font" >Roboto</Select.Option>
                <Select.Option value="Helvetica" name="primary_font" >Helvetica</Select.Option>
              </Select>
            </Form.Item>
            {/* <Form.Item
              label="Favicon"
            >
              <div style={{ marginTop: '-15px' }}>
                <Dragger {...props} name="favicon" customRequest={uploadImages}
                  style={{ width: '100%', marginTop: '30px' }}>
                  <p className="ant-upload-drag-icon">
                    <PictureOutlined />
                  </p>
                  <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
                </Dragger>
              </div>
              <Form.Item
                style={{ width: '100%' }}
              >
                <h1 style={{ marginTop: '10px' }}>Uploaded image will be displayed here</h1>
                {faviconurlList.map((url) => {
                  return <img style={{ width: '10%', padding: '5px' }} src={url} />
                })}
              </Form.Item>
            </Form.Item> */}
            <Form.Item
              label="Footer_icon"
            >
              <div style={{ marginTop: '-15px' }}>
                <Dragger {...props} name="footer_icon" customRequest={uploadImages}
                  style={{ width: '100%', marginTop: '30px' }}>
                  <p className="ant-upload-drag-icon">
                    <PictureOutlined />
                  </p>
                  <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
                </Dragger>
              </div>
              <Form.Item
                style={{ width: '100%' }}
              >
                <strong style={{ marginTop: '10px' }}>Uploaded image will be displayed here</strong>
                {footericonurlList.map((url) => {
                  return <img style={{ width: '10%', padding: '5px' }} src={url} />
                })}
              </Form.Item>
            </Form.Item >
            <Form.Item name="show_orders" valuePropName="checked">
              <Checkbox name="show_orders" onChange={onChangeCheckbox}>Show Offers</Checkbox>
            </Form.Item>
            <Form.Item name="show_latest_products" valuePropName="checked">
              <Checkbox name="show_latest_products" onChange={onChangeCheckbox}>Show Latest Products</Checkbox>
            </Form.Item>
            <Form.Item name="show_latest_news" valuePropName="checked">
              <Checkbox name="show_latest_news" onChange={onChangeCheckbox}>Show Latest News</Checkbox>
            </Form.Item>
            <Form.Item name="show_FAQ_page" valuePropName="checked">
              <Checkbox name="show_FAQ_page" onChange={onChangeCheckbox}>Show FAQ's Page</Checkbox>
            </Form.Item>
            <Form.Item name="show_aboutus_page" valuePropName="checked">
              <Checkbox name="show_aboutus_page" onChange={onChangeCheckbox}>Show AboutUs Page</Checkbox>
            </Form.Item>
            <Form.Item name="display_copyright_in_footer" valuePropName="checked">
              <Checkbox name="display_copyright_in_footer" onChange={onChangeCheckbox}>Disable copyright in footer</Checkbox>
            </Form.Item>
            <p>Upgrade to a paid account to disable footer copyright notice</p>
            {/* <h4>PDF catalog settings</h4>
            <h4>Catalog Logo</h4>
            <Form.Item
              label="Catalog Logo"
            >
              <div style={{ marginTop: '-15px' }}>
                <Dragger {...props} name="catalog_logo" customRequest={uploadImages}
                  style={{ width: '100%', marginTop: '30px' }}>
                  <p className="ant-upload-drag-icon">
                    <PictureOutlined />
                  </p>
                  <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
                </Dragger>
              </div>
              <Form.Item
                style={{ width: '100%' }}
              >
                <h1 style={{ marginTop: '10px' }}>Uploaded image will be displayed here</h1>
                {cataloglogourlList.map((url) => {
                  return <img style={{ width: '10%', padding: '5px' }} src={url} />
                })}
              </Form.Item>
            </Form.Item>
            <h4>Catalog cover</h4>
            <p>Suggested_dimension [2480x2918]</p>
            <Form.Item
              label="Catalog cover"
            >
              <div style={{ marginTop: '-15px' }}>
                <Dragger {...props} name="catalog_cover" customRequest={uploadImages}
                  style={{ width: '100%', marginTop: '30px' }}>
                  <p className="ant-upload-drag-icon">
                    <PictureOutlined />
                  </p>
                  <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
                </Dragger>
              </div>
              <Form.Item
                style={{ width: '100%' }}
              >
                <h1 style={{ marginTop: '10px' }}>Uploaded image will be displayed here</h1>
                {catalogcoverurlList.map((url) => {
                  return <img style={{ width: '10%', padding: '5px' }} src={url} />
                })}
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="PDF category color"
            >
              <div style={{ marginTop: '-15px' }}>
                <Dragger {...props} name="pdf_category_color" customRequest={uploadImages}
                  style={{ width: '100%', marginTop: '30px' }}>
                  <p className="ant-upload-drag-icon">
                    <PictureOutlined />
                  </p>
                  <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
                </Dragger>
              </div>
              <Form.Item
                style={{ width: '100%' }}
              >
                <h1 style={{ marginTop: '10px' }}>Uploaded image will be displayed here</h1>
                {pdfcategorycolorurlList.map((url) => {
                  return <img style={{ width: '10%', padding: '5px' }} src={url} />
                })}
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="PDF image border color"
            >
              <div style={{ marginTop: '-15px' }}>
                <Dragger {...props} name="pdf_image_border_color" customRequest={uploadImages}
                  style={{ width: '100%', marginTop: '30px' }}>
                  <p className="ant-upload-drag-icon">
                    <PictureOutlined />
                  </p>
                  <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
                </Dragger>
              </div>
              <Form.Item
                style={{ width: '100%' }}
              >
                <h1 style={{ marginTop: '10px' }}>Uploaded image will be displayed here</h1>
                {pdfimagebordercolorurlList.map((url) => {
                  return <img style={{ width: '10%', padding: '5px' }} src={url} />
                })}
              </Form.Item>
            </Form.Item>
            <Form.Item
              name="description"
              label="Catalog footer text (character limit 200)"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <TextArea rows={4} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="customers_PDF_catalog" valuePropName="checked">
              <Checkbox name="customers_PDF_catalog" onChange={onChangeCheckbox}>Add "Click to order" link to customers' PDF catalog</Checkbox>
            </Form.Item> */}
            <Form.Item>
              <input type="submit" className="input-save" value="Save" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleCancel}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
            Successfully Updated Appearance
          </span>
        </div>
      </Modal>
    </>
  );
};