import {
  IResourceComponentsProps,
  useExport,
  useGetIdentity,
  CrudFilters,
  HttpError,
  useNotification,
} from "@pankod/refine-core";

import {
  List,
  RefreshButton,
  CreateButton,
  ExportButton,
  Table,
  useTable,
  Space,
  Button,
  EditButton,
  // ViewButton,
  DeleteButton,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  Icons,
  FormProps,
  Form,
  Input,
  Popconfirm,
  Modal,
  useSelect,
  Select,
  ShowButton,
} from "@pankod/refine-antd";
import { ICustomer, ICustomerFilterVariables } from "interfaces";
import { ViewOrderList } from "./viewOrder";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import { CreateCustomer } from "./create";
import { EditCustomer } from "./edit";
import { EditUser } from "./editUser";
import { useTranslate } from "@pankod/refine-core";
import { useEffect, useState } from "react";
import { useUserData } from "@nhost/react";
import { GET_ALL_CUSTOMERS, GET_ALL_CUSTOMER_GRPS_WITH_ORGID } from "query";
import { Delete_Customer } from "../../query";
import { useQuery } from "@apollo/client";
import { CheckCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { AuthPermission } from "utility/authPermission";
import { ShowCustomer } from "./show";
// import TimezoneSelect from 'react-timezone-select';

const { PlusOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } = Icons;

export const CustomersList: React.FC<IResourceComponentsProps> = () => {
  const userdata = useGetIdentity();

  const t = useTranslate();
  const { data: user } = useGetIdentity();
  const { open, close } = useNotification();

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [showModalVisible, setShowModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [createUserModalVisible, setCreateUserModalVisible] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [createEditModalVisible, setEditCreateModalVisible] = useState(false);
  const [EditformSubmitted, setEditFormSubmitted] = useState(false);
  const [customerGrps, setCustomerGrps] = useState<any>([]);
  const [customerId, setCustomerId] = useState<any>();
  const [opened, setOpen] = useState(false);
  const [menuPermission, setPermissions] = useState<any>(true);
  const userId = user?.metadata?.org_id;
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      AuthPermission(user?.metadata?.org_id).then((res: any) => {
        setPermissions(res);
      });
    }
  }, [user]);
  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<
    ICustomer,
    HttpError,
    ICustomerFilterVariables
  >({
    resource: "customers",
    initialPageSize: 10,
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "id",
        operator: "eq",
        value: customerId,
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "fullname",
        "address",
        "status",
        "created_at",
        "customer_org_id",
      ],
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name } = params;
      filters.push(
        {
          field: "name",
          operator: "contains",
          value: name !== "" ? name : null,
        },
        {
          field: "ob_org_id",
          operator: "eq",
          value: userId,
        }
      );
      return filters;
    },
  });

  const {
    tableProps: newtableProps,
    sorter: newSorter,
    tableQueryResult: newQuerResult,
    searchFormProps: newSearchFormProps,
  } = useTable<ICustomer, HttpError, ICustomerFilterVariables>({
    resource: "customers",
    // initialPageSize: 10,
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "id",
        operator: "eq",
        value: customerId,
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "fullname",
        "address",
        "status",
        "created_at",
        "customer_org_id",
        "city",
        "country",
      ],
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name } = params;
      filters.push(
        {
          field: "name",
          operator: "contains",
          value: name !== "" ? name : null,
        },
        {
          field: "ob_org_id",
          operator: "eq",
          value: userId,
        }
      );
      return filters;
    },
  });

  const { selectProps } = useSelect<any>({
    resource: "customers",
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });
  const [dataSource, setDataSource] = useState<
    readonly ICustomer[] | undefined
  >(undefined);
  const [newTableProps, setNewTableProps] = useState(tableProps);
  const [Allcustomers, setCustomers]: any = useState({});
  const [searchTableProps, setSearchTableProps]: any = useState([]);

  let searchedValue = searchFormProps.form?.getFieldValue("name");

  const getCustomers = async () => {
    GET_ALL_CUSTOMERS().then((response: any) => {
      setCustomers(response);
    });
  };

  const getAllCustomerGrps = () => {
    GET_ALL_CUSTOMER_GRPS_WITH_ORGID(userId).then((res: any) => {
      console.log(res, "res");
      setCustomerGrps(res?.customer_groups);
    });
  };

  useEffect(() => {
    getCustomers();
  }, []);
  useEffect(() => {
    if (userId) {
      setTimeout(() => {
        getAllCustomerGrps();
      }, 300);
    }
  }, [userId, createModalVisible, editModalVisible]);

  const allCust = Allcustomers;

  useEffect(() => {
    if (!dataSource?.length) {
      return;
    }

    if (searchedValue) {
      const newDataSource = allCust?.customers?.filter((item: any) =>
        item?.name.toLowerCase().includes(searchedValue.toLowerCase())
      );

      setNewTableProps((prev: any) => ({ ...prev, dataSource: newDataSource }));
      setSearchTableProps(newDataSource);
      return;
    }

    if (searchedValue === "") {
      setSearchTableProps([]);
      setNewTableProps(tableProps);
    }
  }, [searchFormProps?.form?.getFieldValue("name")]);
  useEffect(() => {
    if (!newTableProps.dataSource) {
      setNewTableProps(tableProps);
    }
    if (tableProps.dataSource?.length) {
      setDataSource(tableProps.dataSource);
    }
  }, [newTableProps, tableProps.dataSource?.length]);

  const { formProps, modalProps, show } = useModalForm({
    resource: "customers",
    action: "create",
    redirect: false,
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "customers",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "name",
        "address",
        "status",
        "fullname",
        "activity",
        "specify_activity",
        "email",
        "price_list",
        "discount_rate",
        "created_at",
        "min_order_value",
        "phone",
        "address_line_2",
        "city",
        "province",
        "country",
        "postal_code",
        "website",
        "company_number",
        "customer_reference_code",
        "privacy_group",
        "vat_number",
        "admin_description",
        "notify_customer_email",
        "is_active",
        "disable_ordering",
        "outgoing_email",
        "sales_rep",
        "addresses",
        "order_as_XLS",
        "order_as_PDF",
        "receive_email_notify",
        "new_order_notification",
        "bcc_outging_email",
        "customer_homepage_products",
        "is_show_price_list",
        "enable_pay_now",
        "payment_options",
        "default_tax_id",
        "discount_type",
        "sage_acc_status_number",
        "sage_acc_status",
        "credit_limit",
        "credit_position",
        "total_invoice_amount",
        "total_credit_amount",
        "total_payment_amount",
        "total_balance_amount",
        "checkout_additional_charges",
        "custom_alert",
      ],
    },
  });

  const {
    formProps: showFormProps,
    modalProps: showModalProps,
    show: showModal,
  } = useModalForm({
    resource: "customers",
    action: "clone",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "name",
        "address",
        "status",
        "fullname",
        "activity",
        "specify_activity",
        "email",
        "price_list",
        "discount_rate",
        "created_at",
        "min_order_value",
        "phone",
        "address_line_2",
        "city",
        "province",
        "country",
        "postal_code",
        "website",
        "company_number",
        "customer_reference_code",
        "privacy_group",
        "vat_number",
        "admin_description",
        "notify_customer_email",
        "is_active",
        "disable_ordering",
        "outgoing_email",
        "sales_rep",
        "addresses",
        "order_as_XLS",
        "order_as_PDF",
        "receive_email_notify",
        "new_order_notification",
        "bcc_outging_email",
        "customer_homepage_products",
        "is_show_price_list",
        "enable_pay_now",
        "payment_options",
        "default_tax_id",
        "discount_type",
        "sage_acc_status_number",
        "sage_acc_status",
        "credit_limit",
        "credit_position",
        "total_invoice_amount",
        "total_credit_amount",
        "total_payment_amount",
        "total_balance_amount",
        "checkout_additional_charges",
        "custom_alert",
      ],
    },
  });

  const {
    formProps: editUserFormProps,
    modalProps: editUserModalProps,
    show: editUserShow,
  } = useModalForm({
    resource: "customers",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "name",
        "address",
        "status",
        "fullname",
        "activity",
        "specify_activity",
        "email",
        "price_list",
        "discount_rate",
        "created_at",
        "min_order_value",
        "phone",
        "address_line_2",
        "city",
        "province",
        "country",
        "postal_code",
        "website",
        "company_number",
        "customer_reference_code",
        "privacy_group",
        "vat_number",
        "admin_description",
        "notify_customer_email",
        "is_active",
        "disable_ordering",
        "outgoing_email",
        "sales_rep",
        "addresses",
        "order_as_XLS",
        "order_as_PDF",
        "receive_email_notify",
        "new_order_notification",
        "bcc_outging_email",
        "customer_homepage_products",
        "enable_pay_now",
        "payment_options",
        "default_tax_id",
        "discount_type",
        "sage_acc_status_number",
        "sage_acc_status",
        "credit_limit",
        "credit_position",
        "total_invoice_amount",
        "total_credit_amount",
        "total_payment_amount",
        "total_balance_amount",
        "checkout_additional_charges",
      ],
    },
  });
  const { triggerExport, isLoading } = useExport<ICustomer>();
  let datacount = tableQueryResult?.data?.total;

  const handleFormSubmitted = () => setFormSubmitted(true);

  useEffect(() => {
    if (createModalVisible) {
      setFormSubmitted(false);
    }
  }, [createModalVisible]);

  useEffect(() => {
    if (createUserModalVisible) {
      setFormSubmitted(false);
    }
  }, [createUserModalVisible]);

  useEffect(() => {
    if (!formSubmitted) return;
  }, [formSubmitted]);

  const handleCloseCreateModal = () => setCreateModalVisible(false);
  const handleEditFormSubmitted = () => setEditFormSubmitted(false);
  const handleCloseEditModal = () => setEditModalVisible(false);
  const handleCloseShowModal = () => setShowModalVisible(false);

  useEffect(() => {
    if (createEditModalVisible) {
      setEditFormSubmitted(false);
    }
  }, [createEditModalVisible]);

  useEffect(() => {
    if (!EditformSubmitted) return;
  }, [EditformSubmitted]);

  const handleEditCloseCreateModal = () => setEditCreateModalVisible(false);

  const handleViewButton = () => {};

  const handleDelete = async (data: any) => {
    const findCusGrp = await customerGrps?.find((cusGrp: any) => {
      return cusGrp?.customer_ids?.includes(data);
    });
    if (findCusGrp) {
      open?.({
        key: "error",
        type: "error",
        message: "",
        description: `You have to remove this customer from ${findCusGrp?.group_name}`,
      });
      setTimeout(() => {
        close("error");
      }, 4000);
    } else {
      Delete_Customer(data).then((repsone) => {
        if (repsone) {
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 4000);
          tableQueryResult?.refetch();
        }
      });
    }
  };

  const handleSearch = (e: any) => {
    setCustomerId(e);
  };

  const handleOnClearSearch = () => {
    setCustomerId(null);
  };

  const handleRefresh = () => {
    tableQueryResult?.refetch();
    navigate(0);
  };

  return (
    <List
      title="Customers"
      pageHeaderProps={{
        extra: [
          <>
            <Form layout="vertical">
              <Form.Item label="Name">
                <Select
                  placeholder="Select a customer"
                  style={{ width: 300, marginRight: 20 }}
                  onSelect={handleSearch}
                  clearIcon="*"
                  allowClear
                  onClear={handleOnClearSearch}
                  {...selectProps}
                />
              </Form.Item>
            </Form>
            {/* <Filter formProps={searchFormProps} />, */}
            {/* //  <ExportButton onClick={triggerExport} loading={isLoading} />, */}
            <RefreshButton
              style={{ marginTop: "15px" }}
              type="primary"
              children={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ReloadOutlined style={{ marginRight: "5px" }} />
                  Refresh
                </div>
              }
              icon={false}
              onClick={handleRefresh}
            />
            {!menuPermission && (
              <CreateButton
                type="primary"
                style={{ marginTop: "15px" }}
                resourceName="customers/customer"
                children={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PlusOutlined style={{ marginRight: "5px" }} />
                    Add Customer
                  </div>
                }
                icon={false}
                // icon={<PlusOutlined />}
                onClick={() =>
                  // show()
                  setCreateModalVisible(true)
                }
              />
            )}
            ,
          </>,
        ],
      }}
    >
      <span>Records: {datacount}</span>
      {customerId ? (
        <Table {...newtableProps} pagination={false} rowKey="id" size="small">
          <Table.Column dataIndex="name" title="Name" />
          {/* <Table.Column dataIndex="fullname" title="Full Name" /> */}
          <Table.Column
            dataIndex="address"
            title="Address"
            render={(value: any, rescord: any) => {
              return (
                <pre className="table_address">
                  {value}, {rescord?.city}, {rescord?.country}
                </pre>
              );
            }}
          />
          {!menuPermission && (
            <Table.Column dataIndex="status" title="Status" />
          )}
          {!menuPermission && (
            <Table.Column
              dataIndex="created_at"
              title="Created At"
              render={(value) => <DateField value={value} format="LLL" />}
              defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
              sorter
            />
          )}
          <Table.Column<ICustomer>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {/* <Link to="viewOrder"><Button onClick={handleViewButton}>View</Button></Link> */}
                {!menuPermission && (
                  <Button
                    size="small"
                    type="link"
                    icon={null}
                    // recordItemId={record.id}
                    // resourceName="customers/customer"
                    onClick={() => {
                      localStorage.setItem(
                        "customer_org_id",
                        record.customer_org_id
                      );
                      editUserShow(record?.id);
                      setEditCreateModalVisible(true);
                    }}
                  >
                    User Invite
                  </Button>
                )}
                {!menuPermission && (
                  <EditButton
                    size="small"
                    type="link"
                    icon={null}
                    recordItemId={record.id}
                    resourceName="customers/customer"
                    onClick={() => {
                      editShow(record.id);
                      setEditModalVisible(true);
                    }}
                  />
                )}
                {menuPermission && (
                  <ShowButton
                    hideText={true}
                    type="text"
                    recordItemId={record.id}
                    resourceName="customers/customer"
                    onClick={() => {
                      showModal(record.id);
                      setShowModalVisible(true);
                    }}
                  />
                )}

                {/* <DeleteButton
                  size="small"
                  type="link"
                  icon={null}
                  resourceName="customers/customer"
                  recordItemId={record.id}
                /> */}
                {!menuPermission && (
                  <Popconfirm
                    placement="leftTop"
                    title="Delete?"
                    onConfirm={() => handleDelete(record.id)}
                  >
                    <a style={{ color: "red" }}>Delete</a>
                  </Popconfirm>
                )}
              </Space>
            )}
          />
        </Table>
      ) : (
        <Table {...tableProps} rowKey="id" size="small">
          <Table.Column dataIndex="name" title="Name" />
          {/* <Table.Column dataIndex="fullname" title="Full Name" /> */}
          <Table.Column
            dataIndex="address"
            title="Address"
            render={(value: any, rescord: any) => {
              return (
                <pre className="table_address">
                  {value} {rescord?.city} {rescord?.country}
                </pre>
              );
            }}
          />
          {!menuPermission && (
            <Table.Column dataIndex="status" title="Status" />
          )}
          {!menuPermission && (
            <Table.Column
              dataIndex="created_at"
              title="Created At"
              render={(value) => <DateField value={value} format="LLL" />}
              defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
              sorter
            />
          )}

          <Table.Column<ICustomer>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {/* <Link to="viewOrder"><Button onClick={handleViewButton}>View</Button></Link> */}
                {!menuPermission && (
                  <Button
                    size="small"
                    type="link"
                    icon={null}
                    // recordItemId={record.id}
                    // resourceName="customers/customer"
                    onClick={() => {
                      localStorage.setItem(
                        "customer_org_id",
                        record.customer_org_id
                      );
                      editUserShow(record?.id);
                      setEditCreateModalVisible(true);
                    }}
                  >
                    User Invite
                  </Button>
                )}
                {!menuPermission && (
                  <EditButton
                    size="small"
                    type="link"
                    icon={null}
                    recordItemId={record.id}
                    resourceName="customers/customer"
                    onClick={() => {
                      editShow(record.id);
                      setEditModalVisible(true);
                    }}
                  />
                )}
                {menuPermission && (
                  <ShowButton
                    hideText={true}
                    type="text"
                    recordItemId={record.id}
                    resourceName="customers/customer"
                    onClick={() => {
                      showModal(record.id);
                      setShowModalVisible(true);
                    }}
                  />
                )}

                {/* <DeleteButton
                  size="small"
                  type="link"
                  icon={null}
                  resourceName="customers/customer"
                  recordItemId={record.id}
                /> */}
                {!menuPermission && (
                  <Popconfirm
                    placement="leftTop"
                    title="Delete?"
                    onConfirm={() => handleDelete(record.id)}
                  >
                    <a style={{ color: "red" }}>Delete</a>
                  </Popconfirm>
                )}
              </Space>
            )}
          />
        </Table>
      )}
      <CreateCustomer
        tableQueryResult={tableQueryResult}
        handleFormSubmitted={handleFormSubmitted}
        closeCreateModal={handleCloseCreateModal}
        createModalVisible={createModalVisible}
        modalProps={modalProps}
        formProps={formProps}
      />
      <EditCustomer
        tableQueryResult={tableQueryResult}
        modalProps={editModalProps}
        formProps={editFormProps}
        closeEditModal={handleCloseEditModal}
        editModalVisible={editModalVisible}
      />
      <ShowCustomer
        tableQueryResult={tableQueryResult}
        modalProps={showModalProps}
        formProps={showFormProps}
        closeEditModal={handleCloseShowModal}
        editModalVisible={showModalVisible}
      />
      <EditUser
        tableQueryResult={tableQueryResult}
        handleEditFormSubmitted={handleEditFormSubmitted}
        closeEditCreateModal={handleEditCloseCreateModal}
        createEditModalVisible={createEditModalVisible}
        modalProps={editUserModalProps}
        formProps={editUserFormProps}
      />
      <Modal
        visible={opened}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        footer={null}
        width="27%"
        style={{
          position: "absolute",
          marginTop: "-5%",
          marginLeft: "70%",
          gridRow: "auto",
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>
          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%" }}>Customer has been removed</span>
        </div>
      </Modal>
      {/* <EditUser modalProps={editUserModalProps} formProps={editUserFormProps} /> */}
      {/* <Routes>
                        <Route index element={<div>Home</div>} />
                        <Route path="customer" element={<CustomersList/>} />
                        <Route path="viewOrder" element={<ViewOrderList/>} />
                        <Route path="*" element={<div>Anything</div>} />
                    </Routes> */}
    </List>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps} style={{ display: "flex" }}>
      <Form.Item label="Search" name="name">
        <Input
          allowClear
          placeholder="ID, Title, Content, etc."
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item style={{ marginLeft: "5px", marginTop: "21px" }}>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
