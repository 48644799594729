import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
// import { Editor } from '@tinymce/tinymce-react';
import { Col, Row } from 'antd';
import {
  List,
} from "@pankod/refine-antd";
import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
import { message, Upload } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IProduct, IOrg, ICategory, IbrandProduct } from "interfaces";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { nhost } from "utility";
import "./pages.css"
import { jsonConverter } from "components/helpers/jsonConverter";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';




type CreateProductProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const onChange = (e: any) => {
};

const onChangeCheckbox = (e: CheckboxChangeEvent) => {
};

interface AdderssProps {
  addressData?: {} | any
}

export const Address: React.FC<AdderssProps> = ({ addressData }) => {
  const [form] = Form.useForm();
  const { data: user } = useGetIdentity();
  const [open, setOpen] = useState(false)
  const userId = user?.metadata?.org_id;
  const [markerPosition, setMarkerPosition] = useState<any>(
    addressData?.data?.data[0]?.google_location_map ?
     { latitude: Number(addressData?.data?.data[0]?.google_location_map?.latitude), longitude: Number(addressData?.data?.data[0]?.google_location_map?.longitude) }
    : { latitude: 54.63258457050317, longitude: -2.663361179321271 });

  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyA6_5UZh5yHlZdXtsGBjSwwy7bYrYH1Qc0'
  });

  const containerStyle = {
    width: '100%',
    height: '150px',
  };

  const onMapLoad = (map:any) => {    
    setMap(map);
  };

  const onMarkerDragEnd = (event:any) => {
    const latitude = event.latLng.lat();
    const longitude = event.latLng.lng();

    setMarkerPosition({ latitude, longitude });
    form.setFieldsValue({
      // ['google_location_map.longitude']: longitude,
      // ['google_location_map.latitude']: latitude,
      ['google_location_map']: markerPosition
    });
  };
  
  const handleMapLatLonChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const value = event.target.value;
    setMarkerPosition((prevPosition:any) => ({
      ...prevPosition,
      [name]: Number(value)
    }));
  }

  const { selectProps: OBProductSelectProps } = useSelect<IProduct>({
    resource: "ob_products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBProductSelectBuyProps } = useSelect<IProduct>({
    resource: "products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBBrandSelectProps } = useSelect<IbrandProduct>({
    resource: "brands",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBCustomerSelectProps } = useSelect<IbrandProduct>({
    resource: "customers",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const apidata = (e: any) => {            
    const MUTATIONAPI = `mutation MyMutation {
        update_ob_orgs(_set: {address:"${e.address}",city:"${e.city}",postal_code:"${e.postal_code}",
        province:"${e.province}",phone:"${e.phone}",fax:"${e.fax}",
        google_location_map: ${e.google_location_map != undefined || null ? jsonConverter(e.google_location_map) : null},},
         where: { id: { _eq: "${userId}" } }) {
          affected_rows
        }
      }
      `
    const onSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI)
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 3000);
      return data
    }
    onSubmit();
  }
  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <>
      <Form
        form={form}
        // {...formProps}
        // name="basic"
        initialValues={addressData?.data?.data[0]}
        wrapperCol={{ span: 23 }}
        onFinish={(e) => {
          apidata(e)
        }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Row>
          <Col span={12}>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: 'Please input your primary color!' }]}>

              <Input onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="city"
              label="City"
              rules={[{ required: true, message: 'Please input your primary color!' }]}>

              <Input onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="postal_code"
              label="Postal code"
              rules={[{ required: true, message: 'Please input your primary color!' }]}>

              <Input onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="province"
              label="Province"
              rules={[{ required: true, message: 'Please input your primary color!' }]}>

              <Input onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Landline"
              rules={[{ required: true, message: 'Please input your primary color!' }]}>

              <Input onChange={(e) => onChange(e)} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="fax"
              label="Mobile No"
              rules={[{ required: true, message: 'Please input your primary color!' }]}>

              <Input onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <span>Google Location Map Setting</span>
            <Row>
               <Col>
               <Form.Item
              label="Latitude"
              name={["google_location_map", "latitude"]}
              >
                <Input type="number" step="0.001" onChange={(e:any) => handleMapLatLonChange(e,"latitude")}/>
              </Form.Item>
              </Col>
              <Col>
              <Form.Item
              label="Longitude"
              name={["google_location_map", "longitude"]}
              >
                <Input type="number" step="0.001" onChange={(e:any) => handleMapLatLonChange(e,"longitude")} />
              </Form.Item>
              </Col>
            </Row>
              {isLoaded ? 
              <Form.Item
                  label="Map"
                  name="google_map"
                >
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{ lat: markerPosition.latitude, lng: markerPosition.longitude }}
                    zoom={14}
                    onLoad={onMapLoad}
                  >
                    <Marker
                      position={{ lat: markerPosition.latitude, lng: markerPosition.longitude }}
                      draggable={true}
                      onDragEnd={onMarkerDragEnd}
                      visible={true}

                    />
                  </GoogleMap>
                </Form.Item>
                : <>Loading Map...</> }
            <Form.Item>
              <input type="submit" className="input-save" value="Save" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleCancel}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
            Successfully Updated Address
          </span>
        </div>
      </Modal>
    </>

  );
};
