import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    InputNumber,
  } from "@pankod/refine-antd";
  import { useGetIdentity } from "@pankod/refine-core";

  
  type CreateOrgProps = {
    modalProps: ModalProps;
    formProps: FormProps;
  };
  
  export const CreateOrg: React.FC<CreateOrgProps> = ({
    modalProps,
    formProps,
  }) => {
    const { data: user } = useGetIdentity();

    return (
      <Modal {...modalProps} title="Create Org">
        <Form {...formProps} size="large" layout="vertical">
          <Form.Item
            label="Org Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="created_by" 
          initialValue={user?.id}
          >
            <Input type="hidden"/>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  