import {
  Button,
  DateField,
  Table,
  useTable,
  Modal,
  List,
  FormProps,
  ModalProps,
  Typography
} from "@pankod/refine-antd";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import { HttpError, useGetIdentity, useList, useNavigation, useNotification, useOne, useShow } from "@pankod/refine-core";
import { Show } from "@pankod/refine-antd";
import { ICustomer, IOrder, IOrderStatus } from "interfaces";
import { Badge, Card, Space, Tag, message, Popconfirm, Spin } from "antd";

import "./style.css";
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Delete_Order, GET_ALL_TAXES, Update_Order } from "query";
import { TextArea } from "react-mde";
import { nhost } from "utility";

type orderDetails = {
  // listOrder: any;
  closeCreateModal: () => void;
  createModalVisible: boolean;
  formProps: FormProps;
  modalProps: ModalProps;
  tableShowQueryResult: any;
  updatedValue:any;
};

interface CustomerDetails {
  id: string ,
  custom_alert: string
}
const { Paragraph } = Typography;

export const OrderDetailShow: React.FC<orderDetails> = (
  {
    closeCreateModal,
    createModalVisible,
    formProps,
    modalProps,
    tableShowQueryResult,
    updatedValue,
    // modalProps,
    // formProps,
  }
) => {

  const { id } = useParams();
  const { push } = useNavigation();
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;
  const [record, setRecord] = useState<any>()
  const [isLoading, setIsLoading]: any = useState(true);  
  const [tableLoading, setTableLoading] = useState<any>(false);
  const [allTaxes, setAllTaxes] = useState<any>(null);  
  const [alertMessage, setAlertMessage] = useState("")
  const [editBtnShow,setEditBtnShow] = useState(false)
  const { open, close } = useNotification();
  const [isEditing, setIsEditing] = useState(false)
  const { tableProps: showTableProps, tableQueryResult: showTableQueryResult} =
    useTable({
      resource: "orders",
      metaData: {
        fields: [
          "id",
          "name",
          "email",
          "total_price",
          "order_Unique_id",
          "total_quantity",
          "date",
          "address",
          "phone_no",
          "delivery_Instruction",
          "items",
          "status",
          "created_at",
          "trip_id",
          "despatch_status",
          "additional_charges"
        ],
      },
      permanentFilter: [
        {
          field: "id",
          operator: "eq",
          value: record?.id,
        },
      ],
    });

  const { data: alertData, refetch } = useOne<ICustomer, HttpError>({
    resource: "customers",
    id: formProps.initialValues?.customer_id || "",
    queryOptions: {
      enabled: !!formProps.initialValues?.customer_id,
    },
    metaData: {
      fields: ["id", "custom_alert"],
  },
  });

  useEffect(()=>{
    if(alertData?.data){
      setAlertMessage(alertData?.data?.custom_alert)
    }
  }, [alertData])


    // const { data: tripData, isLoading: tripIsLoading } =
    //         useOne<any>({
    //             resource: "trip",
    //             id: formProps?.initialValues?.trip_id ? formProps?.initialValues?.trip_id : null,
    //             metaData: {
    //                 fields: ["id", "name"],
    //             },
    //         });

    

const getAllTaxes = () => {
  GET_ALL_TAXES(userId).then((res: any) => {
    setAllTaxes(res?.taxes)
  })
}
            
  const [showProps, setShowProps] = useState<any>(showTableProps);
  const [showOrder, setShowOrder] = useState<any>(showTableProps);

  useEffect(() => {
    if (showTableProps?.dataSource && showTableProps?.dataSource?.length > 0) {
      setShowProps({
        ...showTableProps,
        dataSource: showTableProps?.dataSource?.[0]?.items,
      });
      setShowOrder(showTableProps?.dataSource[0]?.additional_charges)
    }
  }, [showTableProps?.dataSource]);
  useEffect(() => {
    setTableLoading(false)
    setTimeout(() => {
      setTableLoading(true)
    }, 500);
    getAllTaxes();
    calculateVat();
  },[formProps?.initialValues])

  useEffect(() => {
  setRecord(formProps?.initialValues)
  setTimeout(() => {
    setIsLoading(false)
  },500)
  
},[formProps?.initialValues]);

useEffect(() => {
  if(updatedValue){
    const findOrder = updatedValue?.dataSource?.find((order:any) => order?.id == formProps?.initialValues?.id);
    if(findOrder){
     setRecord(findOrder)
    }
  }
},[updatedValue])

  const { tableProps, sorter, tableQueryResult } = useTable<IOrder>({
    resource: "orders",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "date",
        "email",
        "items",
        "phone_no",
        "status",
        "order_Unique_id",
        "created_at",
      ],
    },
  });

  const orderStatus = useTable<IOrderStatus>({
    resource: "order_statuses",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "sort",
        "bg_color",
        "ob_org_id",
        "notify_customer",
      ],
    },
  });

  const data: any = showTableQueryResult;
  // const record: any = data?.data?.data?.[0];
  const tableData: any = [record];
  const [allOrder, setAllOrders]: any = useState({});
  const [modelOpen, setOpen]: any = useState(false);
  const [success, setSuccess]: any = useState(false);

  const orderItemStatus = orderStatus?.tableProps?.dataSource?.filter(
    (e) => e.name.toUpperCase() === ((record?.status ?? '').toUpperCase())
  );
  
  const text = "Are you sure to Cancel this Order?";
  const confirm = "Are you sure to confirm this Order?";
  const description = "Order Cancelled";
  const description2 = "Order Completed Successfully";

  const handleDelete = (data: any) => {    
    Delete_Order(data).then((response) => {
      if (response) {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 4000);
        closeCreateModal();
        tableShowQueryResult?.refetch();
        showTableQueryResult?.refetch();
      }
    });
  };

  const handleUpdate = (data: any) => {    
    Update_Order(data).then((response) => {
      if (response) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 4000);
        closeCreateModal();
        tableShowQueryResult?.refetch();
        showTableQueryResult?.refetch();
      }
    });
  };
  
  let VAT = 0;
  const calculateVat = () => {
    VAT = 0
    formProps?.initialValues?.items?.forEach((item: any) => {
      const taxId = item.tax_id;
      const itemTotal = item?.itemTotal;
      const tax = allTaxes?.find((tax: any) => tax.id === taxId);
      if (tax) {
        const findSeparateTax = (itemTotal * (tax?.vat_rate / 100));
        if (findSeparateTax) {
          VAT += findSeparateTax;
        }
      }
    });
    return VAT
  }
  let initialValue = 0;
  const totalValue = formProps?.initialValues?.items?.reduce(function (accumulator: any, curValue: any) {
    return accumulator + curValue.itemTotal
  }, initialValue);  
  calculateVat();
  
  let total_Aomunt: number = 0
  const totalAmountCalculation = () =>{
    total_Aomunt = Number(totalValue) +Number(VAT)
    if(showOrder?.length>0){
      showOrder?.forEach((chargeData: any)=>{
        if(total_Aomunt !==0){
          
          if (chargeData.charges_type === 'amount') {
            total_Aomunt += chargeData.rate;
              return total_Aomunt
              
          } else if (chargeData.charges_type === 'percentage') {
              const percentageAmount = (chargeData.rate / 100) * total_Aomunt;
              total_Aomunt += percentageAmount;
              return total_Aomunt
          }
        }
      })
    }else{
      return total_Aomunt
    }
  }
  totalAmountCalculation()

  const handleChangeMessage = (e: any) =>{
    setAlertMessage(e)
  }

  const handleAlertUpdate = () =>{
    setIsEditing(!isEditing);
    updateAlertApi()
  }

  const handleEditButtonClick = () => {
    setIsEditing(!isEditing);
    setEditBtnShow(!editBtnShow)
  };

  const updateAlertApi = () =>{
    const Mutation = `mutation MyMutation {
      update_customers(_set: {custom_alert: "${alertMessage}"},where: { id: { _eq: "${formProps.initialValues?.customer_id}"} } ){
        affected_rows
      }
    }
    `
    nhost.graphql.request(Mutation).then((response: any)=>{
      refetch()
      setEditBtnShow(!editBtnShow)
      open?.({
        key: "success",
        type: "success",
        message: "",
        
        description: `Custom Alert has been updated`,
      });
      setTimeout(() => {
        close("success");
      }, 5000);
    }).catch((error)=>{
      console.log(error);
      
    })
  }

  return (
    <div className="Order_show">
      {/* <Show isLoading={isLoading}> */}
      
      <Modal {...modalProps}
                title={false}
                //  title="View Product"
                onCancel={closeCreateModal}
                visible={createModalVisible}
              >
      <Container fluid>
        <Row>
          {/* <div
            style={{cursor: "pointer"}}
            onClick={() => push(`/orders`)}
          >
            <ArrowLeftOutlined
            className="order_back_button"
            />
          </div> */}
          <Col xs={12} md={8} style={{ width: "100%", height: "100%" }}>
            <div
              className="orderIdSection"
              // style={{
              //   marginTop: "3%",
              // }}
            >
              {/* <Badge.Ribbon text="Hippies"> */}

              <div>
                <h3
                  className="OrderID"
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "center",
                  //   alignItems: "center",
                  // }}
                >
                  Order ID  : {" "}
                  <b
                    style={{
                      // fontFamily: "Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif",
                      color: "#4EA144",
                    }}
                  >
                    {" "}
                    {record?.order_Unique_id}
                  </b>
                  {/* {record?.status.toUpperCase() === "PREPARING" ? (
                    <Tag
                      style={{ marginLeft: "10px" }}
                      icon={
                        <SyncOutlined style={{ marginBottom: "4px" }} spin />
                      }
                      color="blue"
                    >
                      Processing
                    </Tag>
                  ) : record?.status.toUpperCase() === "CANCELLED" ? (
                    <Tag
                      style={{ marginLeft: "10px" }}
                      icon={
                        <ExclamationCircleOutlined
                          style={{ marginBottom: "4px" }}
                        />
                      }
                      color="volcano"
                    >
                      Cancelled
                    </Tag>
                  ) : (
                    <Tag
                      style={{ marginLeft: "10px" }}
                      icon={
                        <CheckCircleOutlined style={{ marginBottom: "4px" }} />
                      }
                      color="success"
                    >
                      Completed
                    </Tag>
                  )} */}
                  {/* {orderItemStatus && orderItemStatus.length > 0 && (
                    <Tag
                      style={{ marginLeft: "10px" }}
                      color={orderItemStatus[0].bg_color}
                    >
                      {orderItemStatus[0].name.charAt(0).toUpperCase() + orderItemStatus[0].name.slice(1)}
                    </Tag>
                  )} */}
                </h3>
              </div>

              {/* {record?.status === "Preparing" ? (
                <div
                  className="buttonArea"
                >
                  <Popconfirm
                    placement="bottomRight"
                    title={confirm}
                    onConfirm={() => handleUpdate(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                  </Popconfirm>
                  <Popconfirm
                    placement="bottomRight"
                    title={text}
                    onConfirm={() => handleDelete(record.id)}
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{
                        backgroundColor: "rgb(255, 106, 52)",
                        color: "white",
                        border: "10px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Decline Order
                    </Button>
                  </Popconfirm>
                </div>
              ) : null} */}

              {/* </Badge.Ribbon> */}
            </div>

            {/* <div className="customerDetails">
              <div className="rowArea row">
                <div className="detailsArea col-md-6 text-center">
                  <span className="fontStyle1 float-md-left ">
                    Customer Name :
                  </span>
                  <span className="float-md-left">
                    <span> {record?.name} </span>
                  </span>
                </div>

                <div className="detailsArea col-md-6 text-center">
                  <span className="fontStyle2 float-md-right">Date :</span>
                  <span className="float-md-right">
                    {" "}
                    <span>
                      {" "}
                      <DateField format="LL" value={record?.date} />
                    </span>
                  </span>
                </div>

                <div className="detailsArea col-md-6 text-center">
                  <span className="fontStyle3 float-md-left">
                    Delivery Notes :
                  </span>
                  <span className="float-md-left">
                    <b> </b>
                  </span>
                </div>

                <div className="detailsArea col-md-6 text-center">
                  <span className="fontStyle4 float-md-right">
                    Contact Info :
                  </span>
                  <span className="float-md-right">
                    {" "}
                    <span> {record?.phone_no} </span>
                  </span>
                </div>

                <div className="detailsArea col-md-6 text-center">
                  <span className="fontStyle5 float-md-left">Total :</span>
                  <span className="float-md-left">
                    <span> £ {(totalValue + VAT)?.toFixed(2)} </span>
                  </span>
                </div>

              </div>
            </div>
            <div className="addressDetails">
              <div className="addressRow row mt-2">
                <div className="addressArea col-md-6 text-center">
                  <span className="fontStyle6 float-md-left">Address :</span>
                  <span className="float-md-left p-6">
                    {" "}
                    {record?.address?.customers?.map((data: any) => {
                      return (
                        <span>
                          {data?.address} {data?.address_line_2} {data?.city}{" "}
                          {data?.country} {data?.postal_code}
                        </span>
                      );
                    })}
                  </span>
                </div>
              </div>
            </div> */}
            {/* <List> */}


            <div className="header_container">
              <div className="header_container_box_1">
                <table className="invoice_total_value_table">
                  <thead>
                    <tr>
                      <th className=""></th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="fontStyle1 invoice_total_content">
                        Customer Name :
                      </td>
                      <td className="invoice_total_content_value">
                      {record?.name}
                      </td>
                    </tr>
                  <tr>
                      <td className="fontStyle1">Date :</td>
                      <td className="invoice_total_content_value">
                        {
                          <DateField
                            format="LL"
                            value={record?.date}
                          />
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Address :</td>
                      <td className="invoice_total_content_value">
                        {record?.address?.customers?.map((data: any) => {
                          return (
                            <>
                              {" "}
                              <span>
                                <span>{data?.address}</span>
                                <br />
                                <span>
                                  {" "}
                                  {data?.address_line_2} {data?.city}{" "}
                                  {data?.country} {data?.postal_code}
                                </span>
                              </span>{" "}
                            </>
                          );
                        })}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="header_container_box_2">
                <table className="invoice_total_value_table">
                  <thead>
                    <tr>
                      <th className=""></th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="fontStyle1">Despatch Status :</td>
                      <td className="invoice_total_content_value">
                        {record?.despatch_status}
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Order No :</td>
                      <td className="invoice_total_content_value">
                        {record?.sage_order_ref_no}
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Delivery Notes :</td>
                      <td className="invoice_total_content_value">
                        
                        {record?.delivery_Instruction}
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Contact Info :</td>
                      <td className="invoice_total_content_value">
                      {record?.phone_no}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
             <Table
                {...showProps}
                pagination={false}
                style={{
                  // border: "2px solid gray",
                  // gridRow: "auto",
                  marginTop: "30px",
                  height: "320px",
                  overflowY: "scroll"
                }}
                rowKey="id"
                size="small"
              >
                <Table.Column
                  title="S.No"
                  dataIndex="index"
                  key="index"
                  render={(text, record, index) => index + 1}
                />
                <Table.Column dataIndex="name" title="Products" />
                <Table.Column dataIndex="price" title="Price" align="center"/>
                <Table.Column dataIndex="quantity" title="Quantity" align="center"/>
                <Table.Column dataIndex="" title="Discount" align="center"
                  render={(record) =>{
                    let discountAmount =Number((record?.quantity * record?.price)?.toFixed(2) )- Number((record?.itemTotal)?.toFixed(2)) 
                    return discountAmount?.toFixed(2)
                  }
                  }
                />
                <Table.Column
                  dataIndex="itemTotal"
                  title="Sub total"
                  align="center"
                  render={(text, record) => parseFloat(text).toFixed(2)}
                />
                <Table.Column
                  dataIndex=""
                  title="VAT"
                  align="center"
                  render={(text, record) =>
                  //    {                      
                  //   if (text !== null || text !== undefined || text?.tax_id !== null) {
                  //     const getTax = allTaxes?.map((tax: any) => {
                  //       if (tax?.id === text?.tax_id) {
                  //         return (text?.quantity * text?.price * (tax?.vat_rate / 100))?.toFixed(2);
                  //       }
                  //       return null;
                  //     });
                  //     return getTax;
                  //   } else {
                  //     return "0.00"; 
                  //   }
                  // }
                  {
                    const tax = allTaxes?.find((tax: any) => tax.id === text.tax_id);
                    if (tax) {
                      const vatAmount = (text.itemTotal * (tax.vat_rate / 100)).toFixed(2);
                      return (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          {vatAmount}
                        </div>
                      );
                    }
                    return "0.00";
                  }
                }
                />
                {showOrder?.length > 0 ? 
                  showOrder?.map((data: any)=>
                    <Table.Column title={data?.charges_name} align="center" 
                      render={(record: any) =><div style={{ display: "flex", justifyContent: "center" }}>
                        {data?.charges_type === 'amount' ? data?.rate : `${data?.rate}%`} 
                    </div> }
                    />
                  )
                : []}
              </Table>
              
              <div className={`order_header_container_box_total ${!alertMessage && "alert"}`}>
                {alertMessage &&
                  <div className="order_alert_box">
                    <div className="order_alert_content">
                      <div className="order_content_head">
                        <h5>Custom Alert</h5>
                        <div className="alert_btn">
                          <EditOutlined className="edit_btn" onClick={()=>handleEditButtonClick()} />
                          { editBtnShow &&<CheckOutlined className="tick_btn"  onClick={()=>handleAlertUpdate()}/>}
                        </div>
                      </div>
                      <Paragraph
                        editable ={{
                          enterIcon: null,
                          editing: isEditing,
                          onChange: handleChangeMessage,
                          triggerType: []
                        }}
                        className="alert_mgs"
                      >
                        {alertMessage}
                      </Paragraph>
                    </div>
                  </div>
                }
                <table className="invoice_total_value_table">
                  <thead>
                    <tr>
                      <th className=""></th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="fontStyle1">Total GBP :</td>
                      <td className="invoice_total_content_value">
                        £ {totalValue?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Total VAT :</td>
                      <td className="invoice_total_content_value">
                        £ {calculateVat()?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Garriage GBP :</td>
                      <td className="invoice_total_content_value">
                        £ {(totalValue + Number((calculateVat()).toFixed(2)))?.toFixed(2)}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="fontStyle1">Amount Due GBP :</td>
                      <td className="invoice_total_content_value">
                        £ {(totalValue + VAT)?.toFixed(2)}
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            {/* </List> */}
            <Modal
              visible={modelOpen}
              maskStyle={{ width: "0px" }}
              bodyStyle={{ height: "80px" }}
              footer={null}
              width="27%"
              style={{
                position: "absolute",
                marginTop: "-5%",
                marginLeft: "70%",
                gridRow: "auto",
              }}
            >
              <div
                style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}
              >
                <CheckCircleOutlined
                  style={{ color: "green", fontSize: "22px" }}
                />
                <span style={{ paddingLeft: "3%" }}>{description}</span>
              </div>
            </Modal>
            <Modal
              visible={success}
              maskStyle={{ width: "0px" }}
              bodyStyle={{ height: "80px" }}
              footer={null}
              width="27%"
              style={{
                position: "absolute",
                marginTop: "-5%",
                marginLeft: "70%",
                gridRow: "auto",
              }}
            >
              <div
                style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}
              >
                <CheckCircleOutlined
                  style={{ color: "green", fontSize: "22px" }}
                />
                <span style={{ paddingLeft: "3%" }}>{description2}</span>
              </div>
            </Modal>
          </Col>
        </Row>
      </Container>
      </Modal>
      {/* </Show> */}
    </div>
  );
};
