import {
  DateField,
  useTable,
  Modal,
  FormProps,
  ModalProps,
} from "@pankod/refine-antd";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useRef, useState } from "react";
import { useGetIdentity, useList } from "@pankod/refine-core";
import { IOrder, IOrg } from "interfaces";
import { Table } from "antd";
import { GET_ALL_TAXES, GET_ORDERS } from "query";
import "./invoice.css";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { UncontrolledTooltip } from "reactstrap";
// import { Image } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

type InvoiceDetails = {
  closeShowModal: () => void;
  showModalVisible: boolean;
  formProps: FormProps;
  modalProps: ModalProps;
  orders: any;
};

export const InvoiceDetailShow: React.FC<InvoiceDetails> = ({
  closeShowModal,
  showModalVisible,
  formProps,
  modalProps,
  orders,
}) => {
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;
  const custome_modify = userId === "1e84e052-4427-4808-b100-870391d93c2d" ? true : false
  const [orderItems, setOrderItems] = useState<any>([]);
  const [printTable, setPrintTAble] = useState(false);
  const [allTaxes, setAllTaxes] = useState<any>(null);
  const [allOrders, setAllOrders] = useState<any>(null);

  const { tableProps, sorter, tableQueryResult } = useTable({
    resource: "orders",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      // {
      //   field: "order_Unique_id",
      //   operator: "eq",
      //   value: formProps?.initialValues?.orderids
      // }
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "date",
        "email",
        "items",
        "phone_no",
        "status",
        "order_Unique_id",
        "created_at",
        "address",
      ],
    },
  });

  const getOrders = () => {
    GET_ORDERS(userId).then((res: any) => {
      setAllOrders(res?.orders);
    });
  };

  const orgDetails = useList<IOrg>({
    resource: "ob_orgs",
    metaData: {
      fields: [
        "primary_color",
        "logo",
        "currency",
        "address",
        "name",
        "address",
      ],
    },
    config: {
      filters: [
        {
          field: "id",
          operator: "eq",
          value: userId,
        },
      ],
    },
  });

  const companyName = orgDetails?.data?.data[0]?.name ?? "Orderbit";
  const companyAddress = orgDetails?.data?.data[0]?.address ?? ["Orderbit"];
  const primaryColour = orgDetails?.data?.data[0]?.primary_color ?? "#4EA144";
  const logo =
    orgDetails?.data?.data[0]?.logo ??
    "https://res.cloudinary.com/dov89ugan/image/upload/v1699341390/orderbit/wyjlrnkxkfajiqx55jsh.png";
  const currency = "£" ?? "£";
  console.log(orgDetails?.data?.data[0]?.address, "orgDetails?.data?.data");

  const getAllTaxes = () => {
    GET_ALL_TAXES(userId).then((res: any) => {
      setAllTaxes(res?.taxes);
    });
  };

  useEffect(() => {
    if (allOrders?.length > 0) {
      const getOrderItems = allOrders?.filter((order: any) => {
        return order?.order_Unique_id == formProps?.initialValues?.orderids;
      });
      if (getOrderItems) {
        setOrderItems(getOrderItems);
      }
    }
  }, [formProps]);

  useEffect(() => {
    if (userId) {
      getAllTaxes();
      getOrders();
    }
  }, [userId]);

  const Coloums = [
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            fontWeight: "500",
            color: "#00000078",
            fontSize: "15px",
            lineHeight: "15px",
          }}
        >
          S.No
        </div>
      ),
      key: "id",
      width: 40,
      render: (text: any, record: any, index: number) => {
        return (
          <div style={{ display: "flex", justifyContent: "left" }}>
            {index + 1}
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            fontWeight: "500",
            color: "#00000078",
            fontSize: "15px",
            lineHeight: "15px",
          }}
        >
          Product
        </div>
      ),
      dataIndex: "name",
      width: 200,
      key: "name",
      render: (value: any) => (
        <div style={{ display: "flex", justifyContent: "left" }}>{value}</div>
      ),
    },
    ...(custome_modify
      ? [
          {
            title: (
              <div style={{ display: "flex", justifyContent: "left", fontWeight: "500", color: "#00000078", fontSize: "15px", lineHeight: "15px" }}>
                Comment
              </div>
            ),
            dataIndex: "comment",
            width: 200,
            key: "comment",
            render: (value: any) => <div style={{ display: "flex", justifyContent: "left" }}>{value}</div>,
          },
        ]
      : []),
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "500",
            color: "#00000078",
            fontSize: "15px",
            lineHeight: "15px",
          }}
        >
          Quantity
        </div>
      ),
      width: 80,
      key: "quantity",
      dataIndex: "quantity",
      render: (value: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>{value}</div>
      ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "500",
            color: "#00000078",
            fontSize: "15px",
            lineHeight: "15px",
          }}
        >
          Price
        </div>
      ),
      width: 80,
      key: "price",
      dataIndex: "price",
      render: (value: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          £ {value}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "500",
            color: "#00000078",
            fontSize: "15px",
            lineHeight: "15px",
          }}
        >
          Net
        </div>
      ),
      width: 80,
      key: "status",
      dataIndex: "",
      render: (value: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          £ {value?.itemTotal?.toFixed(2)}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            fontWeight: "500",
            color: "#00000078",
            fontSize: "15px",
            lineHeight: "15px",
          }}
        >
          VAT £
        </div>
      ),
      width: 80,
      key: "tax_id",
      dataIndex: "",
      render: (value: any) => {
        const tax = allTaxes?.find((tax: any) => tax.id === value.tax_id);
        if (tax) {
          const vatAmount = (value.itemTotal * (tax.vat_rate / 100)).toFixed(2);
          return (
            <div style={{ display: "flex", justifyContent: "left" }}>
              {vatAmount}
            </div>
          );
        }
        return "0.00";
      },
    },

    ...(orderItems?.[0]?.additional_charges?.map((charge: any, index: any) => ({
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "500",
            color: "#00000078",
            fontSize: "15px",
            lineHeight: "15px",
            textTransform: "capitalize",
          }}
        >
          {charge?.charges_name}
        </div>
      ),
      width: 80,
      key: "status",
      dataIndex: "",
      render: (value: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {charge.rate}
          {charge?.charges_type === "amount" ? "£" : "%"}
        </div>
      ),
    })) ||
      [] ||
      []),
  ];

  let VAT = 0;
  const calculateVat = () => {
    VAT = 0;
    orderItems[0]?.items?.forEach((item: any) => {
      const taxId = item.tax_id;
      const itemTotal = item?.itemTotal;

      const tax = allTaxes?.find((tax: any) => tax.id === taxId);
      if (tax) {
        const findSeparateTax = itemTotal * (tax?.vat_rate / 100);
        if (findSeparateTax) {
          VAT += findSeparateTax;
        }
      }
    });
    return VAT;
  };
  calculateVat();
  let initialValue = 0;
  const totalValue = orderItems[0]?.items?.reduce(function (
    accumulator: any,
    curValue: any
  ) {
    return accumulator + curValue.itemTotal;
  },
  initialValue);

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#FFFFFF",
      borderBottom: `15px solid ${primaryColour}`,
      borderTop: `15px solid ${primaryColour}`,
    },
    viewPage: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    InvoiceHeafing: {
      marginTop: "20px",
      marginBottom: "30px",
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    invoiceHeadingText: {
      fontSize: 25,
      fontWeight: 500,
      color: primaryColour,
    },
    imageContainer: {
      width: 86,
      height: 86,
      // border: `1px solid ${primaryColour}`,
      // borderRadius: 43,
      overflow: "hidden",
    },
    image: {
      width: "100%",
      height: "100%",
    },
    invoiceDetails: {
      // marginTop: "30px",
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      gap: 10,
      // border: `3px solid ${primaryColour}`
    },
    orgDetails: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      gap: 15,
    },
    ourCompany: {
      width: "50%",
    },
    ourCompanycontent: {
      fontSize: 12,
      color: primaryColour,
      marginBottom: 5,
    },
    ourCompanyAddresss: {
      fontSize: 12,
      lineHeight: "1.5px",
    },
    customerCompany: {
      width: "50%",
    },
    customerCompanycontent: {
      fontSize: 13,
      color: primaryColour,
      marginBottom: 5,
    },
    customerCompanyAddresss: {
      fontSize: 12,
      lineHeight: "1.5px",
    },
    customerInvoice: {
      width: "40%",
      // textAlign: "center",
      flexDirection: "row",
      display: "flex",
      justifyContent: "center",
    },
    customerInvoiceContentBox: {
      width: "40%",
      textAlign: "left",
    },
    customerInvoiceHeading: {
      fontSize: 13,
      color: primaryColour,
    },
    customerInvoiceText: {
      fontSize: 12,
      marginBottom: 5,
    },
    table_main: {
      marginTop: "30px",
    },
    table_view: {
      flexDirection: "row",
    },
    table_content: {
      fontSize: 12,
      borderLeft: 1,
      borderBottom: 1,
      borderColor: "#ced0d3",
      minWidth: "35px",
      maxWidth: "35px",
      paddingLeft: 10,
      padding: 5,
      textAlign: "center",
      verticalAlign: "sub",
    },
    table_content1: {
      fontSize: 12,
      borderLeft: 1,
      borderBottom: 1,
      borderColor: "#ced0d3",
      minWidth: "70px",
      maxWidth: "70px",
      padding: 5,
      textAlign: "center",
      verticalAlign: "sub",
    },
    table_content3: {
      fontSize: 12,
      borderLeft: 1,
      borderBottom: 1,
      borderRight: 1,
      borderColor: "#ced0d3",
      minWidth: "70px",
      maxWidth: "70px",
      padding: 5,
      textAlign: "center",
      verticalAlign: "sub",
    },
    table_content2: {
      fontSize: 12,
      borderLeft: 1,
      borderBottom: 1,
      borderColor: "#ced0d3",
      minWidth: "120px",
      maxWidth: "120px",
      padding: 5,
      textAlign: "left",
      verticalAlign: "sub",
    },
    tableHeadingcontent: {
      fontSize: 12,
      borderLeft: 1,
      borderBottom: 1,
      borderColor: "#ced0d3",
      minWidth: "35px",
      maxWidth: "35px",
      paddingLeft: 10,
      padding: 5,
      textAlign: "center",
      verticalAlign: "sub",
      backgroundColor: primaryColour,
      color: "#FFFFFF",
    },
    tableHeadingcontent1: {
      fontSize: 12,
      borderLeft: 1,
      borderBottom: 1,
      borderColor: "#ced0d3",
      minWidth: "70px",
      maxWidth: "70px",
      padding: 5,
      textAlign: "center",
      verticalAlign: "sub",
      backgroundColor: primaryColour,
      color: "#FFFFFF",
    },
    tableHeadingcontent2: {
      fontSize: 12,
      borderLeft: 1,
      borderBottom: 1,
      borderColor: "#ced0d3",
      minWidth: "120px",
      maxWidth: "120px",
      padding: 5,
      textAlign: "left",
      verticalAlign: "sub",
      backgroundColor: primaryColour,
      color: "#FFFFFF",
    },
    tableHeadingcontent3: {
      fontSize: 12,
      borderLeft: 1,
      borderBottom: 1,
      borderRight: 1,
      borderColor: "#ced0d3",
      minWidth: "70px",
      maxWidth: "70px",
      padding: 5,
      textAlign: "center",
      verticalAlign: "sub",
      backgroundColor: primaryColour,
      color: "#FFFFFF",
    },
    invoiceTotal: {
      marginTop: "30px",
      fontSize: 12,
      marginRight: "3.5px",
    },
    invoiceTotanContent: {
      flexDirection: "row",
      display: "flex",
      lineHeight: "2.5px",
    },
    invoiceTotalExtraSpaceContent: {
      width: "70%",
    },
    invoiceTotalTaxString: {
      width: "30%",
      textAlign: "left",
    },
    invoiceTotalTaxValue: {
      width: "20%",
      textAlign: "right",
    },
    invoiceNotes: {
      marginTop: "30px",
    },
    invoiceNotesText: {
      fontSize: 12,
    },
    invoiceTerms: {
      marginTop: "60px",
    },
    invoiceTermsText: {
      fontSize: 12,
    },
  });

  
  const MyDocument = () => {
    return (
      <Document>
        <Page size="A4" wrap={true} style={styles.page}>
          <View style={styles.viewPage}>
            <View style={styles.InvoiceHeafing} fixed>
              <View>
                <Text style={styles.invoiceHeadingText}>INVOICE</Text>
              </View>
              <View style={styles.imageContainer}>
                {logo ? (
                  <Image style={styles.image} src={logo} />
                ) : (
                  <Image
                    style={styles.image}
                    src="https://res.cloudinary.com/dov89ugan/image/upload/v1699341390/orderbit/wyjlrnkxkfajiqx55jsh.png"
                  />
                )}
              </View>
            </View>

            <View style={styles.invoiceDetails}>
              <View style={styles.orgDetails}>
                <View style={styles.ourCompany}>
                  <Text style={styles.ourCompanycontent}>
                    {companyName?.toUpperCase()}
                  </Text>
                  <Text style={styles.ourCompanyAddresss}>
                    {/* {companyAddress} */}
                  </Text>
                </View>
                <View style={styles.customerCompany}>
                  <Text style={styles.customerCompanycontent}>Invoice To:</Text>
                  <Text style={styles.customerCompanyAddresss}>
                    {orderItems[0]?.name}
                  </Text>
                  <Text style={styles.customerCompanyAddresss}>
                    {orderItems[0]?.address?.customers?.map((data: any) => {
                      return (
                        <>
                          {data?.address}
                          {data?.address_line_2} {data?.city} {data?.country}{" "}
                          {data?.postal_code}
                        </>
                      );
                    })}
                  </Text>
                </View>

                <View style={styles.customerInvoiceContentBox}>
                  <Text style={styles.customerInvoiceHeading}>
                    Invoice Number
                  </Text>
                  <Text style={styles.customerInvoiceText}>
                    {/* {formProps?.initialValues?.orderids} */}
                    {orderItems[0]?.order_Unique_id}
                  </Text>
                  <Text style={styles.customerInvoiceHeading}>
                    Invoice Date
                  </Text>
                  <Text style={styles.customerInvoiceText}>
                    {moment(formProps?.initialValues?.created_at)
                      .utc()
                      .format("MMM Do YYYY HH:mm")}
                  </Text>
                  {/* <Text style={styles.customerInvoiceHeading}>Due Date</Text>
                <Text style={styles.customerInvoiceText}>
                  {moment(formProps?.initialValues?.created_at)
                    .utc()
                    .format("MMM Do YYYY HH:mm")}
                </Text> */}
                  <Text style={styles.customerInvoiceHeading}>
                    Invoice Status
                  </Text>
                  <Text style={styles.customerInvoiceText}>
                    {formProps?.initialValues?.status}
                  </Text>
                </View>
              </View>

              {/* <View style={styles.customerInvoice}>
              </View> */}
            </View>

            <View style={styles.table_main}>
              <View style={styles.table_view}>
                <Text style={styles.tableHeadingcontent}>S.No</Text>
                <Text style={styles.tableHeadingcontent2}>Product</Text>
                {custome_modify && <Text style={styles.tableHeadingcontent2}>Comment</Text>}
                <Text style={styles.tableHeadingcontent1}>Quantity</Text>
                <Text style={styles.tableHeadingcontent1}>Price £</Text>
                {!custome_modify &&<Text style={styles.tableHeadingcontent1}>Discount £</Text>}
                <Text style={styles.tableHeadingcontent1}>Net £</Text>
                <Text style={styles.tableHeadingcontent1}>VAT £</Text>
                {orderItems?.[0]?.additional_charges?.map(
                  (charge: any, index: any) => (
                    <Text style={styles.tableHeadingcontent3}>
                      {charge?.charges_type === "amount"
                        ? `${charge?.charges_name}£`
                        : `${charge?.charges_name}%`}
                    </Text>
                  )
                )}
              </View>
              {orderItems[0]?.items?.map((product: any, index: number) => {
                return (
                  <View style={styles.table_view}>
                    <Text style={styles.table_content}>{index + 1}</Text>
                    <Text style={styles.table_content2}>{product?.name}</Text>
                    {custome_modify &&<Text style={styles.table_content2}>{product?.comment}</Text>}
                    <Text style={styles.table_content1}>
                      {product?.quantity}
                    </Text>
                    <Text style={styles.table_content1}>{product?.price}</Text>
                    {!custome_modify &&<Text style={styles.table_content1}>
                      {(
                        product?.quantity * product?.price -
                        product?.itemTotal
                      ).toFixed(2)}
                    </Text>}
                    <Text style={styles.table_content1}>
                      {(product?.itemTotal).toFixed(2)}
                    </Text>
                    <Text style={styles.table_content3}>
                      {allTaxes?.map((tax: any) => {
                        if (tax?.id == product.tax_id) {
                          return (
                            product?.itemTotal *
                            (tax?.vat_rate / 100)
                          )?.toFixed(2);
                        }
                      })}
                    </Text>
                    {orderItems?.[0]?.additional_charges?.map(
                      (charge: any, index: any) => (
                        <Text style={styles.table_content1}>
                          {charge?.rate}
                        </Text>
                      )
                    )}
                  </View>
                );
              })}
            </View>

            <View style={styles.invoiceTotal}>
              <View style={styles.invoiceTotanContent}>
                <View style={styles.invoiceTotalExtraSpaceContent}></View>
                <View style={styles.invoiceTotalTaxString}>
                  <Text>Total GBP :</Text>
                  <Text>Total VAT :</Text>
                  <Text>Garriage GBP :</Text>
                  {orderItems?.[0]?.additional_charges?.map(
                    (charge: any, index: any) => (
                      <Text>{charge?.charges_name} :</Text>
                    )
                  )}
                  <Text>Amount Due GBP :</Text>
                </View>
                <View style={styles.invoiceTotalTaxValue}>
                  <Text>£ {totalValue?.toFixed(2)}</Text>
                  <Text>£ {calculateVat()?.toFixed(2)}</Text>
                  <Text>
                    £ {(totalValue + Number(VAT.toFixed(2)))?.toFixed(2)}
                  </Text>
                  {orderItems?.[0]?.additional_charges?.map(
                    (charge: any, index: any) => (
                      <Text>
                        {charge?.charges_type === "amount"
                          ? `${charge?.rate}£`
                          : `${charge?.rate}%`}
                      </Text>
                    )
                  )}
                  <Text>
                    £{" "}
                    {formProps?.initialValues?.outstanding?.toFixed(2) ??
                      "0.00"}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.invoiceNotes}>
              <Text style={styles.invoiceNotesText}>Notes: </Text>
            </View>

            <View style={styles.invoiceTerms}>
              <Text style={styles.invoiceTermsText}>
                Terms and Conditions:{" "}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const componentRef: any = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDual = () => {
    setPrintTAble(true);
    setTimeout(() => {
      handlePrint();
    }, 1000);
    setTimeout(() => {
      setPrintTAble(false);
    }, 3000);
  };

  const primaryColourStyle: React.CSSProperties = {
    color: primaryColour,
  };

  const primaryColourStyleforPrintPage: React.CSSProperties = {
    height: "100vh",
    borderBottom: `15px solid ${primaryColour}`,
    borderTop: `15px solid ${primaryColour}`,
  };

  return (
    <Modal
      {...modalProps}
      title="View Invoice"
      onCancel={closeShowModal}
      visible={showModalVisible}
      footer={false}
    >
      <Container fluid>
        <div className="invoice_icons">
          <div>
            <UncontrolledTooltip
              placement="top"
              target="print"
              className="Tooltip_icons"
            >
              Print
            </UncontrolledTooltip>
            <img
              style={{ width: "22px", height: "22px" }}
              src="/images/printer.png"
              alt="printer.png"
              className="fav_Print"
              id="print"
              onClick={() => handleDual()}
            />
          </div>
          <div>
            <PDFDownloadLink
              document={<MyDocument />}
              fileName={`Invoice${formProps?.initialValues?.orderids}.pdf`}
            >
              {/* <Image
                    width={25}
                    height={25}
                    className="fav_Print"
                    src="/images/pdf.png"
                    alt="export.png"
                    id="pdf"
                  /> */}
              <img
                alt="export.png"
                className="fav_Print"
                style={{ width: "22px", height: "22px" }}
                src="/images/pdf.png"
                id="pdf"
              ></img>
            </PDFDownloadLink>
            <UncontrolledTooltip
              placement="top"
              target="pdf"
              className="Tooltip_icons"
            >
              Export to pdf
            </UncontrolledTooltip>
          </div>
        </div>
        <Row>
          <Col xs={12} md={8} style={{ width: "100%", height: "100%" }}>
            <div className="header_container">
              <div className="header_container_box_1">
                <table className="invoice_total_value_table">
                  <thead>
                    <tr>
                      <th className=""></th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="fontStyle1 invoice_total_content">
                        Customer Name :
                      </td>
                      <td className="invoice_total_content_value">
                        {orderItems[0]?.name}
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Order ID :</td>
                      <td className="invoice_total_content_value">
                        {formProps?.initialValues?.orderids}
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Invoice Date :</td>
                      <td className="invoice_total_content_value">
                        {
                          <DateField
                            format="LL"
                            value={formProps?.initialValues?.created_at}
                          />
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Invoice Status :</td>
                      <td className="invoice_total_content_value">
                        {formProps?.initialValues?.status}
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Address :</td>
                      <td className="invoice_total_content_value">
                        {orderItems[0]?.address?.customers?.map((data: any) => {
                          return (
                            <>
                              {" "}
                              <span>
                                <span>{data?.address}</span>
                                <br />
                                <span>
                                  {" "}
                                  {data?.address_line_2} {data?.city}{" "}
                                  {data?.country} {data?.postal_code}
                                </span>
                              </span>{" "}
                            </>
                          );
                        })}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="header_container_box_2">
                <table className="invoice_total_value_table">
                  <thead>
                    <tr>
                      <th className=""></th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="fontStyle1">Order No :</td>
                      <td className="invoice_total_content_value">
                        {orderItems[0]?.sage_order_ref_no}
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Invoice No :</td>
                      <td className="invoice_total_content_value">
                        {/* {formProps?.initialValues?.sage_invoice_no} */}
                        {orderItems[0]?.order_Unique_id}
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Payment mode :</td>
                      {formProps?.initialValues?.is_paid ? (
                        <td
                          className="invoice_total_content_value"
                          style={{ color: "green" }}
                        >
                          {formProps?.initialValues?.paymentmode}
                        </td>
                      ) : (
                        <td className="invoice_total_content_value">
                          {formProps?.initialValues?.paymentmode}
                        </td>
                      )}
                    </tr>
                    {(formProps?.initialValues?.paymentmode?.toLowerCase() ==
                      "card payment via paypal" ||
                      formProps?.initialValues?.paymentmode?.toLowerCase() ==
                        "card payment via stripe") && (
                      <tr>
                        <td className="fontStyle1">Payment Reference :</td>
                        <td className="invoice_total_content_value">
                          {formProps?.initialValues?.paymentreference}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="invoice_items_table">
              <Table
                columns={Coloums}
                dataSource={orderItems[0]?.items}
                pagination={false}
              />
            </div>
            <div className="d-flex justify-content-end">
              <div className="me-3">
                <table className="invoice_total_value_table">
                  <thead>
                    <tr>
                      <th className=""></th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="fontStyle1">Total GBP :</td>
                      <td className="invoice_total_content_value">
                        £ {totalValue?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Total VAT :</td>
                      <td className="invoice_total_content_value">
                        £ {calculateVat()?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Garriage GBP :</td>
                      <td className="invoice_total_content_value">
                        £ {(totalValue + Number(VAT.toFixed(2)))?.toFixed(2)}
                      </td>
                    </tr>
                    {/* {(Number(formProps?.initialValues?.credit_amount) > 0) && (
                    <tr>
                      <td className="fontStyle1">Credit Amount :</td>
                      <td className="invoice_total_content_value">
                        £ {formProps?.initialValues?.credit_amount?.toFixed(2)}
                      </td>
                    </tr>
                    )}
                    <tr>
                      <td className="fontStyle1">Advance/ <br/>Adjustment Amount :</td>
                      <td className="invoice_total_content_value">
                        £ {formProps?.initialValues?.advance_amount?.toFixed(2) ?? "0.00"}
                      </td>
                    </tr> */}
                    <tr>
                      <td className="fontStyle1">Paid Amount :</td>
                      <td className="invoice_total_content_value">
                        £{" "}
                        {formProps?.initialValues?.paid_amount?.toFixed(2) ??
                          "0.00"}
                      </td>
                    </tr>
                    <tr>
                      <td className="fontStyle1">Amount Due GBP :</td>
                      <td className="invoice_total_content_value">
                        £{" "}
                        {formProps?.initialValues?.outstanding?.toFixed(2) ??
                          "0.00"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
          {printTable == true ? (
            <>
              <div ref={componentRef} style={primaryColourStyleforPrintPage}>
                <div className="invoice_print_page">
                  <div className="invoice_heading_container">
                    <div
                      className="invoice_heading_text"
                      style={primaryColourStyle}
                    >
                      INVOICE
                    </div>
                    <div>
                      <img src={logo} className="invoice_heading_image" />
                    </div>
                  </div>

                  <div className="invoice_detail_container">
                    <div className="ourcompany_details_container">
                      <div className="ourcomany_details">
                        <h6
                          className="ourcomany_details_heading"
                          style={primaryColourStyle}
                        >
                          {companyName}
                        </h6>
                        <div>{/* {companyAddress} */}</div>
                      </div>
                      <div className="customercompany_details">
                        <h6 style={primaryColourStyle}>Invoice To:</h6>
                        <p>{orderItems[0]?.name}</p>
                        <div>
                          {orderItems[0]?.address?.customers?.map(
                            (data: any) => {
                              return (
                                <>
                                  {data?.address}
                                  {data?.address_line_2} {data?.city}{" "}
                                  {data?.country} {data?.postal_code}
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <div className="invoice_details_box">
                        <h6 style={primaryColourStyle}>Invoice Number</h6>
                        <p>
                          {/* {formProps?.initialValues?.orderids} */}
                          {orderItems[0]?.order_Unique_id}
                          </p>
                        <h6 style={primaryColourStyle}>Invoice Date</h6>
                        <p>
                          {" "}
                          {moment(formProps?.initialValues?.created_at)
                            .utc()
                            .format("MMM Do YYYY h:mm A")}
                        </p>
                        <h6 style={primaryColourStyle}>Invoice Status</h6>
                        <p>{formProps?.initialValues?.status}</p>
                      </div>
                    </div>
                  </div>

                  <div className="invoice_table_container">
                    <div>
                      <Table
                        columns={Coloums}
                        dataSource={orderItems[0]?.items}
                        pagination={false}
                      />
                    </div>
                  </div>

                  <div className="invoice_totalvalues_container">
                    <div className="invoice_totalvalues_box">
                      <div className="invoice_totalvalues_emptybox"></div>
                      <div className="invoice_totalvalue_text">
                        <h6>Total GBP :</h6>
                        <h6>Total VAT :</h6>
                        <h6>Garriage GBP :</h6>
                        {orderItems?.[0]?.additional_charges?.map(
                          (charge: any, index: any) => (
                            <h6 style={{ textTransform: "capitalize" }}>
                              {charge?.charges_name} :
                            </h6>
                          )
                        )}
                        <h6>Amount Due GBP :</h6>
                      </div>
                      <div className="invoice_totalvalue_value">
                        <h6>£ {totalValue?.toFixed(2)}</h6>
                        <h6>£ {calculateVat()?.toFixed(2)}</h6>
                        <h6>
                          £ {(totalValue + Number(VAT.toFixed(2)))?.toFixed(2)}
                        </h6>
                        {orderItems?.[0]?.additional_charges?.map(
                          (charge: any, index: any) => (
                            <h6 style={{ textTransform: "capitalize" }}>
                              {charge?.charges_type === "amount"
                                ? `£${charge?.rate}`
                                : `${charge?.rate}%`}
                            </h6>
                          )
                        )}
                        <h6>
                          £{" "}
                          {formProps?.initialValues?.outstanding?.toFixed(2) ??
                            "0.00"}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="invoice_notes_container">
                    <h6 style={primaryColourStyle}>Notes:</h6>
                  </div>

                  <div className="invoice_terms_container">
                    <h6 style={primaryColourStyle}>Terms and Conditions:</h6>
                  </div>
                </div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <h6>Orderbit Invoice Details</h6>
              </div>
              <div className="header_container">
                <div className="header_container_box_1">
                  <table className="invoice_total_value_table">
                    <thead>
                      <tr>
                        <th className=""></th>
                        <th className=""></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="fontStyle1 invoice_total_content">
                          Customer Name :
                        </td>
                        <td className="invoice_total_content_value">
                          {orderItems[0]?.name}
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1">Order ID :</td>
                        <td className="invoice_total_content_value">
                          {formProps?.initialValues?.orderids}
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1">Invoice Date :</td>
                        <td className="invoice_total_content_value">
                          {
                            <DateField
                              format="LL"
                              value={formProps?.initialValues?.created_at}
                            />
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1">Invoice Status :</td>
                        <td className="invoice_total_content_value">
                          {formProps?.initialValues?.status}
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1">Address :</td>
                        <td className="invoice_total_content_value">
                          {orderItems[0]?.address?.customers?.map(
                            (data: any) => {
                              return (
                                <>
                                  {" "}
                                  <span>
                                    <span>{data?.address}</span>
                                    <br />
                                    <span>
                                      {" "}
                                      {data?.address_line_2} {data?.city}{" "}
                                      {data?.country} {data?.postal_code}
                                    </span>
                                  </span>{" "}
                                </>
                              );
                            }
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="header_container_box_2">
                  <table className="invoice_total_value_table">
                    <thead>
                      <tr>
                        <th className=""></th>
                        <th className=""></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="fontStyle1">Total GBP :</td>
                        <td className="invoice_total_content_value">
                          £ {totalValue?.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1">Total VAT :</td>
                        <td className="invoice_total_content_value">
                          £ {calculateVat()?.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1">Garriage GBP :</td>
                        <td className="invoice_total_content_value">
                          £ {(totalValue + VAT)?.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1">Amount Due GBP :</td>
                        <td className="invoice_total_content_value">
                          £ {(totalValue + VAT)?.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <Table
                  columns={Coloums}
                  dataSource={orderItems[0]?.items}
                  pagination={false}
                />
              </div> */}
            </>
          ) : (
            <></>
          )}
        </Row>
      </Container>
    </Modal>
  );
};
