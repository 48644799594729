import { Checkbox, Form, FormProps, Input, Modal, ModalProps } from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";

type EditFaqProps = {
    modalProps: ModalProps;
    formProps: FormProps;
};
const { TextArea } = Input;

export const EditFaq: React.FC<EditFaqProps> = ({
    modalProps,
    formProps,
}) => {
    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;    
    return (
        <Modal {...modalProps} title="Edit FAQ's">
            <Form {...formProps} size="large" layout="vertical">
                <Form.Item
                    label="Title"
                    name="title"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Content"
                    name="content"
                >
                    <TextArea />
                </Form.Item>
                <Form.Item label="" name="is_show" valuePropName="checked">
                    <Checkbox> Show </Checkbox>
                </Form.Item>
                <Form.Item
                    label="View Order"
                    name="view_order"
                >
                    <Input type="number" />
                </Form.Item>
                <Form.Item
                    label=""
                    name="updated_by"
                    initialValue={user?.id}
                    // style={{ display: "none" }}
                >
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item
                    label=""
                    name="ob_org_id"
                    initialValue={userId}
                    // style={{ display: "none" }}
                >
                    <Input type="hidden" />
                </Form.Item>
            </Form>
        </Modal>
    )
}