import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IOrderStatus, IOrg } from "interfaces";
import { useState } from "react";
const { TextArea } = Input;

type CreatePaymentOptionProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const CreatePaymentOption: React.FC<CreatePaymentOptionProps> = ({
  modalProps,
  formProps,
}) => {
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;
  const [active, setActive] = useState(false);
  const [Private, setPrivate] = useState(false);
  const { selectProps: OBOrderStatusSelectProps } = useSelect<IOrderStatus>({
    resource: "order_statuses",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name", "sort", "bg_color", "ob_org_id", "notify_customer"],
    },
    sort: [{ field: "name", order: "asc", },]
  });
  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const onChange = (key: string) => {
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setActive(e.target.checked);
  };
  const onChangeViewCheckbox = (e: CheckboxChangeEvent) => {
    setPrivate(e.target.checked);
  };
  console.log(user?.metadata?.org_id,"user?.metadata?.org_id");
  
  return (
    <Modal {...modalProps} title="Create Payment Option">
      <Form {...formProps}
        wrapperCol={{ span: 23 }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Row>
          <Col span={12}>

            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please input your name!' }]}>
                <Select>
                  <Select.Option value="Stripe" name="Stripe">Stripe</Select.Option>
                  <Select.Option value="Paypal" name="Paypal">Paypal</Select.Option>
                  <Select.Option value="Bank Transfer" name="Bank Transfer">Bank Transfer</Select.Option>
                  <Select.Option value="Cash On Delivery" name="Cash On Delivery">Cash On Delivery</Select.Option>
                </Select>
              {/* <Input placeholder="Enter the name" onChange={(e) => onChange} style={{ width: "100%" }} /> */}
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please input your description!' }]}
            >
              <TextArea rows={4} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="is_active" valuePropName="checked">
              <Checkbox
              // name='active' checked={active} onChange={onChangeCheckbox}
              >Active</Checkbox>
            </Form.Item>
            <Form.Item name="private" valuePropName="checked"  >
              <Checkbox
              // name='private' checked={Private} onChange={onChangeViewCheckbox}
              >Private</Checkbox>
            </Form.Item>
            <Form.Item
              name="view_order"
              label="View Order"
              rules={[{ required: true, }]}>

              <InputNumber onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="payment_fee_percentage"
              label="Payment Fee Percentage"
              rules={[{ required: true, }]}>

              <InputNumber onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="payment_fee_amount"
              label="Payment Fee Amount"
              rules={[{ required: true, }]}>

              <InputNumber onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name={["secrete_keys", "publishable_key"]}
              label="Publishable Key"
              rules={[{ required: true, message: 'Please Enter your Publishable Key!' }]}>

              <Input.Password placeholder="Enter the Publishable Key" onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name={["secrete_keys", "secret_key"]}
              label="Secret Key"
              rules={[{ required: true, message: 'Please Enter your Secret Key!' }]}>

              <Input.Password placeholder="Enter the Secret Key" onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            
            {user ? 
            <>
            <Form.Item
                    name="created_by"
                    initialValue={user?.id}
                >
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item
                    name="ob_org_id"
                    initialValue={userId}
                >
                    <Input type="hidden" />
                </Form.Item>
                </>
                : <></> }

          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
