import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  InputNumber, Select, useSelect, Checkbox
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { IOrg } from "interfaces";
import React, { useState, useEffect } from 'react';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckCircleOutlined, DeleteOutlined, PictureOutlined } from '@ant-design/icons';
import "./stylecategory.css";
import { message, Upload } from 'antd';
import type { UploadProps } from 'antd';
import { nhost } from "utility";
import { useNotification } from "@pankod/refine-core";
import { GET_CATEGORIES_BY_ORGID } from "query";

type CreateCategoryProps = {
  modalProps: ModalProps;
  handleFormSubmitted: () => void;
  createModalVisible: boolean;
  formProps: FormProps;
  closeCreateModal: () => void
};

export const CreateCategory: React.FC<CreateCategoryProps> = ({
  modalProps,
  formProps,
  closeCreateModal,
  createModalVisible,
  handleFormSubmitted,
}) => {
  const { data: user } = useGetIdentity();
  const { open, close } = useNotification();

const { Option } = Select;
const { Dragger } = Upload;
  const userId = user?.metadata?.org_id;

  // const [open, setOpen] = useState(false);
  const [ urlList, setUrlList ] = useState<any>()
  const [allCategories, setAllCategories] = useState<any>([]);
  const [initialFormValues, setInitialFormValues] = useState({
    name: '',
    parent_id: null,
    ob_org_id: user?.metadata?.org_id,
    is_active: true,
    featured_category: false,
    image_url: '',
    created_by: user?.id,
  });

  const getCategories = async () => {
    GET_CATEGORIES_BY_ORGID(userId).then((response: any) => {
        setAllCategories(response?.categories);
    });
};

useEffect(() => {
  getCategories();
},[userId])
  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const props: UploadProps = {
    beforeUpload: (file) => {
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSizeInBytes) {
        message.error('File size exceeds the limit (10MB).');
        return false;
      }
      return true;
    },
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {        
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
    },
  };

  const uploadImages = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      {
        const data = new FormData()
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4")
        data.append("cloud_name", "dov89ugan")
        data.append("folder", "orderbit")
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data
        })
          .then(resp => resp.json())
          .then(data => {
            setUrlList((urlList:any) => [ data.secure_url]);
          })
          .catch(err => console.log(err)
          );
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };
  
  const removeImage = () => {
    setUrlList([])
  }

  const handleOk = () => {
    console.log("hdjsdhjsd")
  }

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
  };
  
  const resetForm = () => {
    formProps.form?.setFieldsValue(initialFormValues);
  };

  const apiData = (e:any) => {
    console.log(e,"ee");
    
    e.created_by = user?.id ? user?.id : null;
    e.is_active = e.is_active != undefined ? e.is_active : false;
    e.ob_org_id = user?.metadata?.org_id ? user?.metadata?.org_id : null;
    // e.image_url = e.image_url ? e.image_url : null;

    let createCategory = `mutation MyMutation {
      insert_categories(objects: {created_by: "${e.created_by}", is_active: ${e.is_active},
       image_url: ${e.image_url ? `"${e.image_url}"` : null}, name: "${e.name}",
       parent_id: ${e.parent_id ? `"${e.parent_id}"` : null},
       featured_category: ${e.featured_category},
        ob_org_id: "${e.ob_org_id}"}) {
        affected_rows
        returning {
          id
          name
        }
      }
    }`

    const querySubmit = async () => {
      const { data } = await nhost.graphql.request(createCategory);
      
      if (data) {
        open?.({
          key:"success",
          type: "success",
          message: "",
          description: `Category Sucessfully Created`,
      });
        setTimeout(() => {
          close("success");
        }, 4000);
        // tableQueryResult?.refetch()
        closeCreateModal();
        resetForm();
        return data
      }
    }
    querySubmit();
    setUrlList([]);
  }

  return (
    <>
    <Modal {...modalProps} title="Create Category" 
    visible={createModalVisible}
    onCancel={closeCreateModal}
    className="Create_Category"    
    >
      <Form {...formProps} size="large" layout="vertical"
      onFinish={(e) => {
        e.image_url = urlList?.join(" ")
        apiData(e)
      }}
      >
        <Form.Item
          label="Category Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Parent Category"
          name="parent_id"
        >
          <Select
          allowClear
          clearIcon="*"
          >
            {allCategories?.map((category:any) => {
              return <Option key={category?.id} value={category?.id}>{category?.name}</Option>
            })}
          </Select>
        </Form.Item>
        {user?.defaultRole === "admin" ?
          <Form.Item
            label="Orderbit Organisation"
            name="ob_org_id"
            rules={[{ required: true, }]} >
            <Select {...OBOrgSelectProps} />
          </Form.Item> : 
          <Form.Item
          name="ob_org_id"
          style={{display: "none"}}
          initialValue={user?.metadata?.org_id}
          >
              <Input hidden />
            </Form.Item>
          }
          <Form.Item label="" name="is_active" valuePropName="checked">
            <Checkbox onChange={onChangeCheckbox}>Is active?</Checkbox>
          </Form.Item>

          <Form.Item label="" name="featured_category" initialValue={false} valuePropName="checked">
            <Checkbox>Show in Featured Category (homepage)</Checkbox>
          </Form.Item>

          <Form.Item label="Image" name="image_url">
            <div className="Image_div">
            <div>
              <Dragger showUploadList={false}
               {...props} 
               name="image_url" 
               customRequest={uploadImages}
              >
                <p className="ant-upload-drag-icon">
                  <PictureOutlined />
                </p>
                <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
              </Dragger>
            </div>
          {/* <Form.Item
                    style={{ width: '100%' }}
                  > */}
                  <div className="category_img_div">
                    <h1 style={{ fontSize: "14px" }}>Uploaded image will be displayed here</h1>
                    {urlList?.map((url:any) => {
                      return <div>
                      <img className="category_img" src={url} />
                       {url ? <span ><DeleteOutlined onClick={removeImage}/></span> : <></> }
                      </div>
                    })}
                    </div>
                    </div>
                  </Form.Item>
                  {/* </Form.Item> */}
          <Form.Item name="created_by"
            initialValue={user?.id}
          style={{display: "none"}}
        >
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
    {/* <Modal visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleOk}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
            Successfully created banner
          </span>
        </div>
      </Modal> */}
    </>
  );
};
