import {useEffect, useState} from 'react';
import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal, Col, Row,
    Checkbox, useSelect, Select, DatePicker
  } from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";

type CreateFaqProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    viewOrderMax:any
  };

export const CreateFaq: React.FC<CreateFaqProps> = ({
    modalProps,
    formProps,
    viewOrderMax,
  }) => {
  const { TextArea } = Input;
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;

useEffect(() => {
    formProps?.form?.resetFields()
    formProps?.form?.setFieldsValue({[ `view_order`]:++viewOrderMax})
  },[viewOrderMax])

return (
    <Modal {...modalProps} title="Create FAQ's">
        <Form {...formProps} size="large" layout="vertical">
            <Form.Item
            label="Title"
            name="title"
            >
                <Input />
            </Form.Item>
            <Form.Item
            label="Content"
            name="content"
            >
                <TextArea />
            </Form.Item>
            <Form.Item label="" name="is_show" initialValue={true} valuePropName="checked">
                <Checkbox> Show </Checkbox>
              </Form.Item>
            <Form.Item
            label="View Order"
            name="view_order"
            >
                <Input type="number"/>
            </Form.Item>
            {user ? 
            <>
            <Form.Item
                    name="created_by"
                    initialValue={user?.id}
                >
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item
                    name="ob_org_id"
                    initialValue={userId}
                >
                    <Input type="hidden" />
                </Form.Item>
                </>
                : <></> }
        </Form>
    </Modal>
)
}