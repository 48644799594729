import {
    DateField,
    useTable,
    Modal,
    FormProps,
    ModalProps,
  } from "@pankod/refine-antd";
  import Container from "react-bootstrap/Container";
  import Row from "react-bootstrap/Row";
  import Col from "react-bootstrap/Col";
  import { useEffect, useRef, useState } from "react";
  import { HttpError, useGetIdentity, useList, useOne } from "@pankod/refine-core";
  import { IOrder, IOrg } from "interfaces";
  import { Table } from 'antd';
  import { GET_ALL_TAXES, GET_ORDERS } from "query";
  import "./tripesheet.css"
  import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
  import { UncontrolledTooltip } from "reactstrap";
  // import { Image } from "react-bootstrap";
  import { useReactToPrint } from "react-to-print";
  import moment from "moment";
import { BlockOutlined } from "@ant-design/icons";
  
  type TripSheet = {
    formProps: FormProps;
    modalProps: ModalProps;
  };
  
  export const TripSheetShow: React.FC<TripSheet> = (
    {
      formProps,
      modalProps,
    }
  ) => {  
    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;
    const [orderItems, setOrderItems] = useState<any>([])
    const [printTable, setPrintTAble] = useState(false);
    const [allTaxes, setAllTaxes] = useState<any>(null);
    const [allOrders, setAllOrders] = useState<any>(null);
    const [sortedOrders, setSortedOrders] = useState<any>([]);
  
    const { tableProps, sorter, tableQueryResult } = useTable({
      resource: "orders",
      initialSorter: [{
        field: "created_at",
        order: "desc",
    }],
    permanentSorter: [{
        field: "created_at",
        order: "desc",
    }],
      permanentFilter: [
        {
          field: "ob_org_id",
          operator: "eq",
          value: userId,
        },
        {
          field: "trip_id",
          operator: "eq",
          value: formProps?.initialValues?.id
        }
      ],
      metaData: {
        fields: ["id", "name", "date", "email", "items", "phone_no", "status", "order_Unique_id", "created_at", "address", "delivery_Instruction",],
      },
    });

        const { data, isLoading, isError } = useOne<any, HttpError>({
          resource: "route",
          id: formProps?.initialValues?.route_id,
          queryOptions: {
            enabled: !!formProps?.initialValues,
        },
          metaData: {
              fields: [
                "id",
                "name",
                "description",
                "ob_org_id",
                "is_active",
                "created_at",
                "created_by",
                "updated_at",
                "updated_by",
              ],
            },
        });
    
    const getOrders = () => {    
      GET_ORDERS(userId).then((res:any) => {      
         setAllOrders(res?.orders)
      })
    }
  
    const orgDetails = useList<IOrg>({
      resource: "ob_orgs",
      metaData: {
        fields: ["primary_color", "logo", "currency","address","name", "address"]
      },
      config: {
        filters: [
          {
            field: "id",
            operator: "eq",
            value: userId,
          },
        ],
      },
    });
  
    const companyName = orgDetails?.data?.data[0]?.name ?? "Orderbit";
    const companyAddress = orgDetails?.data?.data[0]?.address ?? ["Orderbit",];
    const primaryColour = orgDetails?.data?.data[0]?.primary_color ?? "#4EA144";
    const logo = orgDetails?.data?.data[0]?.logo ?? "https://res.cloudinary.com/dov89ugan/image/upload/v1699341390/orderbit/wyjlrnkxkfajiqx55jsh.png";
    const currency = "£" ?? "£";
  
    const getAllTaxes = () => {
      GET_ALL_TAXES(userId).then((res: any) => {
        setAllTaxes(res?.taxes)
      })
    }
  
    useEffect(() => {    
      if (allOrders?.length > 0) {      
        const getOrderItems = allOrders?.filter((order:any) => {        
         return order?.order_Unique_id == formProps?.initialValues?.orderids
        })
        
        if(getOrderItems){
          setOrderItems(getOrderItems)
          
        }
      }
    }, [formProps])
  
    useEffect(() => {
      if (userId) {
        getAllTaxes();
        getOrders();
      }    
    }, [userId])
  
    const OrderCustomerColoums = [
      {
        title: (
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              fontWeight: "500",
              color: "#00000078",
              fontSize: "15px",
              lineHeight: "15px",
            }}
          >
            S.No
          </div>
        ),
        key: "id",
        width: 10,
        render: (text: any, record: any, index: number) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {index + 1}
            </div>
          );
        },
      },
      {
        title: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "500",
              color: "#00000078",
              fontSize: "15px",
              lineHeight: "15px",
            }}
          >
            Order ID
          </div>
        ),
        width: 50,
        key: "order_Unique_id",
        dataIndex: "order_Unique_id",
        render: (value: any) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {value}
          </div>
        ),
      },
      {
        title: (
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              fontWeight: "500",
              color: "#00000078",
              fontSize: "15px",
              lineHeight: "15px",
            }}
          >
            Customer
          </div>
        ),
        dataIndex: "name",
        width: 100,
        key: "name",
        render: (value: any) => (
          <div style={{ display: "flex", justifyContent: "center" }}>{value}</div>
        ),
      },
      {
        title: (
          <div style={{display: "flex",justifyContent: "center",fontWeight: "500",color: "#00000078",fontSize: "15px",lineHeight: "15px",}}>Address</div>
        ),
        width: 100,
        key: "address",
        dataIndex: "address",
        render: (value: any) => (
          <div style={{display: "flex",flexWrap:"wrap",justifyContent: "center"}}>
              <div>{value?.customers[0]?.address},
              {value?.customers[0]?.address_line_2},
              <br></br>
              {value?.customers[0]?.city},
              {value?.customers[0]?.country},
              {value?.customers[0]?.postal_code}
              </div>
          </div>
        ),
      },
      {
        title: <div style={{ display: "flex", justifyContent: "center", fontWeight: "500", color: "#00000078", fontSize: "15px", lineHeight: "15px" }}>Products-Qty</div>,
        width: 350,
        key: "items",
        dataIndex: "items",
        render: (value: any) => (
          <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
            {value?.map((data: any, index: number) => (
              <div key={index} style={{ display: "flex" }}>
                <div>{data?.name}</div>
                <div>-{data?.quantity}{index !== value.length - 1 ? `, ` : ""}{" "}</div>
              </div>
            ))}
          </div>
        )
      },
    //   {
    //     title: (
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "center",
    //           fontWeight: "500",
    //           color: "#00000078",
    //           fontSize: "15px",
    //           lineHeight: "15px"
    //         }}
    //       >
    //         Products-Qty
    //       </div>
    //     ),
    //     width: 180,
    //     key: "items",
    //     dataIndex: "items",
    //     render: (value: any) => {
    //       const rows: JSX.Element[] = [];
    //       for (let i = 0; i < value?.length; i += 4) {
    //         const rowData = value.slice(i, i + 4);
    //         const row = (
    //           <div key={i} style={{ display: "flex", marginBottom: "5px" }}>
    //             {rowData.map((data: any, index: number) => (
    //               <div key={index} style={{ marginRight: "10px" }}>
    //                 {data?.name}-{data?.quantity}{index !== value?.length - 1 ? "," : ""}
    //               </div>
    //             ))}
    //           </div>
    //         );
    //         rows.push(row);
    //       }
    //       return <div>{rows}</div>;
    //     }
    //   },
        {
          title: (
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                fontWeight: "500",
                color: "#00000078",
                fontSize: "15px",
                lineHeight: "15px",
              }}
            >
              Delivery Instruction
            </div>
          ),
          dataIndex: "delivery_Instruction",
          width: 100,
          key: "delivery_Instruction",
          render: (value: any) => (
            <div style={{ display: "flex", justifyContent: "center" }}>{value}</div>
          ),
        },
    ];
  
    const styles = StyleSheet.create({
      page: {
        backgroundColor: '#FFFFFF',
        borderBottom: `15px solid ${primaryColour}`,
        borderTop: `15px solid ${primaryColour}`,
      },
      viewPage: {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
      InvoiceHeafing: {
        marginTop: "15px",
        marginBottom: "15px",
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      invoiceHeadingText: {
        fontSize: 20,
        fontWeight: 400,
        color: primaryColour,
      },
      imageContainer: {
        width: 66,
        height: 66,
        // border: `1px solid ${primaryColour}`,
        borderRadius: 43,
        overflow: 'hidden',
      },
      image: {
        width: '100%',
        height: '100%',
      },
      invoiceDetails: {
        // marginTop: "30px",
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        gap: 10,
        // border: `3px solid ${primaryColour}` 
      },
      orgDetails: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        gap: 15,
      },
      ourCompany: {
        width: "50%",
      },
      ourCompanycontent: {
        fontSize: 12,
        color: primaryColour,
        marginBottom: 5,
      },
      ourCompanyAddresss: {
        fontSize: 12,
        lineHeight: "1.5px",
      },
      customerCompany: {
        width: "50%",
      },
      customerCompanycontent: {
        fontSize: 13,
        color: primaryColour,
        marginBottom: 5,
      },
      customerCompanyAddresss: {
        fontSize: 12,
        lineHeight: "1.5px",
      },
      customerInvoice: {
        width: "40%",
        // textAlign: "center",
        flexDirection: "row",
        display: "flex",
        justifyContent: "center",
      },
      customerInvoiceContentBox: {
        width: "65%",
        textAlign: "left",
        lineHeight: "2px"
    },
    customerInvoiceContentBox2: {
        width: "35%",
        textAlign: "left",
        lineHeight: "2px"
      },
      customerInvoiceHeading: {
        fontSize: 13,
        // color: primaryColour,
      },
      customerInvoiceText: {
        fontSize: 12,
        marginBottom: 5,
      },
      table_main: {
        marginTop: "15px",
      },
      table_view: {
        flexDirection: "row",
      },
      table_content: {
        fontSize: 12,
        borderLeft: 1,
        borderBottom: 1,
        borderColor: "#ced0d3",
        minWidth: "35px",
        maxWidth: "35px",
        paddingLeft: 10,
        padding: 5,
        textAlign: "center",
        verticalAlign: "sub",
      },
      table_content1: {
        fontSize: 12,
        borderLeft: 1,
        borderBottom: 1,
        borderColor: "#ced0d3",
        minWidth: "60px",
        maxWidth: "60px",
        padding: 5,
        textAlign: "center",
        verticalAlign: "sub",
      },
      table_content3: {
        fontSize: 12,
        borderLeft: 1,
        borderBottom: 1,
        borderRight: 1,
        borderColor: "#ced0d3",
        minWidth: "70px",
        maxWidth: "70px",
        padding: 5,
        textAlign: "center",
        verticalAlign: "sub",
    },
    table_products: {
        fontSize: 12,
        borderLeft: 1,
        borderBottom: 1,
        borderRight: 1,
        borderColor: "#ced0d3",
        minWidth: "200px",
        maxWidth: "200px",
        padding: 5,
        textAlign: "center",
        verticalAlign: "sub",
      },
    table_Delivery: {
        fontSize: 12,
        borderLeft: 1,
        borderBottom: 1,
        borderRight: 1,
        borderColor: "#ced0d3",
        minWidth: "90px",
        maxWidth: "90px",
        padding: 5,
        textAlign: "center",
        verticalAlign: "sub",
      },
      table_products_content_box: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
    //   table_products_content: {
    //     flexDirection: "row",
    //     display: "flex",
    //   },
    //   table_products_content_out: {
    //     flexDirection: "row",
    //     display: "flex",
    //   },
    //   table_products: {
    //     // Your styles for the products container
    //     display: "flex",
    //     flexDirection: "column",
    //   },
    
      table_products_content_out: {
        // Your styles for the products container
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      },
    
      table_products_content: {
        // flexBasis: "100%",
        textAlign: "center",
        flexDirection: "row",
      },
      table_address: {
        fontSize: 12,
        borderLeft: 1,
        borderBottom: 1,
        borderRight: 1,
        borderColor: "#ced0d3",
        minWidth: "100px",
        maxWidth: "100px",
        padding: 5,
        // textAlign: "center",
        // verticalAlign: "sub",
        // flexDirection: "column",
        // display: "flex",
        // alignItems: "center",
      },
      table_Address_content3: {
        fontSize: 12,
        minWidth: "150px",
        maxWidth: "150px",
        padding: 5,
        textAlign: "center",
        verticalAlign: "sub",
      },
      table_content2: {
        fontSize: 12,
        borderLeft: 1,
        borderBottom: 1,
        borderColor: "#ced0d3",
        minWidth: "237px",
        maxWidth: "237px",
        padding: 5,
        textAlign: "left",
        verticalAlign: "sub",
      },
      tableHeadingcontent: {
        fontSize: 12,
        borderLeft: 1,
        borderBottom: 1,
        borderColor: "#ced0d3",
        minWidth: "35px",
        maxWidth: "35px",
        paddingLeft: 10,
        padding: 5,
        textAlign: "center",
        verticalAlign: "sub",
        backgroundColor: primaryColour,
        color: '#FFFFFF',
      },
      tableHeadingcontent1: {
        fontSize: 12,
        borderLeft: 1,
        borderBottom: 1,
        borderColor: "#ced0d3",
        minWidth: "60px",
        maxWidth: "60px",
        padding: 5,
        textAlign: "center",
        verticalAlign: "sub",
        backgroundColor: primaryColour,
        color: '#FFFFFF',
      },
      tableHeadingcontent2: {
        fontSize: 12,
        borderLeft: 1,
        borderBottom: 1,
        borderColor: "#ced0d3",
        minWidth: "200px",
        maxWidth: "200px",
        padding: 5,
        textAlign: "center",
        verticalAlign: "sub",
        backgroundColor: primaryColour,
        color: '#FFFFFF',
      },
      tableHeadingDelivery: {
        fontSize: 12,
        borderLeft: 1,
        borderBottom: 1,
        borderColor: "#ced0d3",
        minWidth: "90px",
        maxWidth: "90px",
        padding: 5,
        textAlign: "center",
        verticalAlign: "sub",
        backgroundColor: primaryColour,
        color: '#FFFFFF',
      },
      tableHeadingcontent3: {
        fontSize: 12,
        borderLeft: 1,
        borderBottom: 1,
        borderRight: 1,
        borderColor: "#ced0d3",
        minWidth: "70px",
        maxWidth: "70px",
        padding: 5,
        textAlign: "center",
        verticalAlign: "sub",
        backgroundColor: primaryColour,
        color: '#FFFFFF',
      },
      tableHeadingcontent3_address: {
        fontSize: 12,
        borderLeft: 1,
        borderBottom: 1,
        borderRight: 1,
        borderColor: "#ced0d3",
        minWidth: "100px",
        maxWidth: "100px",
        padding: 5,
        textAlign: "center",
        verticalAlign: "sub",
        backgroundColor: primaryColour,
        color: '#FFFFFF',
      },
      invoiceTotal: {
        marginTop: "30px",
        fontSize: 12,
        marginRight: "3.5px",
      },
      invoiceTotanContent: {
        flexDirection: "row",
        display: "flex",
        lineHeight: "2.5px",
      },
      invoiceTotalExtraSpaceContent: {
        width: "70%",
      },
      invoiceTotalTaxString: { 
        width: "30%",
        textAlign: "left",     
      },
      invoiceTotalTaxValue: { 
        width: "20%",
        textAlign: "right",     
      },
      invoiceNotes: {
        marginTop: "30px",
      },
      invoiceNotesText: {
        fontSize: 12,
      },
      invoiceTerms: {
        marginTop: "60px",
      },
      invoiceTermsText: {
        fontSize: 12,
      },

      allign_items_fortable: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }
    });
  
    const MyDocument = () => {
      return (
        <Document>
          <Page size="A4" wrap={true} style={styles.page}>
            <View style={styles.viewPage}>
              <View style={styles.InvoiceHeafing} fixed>
                <View>
                  <Text style={styles.invoiceHeadingText}>TRIP SHEET</Text>
                </View>
                <View style={styles.imageContainer}>
                  {logo ? (
                    <Image style={styles.image} src={logo} />
                  ) : (
                    <Image
                      style={styles.image}
                      src="https://res.cloudinary.com/dov89ugan/image/upload/v1699341390/orderbit/wyjlrnkxkfajiqx55jsh.png"
                    />
                  )}
                </View>
              </View>

              <View style={styles.invoiceDetails}>
                <View style={styles.orgDetails}>
                  <View style={styles.customerInvoiceContentBox}>
                    <Text style={styles.customerInvoiceHeading}>
                      Trip ID:{" "}
                      <Text style={styles.customerInvoiceText}>
                        {formProps?.initialValues?.name}-{data?.data?.name}-
                        {moment(formProps?.initialValues?.date)
                          .utc()
                          .format("MMM DD")}
                        -
                        {formProps?.initialValues?.seq_number
                          ? formProps?.initialValues?.seq_number
                              ?.toString()
                              .padStart(2, "0")
                          : "00"}
                      </Text>
                    </Text>
                    <Text style={styles.customerInvoiceHeading}>
                      Trip Name:{" "}
                      <Text style={styles.customerInvoiceText}>
                        {formProps?.initialValues?.name}
                      </Text>
                    </Text>
                    <Text style={styles.customerInvoiceHeading}>
                      Route Name:{" "}
                      <Text style={styles.customerInvoiceText}>
                        {data?.data?.name}
                      </Text>
                    </Text>
                    <Text style={styles.customerInvoiceHeading}>
                      Driver Name:{" "}
                      <Text style={styles.customerInvoiceText}>
                        {formProps?.initialValues?.driver_name}
                      </Text>
                    </Text>
                    <Text style={styles.customerInvoiceHeading}>
                      Vehicle Number:{" "}
                      <Text style={styles.customerInvoiceText}>
                        {formProps?.initialValues?.vehicle_number}
                      </Text>
                    </Text>
                  </View>

                  <View style={styles.customerInvoiceContentBox2}>
                    <Text style={styles.customerInvoiceHeading}>
                      Start Time:{" "}
                      <Text style={styles.customerInvoiceText}>
                        {moment(formProps?.initialValues?.start_time)
                          .utc()
                          .format("HH:mm:ss")}
                      </Text>
                    </Text>
                    <Text style={styles.customerInvoiceHeading}>
                      End Time:{" "}
                      <Text style={styles.customerInvoiceText}>
                        {moment(formProps?.initialValues?.end_time)
                          .utc()
                          .format("HH:mm:ss")}
                      </Text>
                    </Text>
                    <Text style={styles.customerInvoiceHeading}>
                      Trip Date:{" "}
                      <Text style={styles.customerInvoiceText}>
                        {moment(formProps?.initialValues?.date)
                          .utc()
                          .format("MMM Do YYYY")}
                      </Text>
                    </Text>
                    <Text style={styles.customerInvoiceHeading}>
                      Seq Number:{" "}
                      <Text style={styles.customerInvoiceText}>
                        {formProps?.initialValues?.seq_number}
                      </Text>
                    </Text>
                    <Text style={styles.customerInvoiceHeading}>
                      Notes:{" "}
                      <Text style={styles.customerInvoiceText}>
                        {formProps?.initialValues?.notes}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>

              <View style={styles.table_main}>
                <View style={styles.table_view}>
                  <Text style={styles.tableHeadingcontent}>S.No</Text>
                  <Text style={styles.tableHeadingcontent1}>Order ID</Text>
                  <Text style={styles.tableHeadingcontent3}>Customer</Text>
                  <Text style={styles.tableHeadingcontent3_address}>Address</Text>
                  <Text style={styles.tableHeadingcontent2}>Products-Qty</Text>
                  <Text style={styles.tableHeadingDelivery}>Delivery Ins</Text>
                </View>
                {sortedOrders?.map((data: any, index: number) => {
                  return (
                    <View style={styles.table_view}>
                      <Text style={styles.table_content}>{index + 1}</Text>
                      <Text style={styles.table_content1}>
                        {data?.order_Unique_id}
                      </Text>
                      <Text style={styles.table_content3}>{data?.name}</Text>

                      {/* {data?.address?.customers?.map((add: any) => {
                        return ( */}
                          <Text style={styles.table_address}>
                              {data?.address?.customers[0]?.address}, {data?.address?.customers[0]?.city}, {data?.address?.customers[0]?.address_line_2}, {data?.address?.customers[0]?.country}, {data?.address?.customers[0]?.postal_code}
                            {/* <Text style={styles.table_Address_content3}>
                              <Text style={styles.table_Address_content3}>
                              </Text>
                            </Text>
                            <Text style={styles.table_Address_content3}>
                            </Text>
                            <Text style={styles.table_Address_content3}>
                              <Text style={styles.table_Address_content3}>
                              </Text>
                            </Text> */}
                          </Text>
                        {/* );
                      })} */}
                      
                      <View style={styles.table_products}>
                        <View style={styles.table_products_content_out}>
                          {data?.items?.map((prod: any, i: number) => (
                            <View key={i} style={styles.table_products_content}>
                              <Text>{prod?.name}</Text>
                              <Text>-{prod?.quantity}{i != data?.items?.length - 1 ? ", " : ""}</Text>
                            </View>
                          ))}
                        </View>
                      </View>

                      <Text style={styles.table_Delivery}>
                        {data?.delivery_Instruction}
                      </Text>
                    </View>
                  );
                })}
              </View>

            </View>
          </Page>
        </Document>
      );
    };
  
    const componentRef: any = useRef();
  
    const handlePrint = useReactToPrint({
      content: () => componentRef.current
    });
  
    const handleDual = () => {
      setPrintTAble(true);
      setTimeout(() => {
        handlePrint();
      }, 1000);
      setTimeout(() => {
        setPrintTAble(false);
      }, 3000);
    }
  
    const primaryColourStyle: React.CSSProperties = {
      color:  primaryColour
    }
  
    const primaryColourStyleforPrintPage: React.CSSProperties = {
      height: "100vh",
      borderBottom: `15px solid ${primaryColour}`,
      borderTop: `15px solid ${primaryColour}`,
    }

  useEffect(() => {
    if(tableProps?.dataSource){
      const sortedData = [...tableProps?.dataSource].sort((a, b) => {
        const postalCodeA: string | number | undefined = a.address?.customers[0]?.postal_code;
        const postalCodeB: string | number | undefined = b.address?.customers[0]?.postal_code;
      
        if (typeof postalCodeA === 'string' && typeof postalCodeB === 'string') {
          return postalCodeA.localeCompare(postalCodeB);
        } else if (typeof postalCodeA === 'number' && typeof postalCodeB === 'number') {
          return postalCodeA - postalCodeB;
        } else {
          return 0;
        }
      });
      if(sortedData){
        setSortedOrders(sortedData)
      }      
    }
  },[tableProps?.dataSource])
  
    return (
      <Modal {...modalProps} title="View Trip Sheet" footer={false}>
        <Container fluid>
          <div className="invoice_icons">
            <UncontrolledTooltip
              placement="top"
              target="print"
              className="Tooltip_icons"
            >
              Print
            </UncontrolledTooltip>
            <img
              style={{ width: "22px", height: "22px" }}
              src="/images/printer.png"
              alt="printer.png"
              className="fav_Print"
              id="print"
              onClick={() => handleDual()}
            />
            <PDFDownloadLink
              document={<MyDocument />}
              fileName={`TripSheet${formProps?.initialValues?.name}.pdf`}
            >
              <img
                alt="export.png"
                className="fav_Print"
                style={{ width: "22px", height: "22px" }}
                src="/images/pdf.png"
                id="pdf"
              ></img>
            </PDFDownloadLink>
            <UncontrolledTooltip
              placement="top"
              target="pdf"
              className="Tooltip_icons"
            >
              Export to pdf
            </UncontrolledTooltip>
          </div>
          <Row>
            <Col xs={12} md={8} style={{ width: "100%", height: "100%" }}>
              <div className="header_container">
                <div className="header_container_box_1">
                  <table className="invoice_total_value_table">
                    <thead>
                      <tr>
                        <th className=""></th>
                        <th className=""></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="fontStyle1">Trip ID :</td>
                        <td className="invoice_total_content_value">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>
                              <BlockOutlined /> {formProps?.initialValues?.name}
                              -
                            </div>
                            <div>{data?.data?.name}-</div>
                            <div>
                              <DateField
                                value={formProps?.initialValues?.date}
                                format="MMM DD"
                              />
                              -
                            </div>
                            <div>
                              {formProps?.initialValues?.seq_number
                                ? formProps?.initialValues?.seq_number
                                    ?.toString()
                                    .padStart(2, "0")
                                : "00"}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1 invoice_total_content">
                          Trip Name :
                        </td>
                        <td className="invoice_total_content_value">
                          {formProps?.initialValues?.name}
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1">Route Name :</td>
                        <td className="invoice_total_content_value">
                          {data?.data?.name}
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1">Driver Name :</td>
                        <td className="invoice_total_content_value">
                          {formProps?.initialValues?.driver_name}
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1">Vehicle Number :</td>
                        <td className="invoice_total_content_value">
                          {formProps?.initialValues?.vehicle_number}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="header_container_box_2">
                  <table className="invoice_total_value_table">
                    <thead>
                      <tr>
                        <th className=""></th>
                        <th className=""></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="fontStyle1">Start Time :</td>
                        <td className="invoice_total_content_value">
                          {
                            <DateField
                              format="HH:mm:ss"
                              value={formProps?.initialValues?.start_time}
                            />
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1">End Time :</td>
                        <td className="invoice_total_content_value">
                          {
                            <DateField
                              format="HH:mm:ss"
                              value={formProps?.initialValues?.end_time}
                            />
                          }
                        </td>
                      </tr>

                      <tr>
                        <td className="fontStyle1">Trip Date :</td>
                        <td className="invoice_total_content_value">
                          {
                            <DateField
                              format="LL"
                              value={formProps?.initialValues?.date}
                            />
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1">Sequence Number :</td>
                        <td className="invoice_total_content_value">
                          {formProps?.initialValues?.seq_number}
                        </td>
                      </tr>
                      <tr>
                        <td className="fontStyle1">Notes :</td>
                        <td className="invoice_total_content_value">
                          {formProps?.initialValues?.notes}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="invoice_items_table">
                <Table
                  columns={OrderCustomerColoums}
                  dataSource={sortedOrders?.length > 0  ? sortedOrders : tableProps?.dataSource}
                  pagination={false}
                />
              </div>
            </Col>
            {printTable == true ? (
              <>
                <div ref={componentRef} style={primaryColourStyleforPrintPage}>
                  <div className="invoice_print_page">
                    <div className="invoice_heading_container">
                      <div
                        className="invoice_heading_text"
                        style={primaryColourStyle}
                      >
                        TRIP SHEET
                      </div>
                      <div>
                        <img src={logo} className="invoice_heading_image" />
                      </div>
                    </div>

                    <div className="tripsheet_container">
                      <div className="header_container">
                        <div className="header_container_box_1">
                          <table className="invoice_total_value_table">
                            <thead>
                              <tr>
                                <th className=""></th>
                                <th className=""></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="fontStyle1">Trip ID :</td>
                                <td className="invoice_total_content_value">
                                  <span
                                    // style={{
                                    //   display: "flex",
                                    //   alignItems: "center",
                                    // }}
                                  >
                                    <span>
                                      {formProps?.initialValues?.name}-
                                    </span>
                                    <span>{data?.data?.name}-</span>
                                    <span>
                                      <DateField
                                        value={formProps?.initialValues?.date}
                                        format="MMM DD"
                                      />
                                      -
                                    </span>
                                    <span>
                                      {formProps?.initialValues?.seq_number
                                        ? formProps?.initialValues?.seq_number
                                            ?.toString()
                                            .padStart(2, "0")
                                        : "00"}
                                    </span>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td className="fontStyle1 invoice_total_content">
                                  Trip Name :
                                </td>
                                <td className="invoice_total_content_value">
                                  {formProps?.initialValues?.name}
                                </td>
                              </tr>
                              <tr>
                                <td className="fontStyle1">Route Name :</td>
                                <td className="invoice_total_content_value">
                                  {data?.data?.name}
                                </td>
                              </tr>
                              <tr>
                                <td className="fontStyle1">Driver Name :</td>
                                <td className="invoice_total_content_value">
                                  {formProps?.initialValues?.driver_name}
                                </td>
                              </tr>
                              <tr>
                                <td className="fontStyle1">Vehicle Number :</td>
                                <td className="invoice_total_content_value">
                                  {formProps?.initialValues?.vehicle_number}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="header_container_box_2">
                          <table className="invoice_total_value_table">
                            <thead>
                              <tr>
                                <th className=""></th>
                                <th className=""></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="fontStyle1">Start Time :</td>
                                <td className="invoice_total_content_value">
                                  {
                                    <DateField
                                      format="HH:mm:ss"
                                      value={
                                        formProps?.initialValues?.start_time
                                      }
                                    />
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td className="fontStyle1">End Time :</td>
                                <td className="invoice_total_content_value">
                                  {
                                    <DateField
                                      format="HH:mm:ss"
                                      value={formProps?.initialValues?.end_time}
                                    />
                                  }
                                </td>
                              </tr>

                              <tr>
                                <td className="fontStyle1">Trip Date :</td>
                                <td className="invoice_total_content_value">
                                  {
                                    <DateField
                                      format="LL"
                                      value={formProps?.initialValues?.date}
                                    />
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td className="fontStyle1">Sequence Number :</td>
                                <td className="invoice_total_content_value">
                                  {formProps?.initialValues?.seq_number}
                                </td>
                              </tr>
                              <tr>
                                <td className="fontStyle1">Notes :</td>
                                <td className="invoice_total_content_value">
                                  {formProps?.initialValues?.notes}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* <div className="invoice_detail_container">
                <div className="ourcompany_details_container">
                  <div className="ourcomany_details">
                    <h6
                      className="ourcomany_details_heading"
                      style={primaryColourStyle}
                    >
                      {companyName}
                    </h6>
                    <div>{companyAddress}</div>
                  </div>
                  <div className="customercompany_details">
                    <h6 style={primaryColourStyle}>Invoice To:</h6>
                    <p>{orderItems[0]?.name}</p>
                    <div>
                      {orderItems[0]?.address?.customers?.map((data: any) => {
                        return (
                          <>
                            {data?.address}
                            {data?.address_line_2} {data?.city} {data?.country}{" "}
                            {data?.postal_code}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="invoice_details_box">
                  <h6 style={primaryColourStyle}>Invoice Number</h6>
                  <p>{formProps?.initialValues?.orderids}</p>
                  <h6 style={primaryColourStyle}>Invoice Date</h6>
                  <p>
                    {" "}
                    {moment(formProps?.initialValues?.created_at)
                      .utc()
                      .format("MMM Do YYYY HH:mm")}
                  </p>
                  <h6 style={primaryColourStyle}>Invoice Status</h6>
                  <p>{formProps?.initialValues?.status}</p>
                </div>
                </div>
              </div> */}

                    <div className="invoice_table_container">
                      <div>
                        <Table
                          columns={OrderCustomerColoums}
                          // dataSource={tableProps?.dataSource}
                          dataSource={sortedOrders?.length > 0  ? sortedOrders : tableProps?.dataSource}
                          pagination={false}
                        />
                      </div>
                    </div>

                    {/* <div className="invoice_totalvalues_container">
                <div className="invoice_totalvalues_box">
                  <div className="invoice_totalvalues_emptybox"></div>
                  <div className="invoice_totalvalue_text">
                    <h6>Total GBP :</h6>
                    <h6>Total VAT :</h6>
                    <h6>Garriage GBP :</h6>
                    <h6>Amount Due GBP :</h6>
                  </div>
                  <div className="invoice_totalvalue_value">
                    <h6>£ {totalValue?.toFixed(2)}</h6>
                    <h6>£ {calculateVat()?.toFixed(2)}</h6>
                    <h6>£ {(totalValue + VAT)?.toFixed(2)}</h6>
                    <h6>£ {(totalValue + VAT)?.toFixed(2)}</h6>
                  </div>
                </div>
              </div> */}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </Row>
        </Container>
      </Modal>
    );
  };
  