
import { IResourceComponentsProps, useExport, CrudFilters, HttpError, useNotification, useGetIdentity } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    DatePicker,
    Button,
    Card,
    Input,
    Form,
    FormProps,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons, Popconfirm, Select, useSelect
} from "@pankod/refine-antd";
import { ICustomerGroup } from "interfaces";
import { CreateCustomerGroup } from "./create";
import { EditCustomerGroup } from "./edit";
import { useEffect, useState } from "react";
import { DELETE_CUSTOMER_GROUP } from "query";
import { ReloadOutlined } from "@ant-design/icons";
import { AuthPermission } from "utility/authPermission";
import { useNavigate } from "react-router-dom";
const { PlusOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } = Icons;


const { RangePicker } = DatePicker;

export const CustomerGroupList: React.FC<IResourceComponentsProps> = () => {

    const { data: user } = useGetIdentity();
    const { open, close } = useNotification();
    const navigate = useNavigate();
    const userId = user?.metadata?.org_id;

    const [createPopup, setCreatePopup] = useState(false)
    const [editPopup, setEditPopup] = useState(false)
    const [customerGrpId, setCustomerGrpId] = useState(null)
    const [menuPermission, setPermissions] = useState<any>(true)
    useEffect(()=>{
        if(user){
        AuthPermission(user?.metadata?.org_id).then((res: any) =>{
            setPermissions(res)
        })
        }
    },[user])

    const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<ICustomerGroup>({
        resource: "customer_groups",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
              field: "ob_org_id",
              operator: "eq",
              value: userId,
            },
            {
              field: "id",
              operator: "eq",
              value: customerGrpId,
            },
          ],
        metaData: {
            fields: ["id","group_name", "ob_org_id", "created_at", "customer_ids"],
        },
    });

    const { formProps, modalProps, show } = useModalForm({
        resource: "customer_groups",
        action: "create",
        redirect: false,
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,

    } = useModalForm({
        resource: "customer_groups",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "group_name", "ob_org_id", "pricelist_id", "customer_ids", "hide_product_ids"],
        },
    });

    const { selectProps } = useSelect<any>({
        resource: "customer_groups",
        metaData: {
          fields: ["id", "group_name"],
        },
        optionLabel: "group_name",
        optionValue: "id",
        filters:[
          {
            field:"ob_org_id",
            operator:"eq",
            value:userId
          }
         ],
        onSearch: (value:any) => [
          {
            field: "group_name",
            operator: "contains",
            value: "%" + value + "%",
          },
        ],
      });

    useEffect(() => {
        setTimeout(() => {
            tableQueryResult?.refetch();
        },2000)        
    },[createPopup, editPopup])
    
    const closeCreatePopup = () => {
        setCreatePopup(false)
    }
    const closeEditPopup = () => {
        setEditPopup(false)
    }

    const handleEdit = (record:any) => {
        editShow(record.id)
        setEditPopup(true)
    }

    const { triggerExport, isLoading } = useExport<ICustomerGroup>();

    const handleDelete = (record:any) => {        
        DELETE_CUSTOMER_GROUP(record?.id).then((res:any) => {
            tableQueryResult?.refetch();
            open?.({
                key: "success",
                type: "success",
                message: "",
        
                description: `${record?.group_name} has been deleted`,
              });
              setTimeout(() => {
                close("success");
              }, 5000);
        })
        .catch((err:any) => console.log(err,"error"))
    }

    const handleSearch = (value:any) => {
        setCustomerGrpId(value)
       }
    
       const handleOnClearSearch = () => {
        setCustomerGrpId(null)
       }

       const handleRefresh = () => {
        tableQueryResult?.refetch();
        navigate(0);
      };

    return (
        <List
            title="Customer Group"
            pageHeaderProps={{

                extra: [
                    <>
                    <Form
                layout="vertical"
                style={{ display: "flex", marginBottom: 17 }}
              >
                <Form.Item label="Group Name">
                  <Select
                    placeholder="Select a Group Name"
                    style={{ width: 300, marginRight: 20 }}
                    onSelect={handleSearch}
                    clearIcon="*"
                    allowClear
                    onClear={handleOnClearSearch}
                    {...selectProps}
                  />
                </Form.Item>
              </Form>
                      {/* <ExportButton onClick={triggerExport} loading={isLoading} />, */}
                    <RefreshButton
                        type="primary"
                        children={<div style={{ display: "flex", alignItems: "center" }}>
                            <ReloadOutlined style={{ marginRight: "5px" }} />
                            Refresh
                        </div>}
                        icon={false}
                        onClick={handleRefresh} />
                        {!menuPermission &&
                        <CreateButton type="primary"
                     resourceName="settings/customergroups"
                      children={<div style={{ display: "flex", alignItems: "center" }}>
                      <PlusOutlined style={{ marginRight: "5px" }} />
                      Add Customer Group
                    </div>}
                    icon={false}
                        onClick={() => setCreatePopup(true)} />
                       }
                       </>
                ],

            }}
        >
            <Table {...tableProps} rowKey="id" size="small">

                <Table.Column dataIndex="group_name" title="Name" />
                <Table.Column
                    dataIndex="created_at"
                    title="Created At"
                    render={(value) => <DateField value={value} format="LLL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                {!menuPermission &&
                <Table.Column<ICustomerGroup>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                                resourceName="settings/customergroups"
                                // onClick={() => editShow(record.id)}
                                onClick={() => handleEdit(record)}
                            />
                            <Popconfirm title="Delete?" onConfirm={() => handleDelete(record)} >
                                <a style={{ color: "red" }}>Delete</a>
                            </Popconfirm>
                            {/* <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                resourceName="settings/customergroups"
                                recordItemId={record.id}
                            /> */}
                        </Space>
                    )}
                />
                }
            </Table>
            <CreateCustomerGroup modalProps={modalProps} formProps={formProps} closePopup={closeCreatePopup} createPopup={createPopup} tableProps={tableProps}/>
            <EditCustomerGroup modalProps={editModalProps} formProps={editFormProps} closePopup={closeEditPopup} editPopup={editPopup}/>

        </List>
    );
};
