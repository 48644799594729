import { newModel, StringAdapter } from "casbin";

export const model = newModel(`
[request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow))

[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);



export const adapter = new StringAdapter(`
p, admin, orgs, (list)|(create)
p, admin, orgs/*, (edit)|(show)|(delete)

p, admin, orders, (list)|(create)
p, admin, orders/*, (edit)|(show)|(delete)

p, admin, order/customerReceipt, (list)|(show)|(create)

p, admin, users, (list)|(create)
p, admin, users/*, (edit)|(show)|(delete)

p, admin, catalogue/categories, (list)|(create)
p, admin, catalogue/categories/*, (edit)|(show)|(delete)

p, admin, catalogue/brands, (list)|(create)
p, admin, catalogue/brands/*, (edit)|(show)|(delete)

p, admin, master/categories, (list)|(create)
p, admin, master/categories/*, (edit)|(show)|(delete)

p, admin, master/products, (list)|(create)
p, admin, master/products/*, (edit)|(show)|(delete)

p, admin, master/brands, (list)|(create)
p, admin, master/brands/*, (edit)|(show)|(delete)

p, admin, store/banners, (list)|(create)
p, admin, store/banners/*, (edit)|(show)|(delete)

p, admin, store/news, (list)|(create)
p, admin, store/news/*, (edit)|(show)|(delete)

p, admin, store/pages, (list)|(create)
p, admin, store/pages/*, (edit)|(show)|(delete)

p, admin, customers/customer , (list)|(create)
p, admin, customers/customer/*, (edit)|(show)|(delete)

p, admin, settings/users , (list)|(create)
p, admin, settings/users/*, (edit)|(show)|(delete)

p, admin, settings/orderstatuses , (list)|(create)
p, admin, settings/orderstatuses/*, (edit)|(show)|(delete)

p, admin, settings/emailtemplates , (list)|(create)
p, admin, settings/emailtemplates/*, (edit)|(show)|(delete)

p, admin, settings/tax , (list)|(create)
p, admin, settings/tax/*, (edit)|(show)|(delete)

p, admin, settings/productstatuses , (list)|(create)
p, admin, settings/productstatuses/*, (edit)|(show)|(delete)

p, admin, settings/measurementunits , (list)|(create)
p, admin, settings/measurementunits/*, (edit)|(show)|(delete)

p, admin, settings/paymentoptions , (list)|(create)
p, admin, settings/paymentoptions/*, (edit)|(show)|(delete)

p, admin, settings/additionalCharges , (list)|(create)
p, admin, settings/additionalCharges/*, (edit)|(show)|(delete)

p, admin, settings/customergroups, (list)|(create)
p, admin, settings/customergroups*, (edit)|(show)|(delete)

p, admin, catalogue/products, (list)|(create)
p, admin, catalogue/products/*, (edit)|(show)|(delete)

p, admin, master/ob_products, (list)|(create)
p, admin, master/ob_products/*, (edit)|(show)|(delete)

p, admin, master/products, (list)|(create)
p, admin, master/products/*, (edit)|(show)|(delete)

p, admin, master/users, (list)|(create)
p, admin, master/users/*, (edit)|(show)|(delete)

p, admin, pricelists, (list)|(create)
p, admin, pricelists/*, (edit)|(show)|(delete)

p, admin, products, (list)|(create)
p, admin, products/*, (edit)|(show)|(delete)

p, admin, ob_categories, (list)|(create)
p, admin, ob_categories/*, (edit)|(show)|(delete)

p, owner, settings/orderstatuses , (list)|(create)
p, owner, settings/orderstatuses/*, (edit)|(show)|(delete)

p, owner, settings/emailtemplates , (list)|(create)
p, owner, settings/emailtemplates/*, (edit)|(show)|(delete)

p, owner, catalogue/brands, (list)|(create)
p, owner, catalogue/brands/*, (edit)|(show)|(delete)

p, owner, settings/tax , (list)|(create)
p, owner, settings/tax/*, (edit)|(show)|(delete)

p, owner, order/tbc , (list)|(create)
p, owner, order/tbc/*, (edit)|(show)|(delete)

p, owner, settings/productstatuses , (list)|(create)
p, owner, settings/productstatuses/*, (edit)|(show)|(delete)

p, owner, settings/measurementunits , (list)|(create)
p, owner, settings/measurementunits/*, (edit)|(show)|(delete)

p, owner, settings/customergroups, (list)|(create)
p, owner, settings/customergroups*, (edit)|(show)|(delete)

p, owner, settings/paymentoptions , (list)|(create)
p, owner, settings/paymentoptions/*, (edit)|(show)|(delete)

p, owner, settings/additionalCharges , (list)|(create)
p, owner, settings/additionalCharges/*, (edit)|(show)|(delete)

p, owner, settings/users , (list)|(create)
p, owner, settings/users/*, (edit)|(show)|(delete)

p, owner, customers/customer , (list)|(create)
p, owner, customers/customer/*, (edit)|(show)|(delete)

p, owner, order/customerReceipt, (list)|(show)|(create)
p, owner, order/customerReceipt/*, (list)|(show)

p, owner, orders, (list)|(create)
p, owner, orders/*, (edit)|(show)|(delete)

p, owner, catalogue/products, (list)|(create)
p, owner, catalogue/products/*, (edit)|(show)|(delete)
s
p, owner, catalogue/categories, (list)|(create)
p, owner, catalogue/categories/*, (edit)|(show)|(delete)

p, owner, ob_products, (list)|(create)
p, owner, ob_products/*, (edit)|(show)|(delete)

p, owner,  store/banners, (list)|(create)
p, owner, store/banners/*, (edit)|(show)|(delete)

p, owner,  store/news, (list)|(create)
p, owner,  store/news/*, (edit)|(show)|(delete)

p, owner,  store/pages, (list)|(create)
p, owner,  store/pages/*, (edit)|(show)|(delete)

p, owner,  store/pages, (list)|(create)
p, owner,  store/pages/*, (edit)|(show)|(delete)

p, owner, products, (list)|(create)
p, owner, products/*, (edit)|(show)|(delete)

p, owner, categories, (list)|(create)
p, owner, categories/*, (edit)|(show)|(delete)

p, owner, catalogue/coupon, (list)|(create)
p, owner, catalogue/coupon/*, (edit)|(show)|(delete)

p, owner, catalogue/productoptions, (list)|(create)
p, owner, catalogue/productoptions/*, (edit)|(show)|(delete)

p, owner, catalogue/pricelist, (list)|(create)
p, owner, catalogue/pricelist/*, (edit)|(show)|(delete)

p, owner, setup/categories, (list)|(create)
p, owner, setup/categories/*, (edit)|(show)|(delete)

p, developer, order/customeAlert, (list)|(create)
p, owner, order/customeAlert/*, (edit)|(show)|(delete)

p, developer, settings/orderstatuses , (list)|(create)
p, developer, settings/orderstatuses/*, (edit)|(show)|(delete)

p, developer, settings/emailtemplates , (list)|(create)
p, developer, settings/emailtemplates/*, (edit)|(show)|(delete)

p, developer, catalogue/brands, (list)|(create)
p, developer, catalogue/brands/*, (edit)|(show)|(delete)

p, developer, settings/tax , (list)|(create)
p, developer, settings/tax/*, (edit)|(show)|(delete)

p, developer, settings/productstatuses , (list)|(create)
p, developer, settings/productstatuses/*, (edit)|(show)|(delete)

p, developer, settings/measurementunits , (list)|(create)
p, developer, settings/measurementunits/*, (edit)|(show)|(delete)

p, developer, settings/customergroups, (list)|(create)
p, developer, settings/customergroups*, (edit)|(show)|(delete)

p, developer, settings/paymentoptions , (list)|(create)
p, developer, settings/paymentoptions/*, (edit)|(show)|(delete)

p, developer, settings/additionalCharges , (list)|(create)
p, developer, settings/additionalCharges/*, (edit)|(show)|(delete)

p, developer, customers/customer , (list)|(create)
p, developer, customers/customer/*, (edit)|(show)|(delete)

p, developer, settings/users, (list)|(create)
p, developer, settings/users/*, (edit)|(show)|(delete)

p, developer, order/customerReceipt, (list)|(show)|(create)

p, developer, orders, (list)|(create)
p, developer, orders/*, (edit)|(show)|(delete)

p, developer, catalogue/products, (list)|(create)
p, developer, catalogue/products/*, (edit)|(show)|(delete)

p, developer, catalogue/categories, (list)|(create)
p, developer, catalogue/categories/*, (edit)|(show)|(delete)

p, developer, ob_products, (list)|(create)
p, developer, ob_products/*, (edit)|(show)|(delete)

p, developer,  store/banners, (list)|(create)
p, developer, store/banners/*, (edit)|(show)|(delete)

p, developer,  store/news, (list)|(create)
p, developer,  store/news/*, (edit)|(show)|(delete)

p, developer,  store/pages, (list)|(create)
p, developer,  store/pages/*, (edit)|(show)|(delete)

p, developer,  store/pages, (list)|(create)
p, developer,  store/pages/*, (edit)|(show)|(delete)

p, developer, products, (list)|(create)
p, developer, products/*, (edit)|(show)|(delete)

p, developer, categories, (list)|(create)
p, developer, categories/*, (edit)|(show)|(delete)

p, developer, catalogue/coupon, (list)|(create)
p, developer, catalogue/coupon/*, (edit)|(show)|(delete)

p, developer, catalogue/productoptions, (list)|(create)
p, developer, catalogue/productoptions/*, (edit)|(show)|(delete)

p, developer, catalogue/pricelist, (list)|(create)
p, developer, catalogue/pricelist/*, (edit)|(show)|(delete)

p, developer, order/tbc, (list)|(create)
p, developer, order/tbc/*, (edit)|(show)|(delete)

p, developer, order/customeAlert, (list)|(create)
p, developer, order/customeAlert/*, (edit)|(show)|(delete)

p, developer, setup/categories, (list)|(create)
p, developer, setup/categories/*, (edit)|(show)|(delete)

`);