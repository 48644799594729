import React from "react";

import { LayoutProps } from "@pankod/refine-core";
import { AntdLayout, Grid } from "@pankod/refine-antd";
import { Header } from "../header";
import { Footer } from "../footer";

export const OrderBitLayout: React.FC<LayoutProps> = ({
  children,
  Sider,
  OffLayoutArea,
  dashboard
}) => {
  const breakpoint = Grid.useBreakpoint();

  return (
    <AntdLayout style={{ minHeight: "100vh", flexDirection: "row" }}>
      <AntdLayout>
        <Header />
        <AntdLayout.Content>
          <div
            style={{
              padding: 0,
              minHeight: 360,
            }}
          >
            {children}
          </div>
          {OffLayoutArea && <OffLayoutArea />}
        </AntdLayout.Content>
        <Footer />
      </AntdLayout>
    </AntdLayout>
  );
};
