import { useEffect, useState } from "react";
import { useGetIdentity, useTitle, useNavigation, useLogout, useNotification } from "@pankod/refine-core";
import { AntdLayout, Typography, Avatar, Space, Menu, Icons, useMenu, Dropdown } from "@pankod/refine-antd";
import { AuthPermission } from "utility/authPermission";
import { useMenuContext } from "contaxt/menuContext";
const { LogoutOutlined, DownOutlined } = Icons

const { Text } = Typography;

export const Header: React.FC = () => {
  const { data: user } = useGetIdentity();
  const Title = useTitle();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const { push } = useNavigation();
  const { open, close } = useNotification();

  const shouldRenderHeader = user && (user.name || user.avatar);
  const { mutate: logout } = useLogout()

  const [isLogout, setIsLogout] = useState<any>(false);
  
  const {menuPermission,setPermissions} = useMenuContext()

  useEffect(()=>{
    if(user){
      AuthPermission(user?.metadata?.org_id).then((res: any) =>{
        setPermissions(res)
      })
    }
  },[user])

  const logOutMenu = (
    <Menu>
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined />}
        onClick={({ key }) => {
          logout()
          return
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const setActiveClass = (selected: any, currentroute: any) => {
    return selected.includes(currentroute);
  }
  useEffect(() => {
    if(isLogout){
      logout();
      setTimeout(() => {
        push(`/login`);
        open?.({
          key:"success",
          type: "success",
          message: "",
          description: `Session Expired... Please Login`,
      });
        setTimeout(() => {
          close("success");
        }, 4000);
      }, 300);
    }
  },[isLogout]);

  const lastClearedTimestamp: any = localStorage.getItem('lastClearedTimestamp');  
    const oneWeekInMillis:any = 7 * 24 * 60 * 60 * 1000;
    // const oneWeekInMillis: any = 10 * 60 * 1000;
    if (!lastClearedTimestamp || Date.now() - lastClearedTimestamp >= oneWeekInMillis) { 
      setTimeout(() => {
        setIsLogout(true);   
      }, 500);
      // localStorage.setItem('lastClearedTimestamp', Date.now().toString());
    };
    
  return shouldRenderHeader ? (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        //alignItems: "center",
        padding: "0px 0px",
        height: "64px",
        //backgroundColor: "#F8F8FB",
        background: "#FFFFFF",
        borderBottom: "1px solid #d0d7e1"
      }}
    >
      {Title && <Title collapsed={false} />}
      <div className="logo" />
      <Menu
        // theme="dark"
        mode="horizontal"
        // defaultSelectedKeys={['/orders']}
        style={{ lineHeight: '64px', width: '70%', justifyContent: 'center' }}
      >
        {/* <Menu.Item
          key="/"
          onClick={() => {
            push("/");
          }}
          className={(selectedKey === "/") ? "ant-menu-item-selected" : ""}>Dashboard</Menu.Item> */}
        <Menu.Item
          key="/order"
          onClick={() => {
            push("/order");
          }}
          className={setActiveClass(selectedKey, '/order') ? "ant-menu-item-selected" : ""}>Orders</Menu.Item>
        <Menu.Item
          key="/customers"
          onClick={() => {
            push("/customers");
          }}
          className={setActiveClass(selectedKey, '/customers') ? "ant-menu-item-selected" : ""}>Customers</Menu.Item>

        <Menu.Item
          key="/catalogue/products"
          onClick={() => {
            push("/catalogue/products");
          }}
          className={setActiveClass(selectedKey, '/catalogue/products') ? "ant-menu-item-selected" : ""}>Catalogue</Menu.Item>
        {!menuPermission &&
        <Menu.Item
          key="/store"
          onClick={() => {
            push("/store");
          }}
          className={setActiveClass(selectedKey, '/store') ? "ant-menu-item-selected" : ""}>Store</Menu.Item>
        }
        {!menuPermission &&
        <Menu.Item
          key="/settings"
          onClick={() => {
            push("/settings");
          }}
          className={setActiveClass(selectedKey, '/settings') ? "ant-menu-item-selected" : ""}>Settings</Menu.Item>}
        {/* <Menu.Item
          key="/reports"
          onClick={() => {
            push("/reports");
          }}
          className={setActiveClass(selectedKey, '/reports') ? "ant-menu-item-selected" : ""}>Reports</Menu.Item> */}


        {user && user.defaultRole === 'admin' &&
          <>
            <Menu.Item
              key="/master"
              onClick={() => {
                push("/master");
              }}
              className={setActiveClass(selectedKey, '/master') ? "ant-menu-item-selected" : ""} >Master</Menu.Item>

            <Menu.Item
              key="/orgs"
              onClick={() => {
                push("/orgs");
              }}
              className={setActiveClass(selectedKey, '/orgs') ? "ant-menu-item-selected" : ""}>Companies</Menu.Item>
          </>
        }



      </Menu>
      <span style={{ alignContent: "flex-end", paddingRight: "10px" }}>
        <Dropdown overlay={logOutMenu} placement="bottomRight" >
          <a onClick={e => e.preventDefault()}>
            {user.name && (
              <Text ellipsis>
                {user.name}
              </Text>
            )}
            {' '}
            <DownOutlined />
            {' '}
          </a>
        </Dropdown>
      </span>



    </AntdLayout.Header>
  ) : null;
};
