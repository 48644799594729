
import { IResourceComponentsProps, useExport } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons
} from "@pankod/refine-antd";
import { IOrg } from "interfaces";
import { CreateOrg } from "./create";
import { EditOrg } from "./edit";
const { PlusOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } = Icons;


export const OrgsList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, tableQueryResult } = useTable<IOrg>({
        resource: "ob_orgs",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: ["id", "name", "address", "created_at"],
        },
    });

    let datacount = tableQueryResult?.data?.total;

    const { formProps, modalProps, show } = useModalForm({
        resource: "ob_orgs",
        action: "create",
        redirect: false,
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,

    } = useModalForm({
        resource: "ob_orgs",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "name", "address", "created_at"],
        },
    });

    const { triggerExport, isLoading } = useExport<IOrg>();


    return (
        <List
            title="Companies"
            pageHeaderProps={{

                extra: [
                    //  <ExportButton onClick={triggerExport} loading={isLoading} />,
                    <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" children="Add Org" icon={<PlusOutlined />} onClick={() => show()} />,
                ],

            }}
        >
  
            <span>Records: {datacount}</span>

            <Table style={{ marginTop: '5px' }} {...tableProps} rowKey="id" size="small">

                <Table.Column dataIndex="name" title="Name" />
                <Table.Column dataIndex="address" title="Address" />
                <Table.Column
                    dataIndex="created_at"
                    title="Created At"
                    render={(value) => <DateField value={value} format="LLL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                <Table.Column<IOrg>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                                onClick={() => editShow(record.id)}
                            />
                            <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
            <CreateOrg modalProps={modalProps} formProps={formProps} />
            <EditOrg modalProps={editModalProps} formProps={editFormProps} />

        </List>
    );
};
