import {
    Form,
    Input,
    Divider,
    ModalProps,
    FormProps,
    Tabs,
    Modal,
    Button,
    Typography,
    InputNumber, Select, useSelect,
    Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
  } from "@pankod/refine-antd";
  import { IResourceComponentsProps, useExport, useList, useNotification } from "@pankod/refine-core";
  import { Editor } from '@tinymce/tinymce-react';
  import { Col, Row, DatePicker } from 'antd';
  import {
    List,
  } from "@pankod/refine-antd";
  import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
  import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
  import { message, Upload } from 'antd';
  import type { CheckboxChangeEvent } from 'antd/es/checkbox';
  import { useGetIdentity } from "@pankod/refine-core";
  import { IProduct, IOrg, ICategory, IbrandProduct, IPrice, ICustomerGroup, AccountStatus } from "interfaces";
  import React, { useContext, useEffect, useRef, useState } from 'react';
  import { GET_ALL_CUSTOMER_GRPS_WITH_ORGID, GET_ALL_PAYMENT_OPTIONS, UPDATE_CUSTOMERS, UPDATE_CUSTOMER_GROUPS } from "query";
  import { nhost } from "utility";
  import { jsonConverter } from "components/helpers/jsonConverter";
  import './style.css'
  
  type EditCustomerProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    closeEditModal: () => void
    editModalVisible: boolean,
    tableQueryResult: any,
  };
  
  const { Text, Title } = Typography;
  const { TabPane } = Tabs;
  const { TextArea } = Input;
  const { Dragger } = Upload;
  
  const onChange = (key: string) => {
  };
  
  export const ShowCustomer: React.FC<EditCustomerProps> = ({
    modalProps,
    formProps,
    closeEditModal,
    editModalVisible,
    tableQueryResult,
  }) => {
    const { data: user } = useGetIdentity();
    const { open, close } = useNotification();
    
    const userId = user?.metadata?.org_id;
  
    const [checkAddress, setCheckAddress] = useState(true);
    // const [checkboxEmail, setCheckboxEmail] = useState(false);
    // const [checkboxActive, setCheckboxActive] = useState(false);
    const [checkboxDisableOrder, setCheckboxDisableOrder] = useState(false);
    const [checkboxOrderXls, setCheckboxOrderXls] = useState(false);
    const [checkboxOrderPdf, setCheckboxOrderPdf] = useState(false);
    const [checkboxReceiveEmail, setCheckboxReceiveEmail] = useState(false);
    const [specifyActivity, setSpecifyActivity] = useState<any>();
    const [customerGrps, setCustomerGrps] = useState<any>([]);
    const [selectedCusGrp, setSelectedCusGrp] = useState<any>([]);
    const [startCusGrp, setStartCusGrp] = useState<any>([]);
    const [onlinePay, setOnlinePay] = useState<any>(false);
    const [paymentOptions, setPaymentOptions] = useState<any>([]);
    const formRef = useRef<any>(null)
    const [statusCode, setStatusCode] = useState<any>();
    const [status, setStatus] = useState<any>();
  
    useEffect(()=>{
      setStatusCode(formProps?.initialValues?.sage_acc_status_number)
    },[formProps])
    
  
    const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
      resource: "ob_orgs",
      optionLabel: "name",
      optionValue: "id",
      metaData: {
        fields: ["id", "name"],
      },
      sort: [{ field: "name", order: "asc", },]
    });
  
    const { selectProps: categorySelectProps } = useSelect<ICategory>({
      resource: "categories",
      fetchSize: 1000,
      optionLabel: "name",
      optionValue: "id",
      metaData: {
        fields: ["id", "name"],
      },
      sort: [{ field: "name", order: "asc", },]
    });
  
    const { selectProps: priceListSelectProps } = useSelect<IPrice>({
      resource: "pricelists",
      fetchSize: 1000,
      optionLabel: "name",
      optionValue: "id",
      filters: [
        {
          field: "ob_org_id",
          operator: "eq",
          value: userId,
        },
      ],
      metaData: {
        fields: ["id", "name"],
      },
      sort: [{ field: "name", order: "asc", },]
    });
    
    const { data: tableData } = useList<AccountStatus>({
      resource: "customer_account_status",
      metaData: {
        fields: ["id", "account_status_name", "sage_status_code"],
      }
    });
  
    const { selectProps: accountStatusNameProps } = useSelect<AccountStatus>({
      resource: "customer_account_status",
      optionLabel: "sage_status_code",
      optionValue: "sage_status_code",
      metaData: {
        fields: ["id", "account_status_name", "sage_status_code"],
      }
    });
    
  
    const { selectProps: OBBrandSelectProps } = useSelect<IbrandProduct>({
      resource: "brands",
      fetchSize: 1000,
      optionLabel: "name",
      optionValue: "id",
      metaData: {
        fields: ["id", "name"],
      },
      sort: [{ field: "name", order: "asc", },]
    });
    
    let vatFilters:any = [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ];
  
    const { selectProps: taxSelectProps } = useSelect<IPrice>({
      resource: "taxes",
      fetchSize: 1000,
      optionLabel: "name",
      optionValue: "id",
      filters: vatFilters,
      onSearch: (value:any) => [
        {
          field: "name",
          operator: "contains",
          value: "%" + value + "%",
        },
      ],
      metaData: {
        fields: ["id", "name"],
      },
      sort: [{ field: "name", order: "asc", },]
    });
    
    const { selectProps: chargeSelectProps } = useSelect<IPrice>({
      resource: "customer_checkout_addititonal_charges",
      optionLabel: "charges_name",
      optionValue: "charges_name",
      filters: vatFilters,
      metaData: {
        fields: ["id", "charges_name"],
      },
      sort: [{ field: "charges_name", order: "asc" },]
    });
  
    const getAllCustomerGrps = () => {
      GET_ALL_CUSTOMER_GRPS_WITH_ORGID(userId).then((res:any) => {      
        setCustomerGrps(() => []);
        setCustomerGrps(res?.customer_groups);
      })
    }
  
    const getAllPaymentOptions = () => {
      GET_ALL_PAYMENT_OPTIONS(userId).then((res:any) => {      
        setPaymentOptions(() => []);
        setPaymentOptions(res?.payment_options);
      })
    }
    useEffect(() => {
      if (formProps?.initialValues && customerGrps) {
        const newSelectedCusGrp = customerGrps.reduce((acc:any, res:any) => {
          const ids = res?.customer_ids?.split(",");
          if (ids.includes(formProps?.initialValues?.id) && !acc.includes(res?.id)) {
            acc.push(res?.id);
          }
          return acc;
        }, []);
        setSelectedCusGrp(newSelectedCusGrp);
        setStartCusGrp(newSelectedCusGrp)
      }
      setOnlinePay(formProps?.initialValues?.enable_pay_now)
    }, [formProps?.initialValues]);
    
    useEffect(() => {
      if(userId){
        getAllCustomerGrps();
        getAllPaymentOptions();
      }
    },[userId])
  
    const onChangeCheckbox = (e: CheckboxChangeEvent) => {
      setCheckAddress(e.target.checked);
    };
    const onChangeCheckboxEmail = (e: CheckboxChangeEvent) => {
      // setCheckboxEmail(e.target.checked);
    };
    const onChangeCheckboxActive = (e: CheckboxChangeEvent) => {
      // setCheckboxActive(e.target.checked);
    };
    const onChangeCheckboxDisableOrder = (e: CheckboxChangeEvent) => {
      setCheckboxDisableOrder(e.target.checked);
    };
   
    const onChangeCheckboxOrderXls = (e: CheckboxChangeEvent) => {
      setCheckboxOrderXls(e.target.checked);
    };
    const onChangeCheckboxOrderPdf = (e: CheckboxChangeEvent) => {
      setCheckboxOrderPdf(e.target.checked);
    };
    const onChangeCheckboxReceiveEmail = (e: CheckboxChangeEvent) => {
      setCheckboxReceiveEmail(e.target.checked);
    };
  
    const onChange = (e: any) => {
      setSpecifyActivity(e)
    }
  
    const { Option } = Select;
  
    const prefixSelector = (
      <Form.Item name="prefix" noStyle>
        <Select style={{ width: 75 }}>
          <Option value="86">+86</Option>
          <Option value="87">+87</Option>
        </Select>
      </Form.Item>
    );
  
    const handleSelectChange = (value: any) => {
      let copyValue: any = [...value]?.filter((e) => e);
      setSelectedCusGrp(copyValue);
    };
  
    const handleProductSearch = (customerGrpsearch: string) => {
  
      if (customerGrpsearch?.length > 0) {
        const filterProduct = customerGrps?.filter((cusGrp: any) => {
          return cusGrp?.group_name?.toLowerCase().includes(customerGrpsearch?.toLocaleLowerCase())
        })
        if (filterProduct?.length != 0) {
          setCustomerGrps([...filterProduct])
        }
        else {
          getAllCustomerGrps();
        }
      } else {
        getAllCustomerGrps();
      }
    };  
  
  const createCustomer = (e: any) => {
  
  const customerId = formProps.initialValues?.id;
  // e.phone = e.phone ?? null
  // let Objects = e
  // UPDATE_CUSTOMERS(customerId,Objects).then((res:any) => {
  //   console.log(res);
  
   e.ob_org_id = user?.metadata?.org_id;
   e.createdBy = user?.id;
   e.specify_activity = e?.specify_activity ?? "empty";
   e.website = e?.website ?? "empty";
   e.notify_customer_email = e?.notify_customer_email ?? "false";
   e.disable_ordering = e?.disable_ordering ?? "false";
   e.discount_rate = e?.discount_rate ?? 0;
   e.admin_description = e?.admin_description ?? "empty";
   e.customer_reference_code = e?.customer_reference_code ?? "empty";
   e.address_line_2 = e?.address_line_2 ?? "empty";
   e.privacy_group = e?.privacy_group ?? "empty";
   e.min_order_value = e?.min_order_value ?? 0;
   e.postal_code = e?.postal_code ?? "empty";
   e.vat_number = e?.vat_number ?? "empty";
   e.address = e.address ?? "";
   e.Price_list = e.Price_list ?? "";
   e.city = e.city ?? "empty";
   e.province = e.province ?? "empty";
   e.country = e.country ?? "empty";
   e.company_number = e.company_number ?? null;
   e.phone = e.phone ?? null;
   e.sage_acc_status_number = statusCode;
   e.sage_acc_status = status;
   e.checkout_additional_charges = e.checkout_additional_charges?.length > 0 ? jsonConverter(e.checkout_additional_charges) : null
  console.log(e);
  
   const CUSTOMERMUTATIONAPI = `mutation MyMutation {
    update_customers(_set: { name:"${e.name}",address:"${e.address}", activity:"${e.activity}",
    specify_activity:"${e.specify_activity}",email:"${e.email}",price_list:"${e.price_list}",address_line_2:"${e.address_line_2}",
    city:"${e.city}",province:"${e.province}",country:"${e.country}",website:"${e.website}",customer_reference_code:"${e.customer_reference_code}",sage_acc_status: "${e.sage_acc_status}",
    privacy_group:"${e.privacy_group}",vat_number:"${e.vat_number}",admin_description:"${e.admin_description}",sage_acc_status_number:"${e.sage_acc_status_number}",
    company_number:"${e.company_number}",phone:"${e.phone}", discount_rate:"${e.discount_rate}",min_order_value:"${e.min_order_value}",postal_code:"${e.postal_code}",
    is_active: ${e?.is_active}, payment_options: ${e.enable_pay_now == true ? jsonConverter(e.payment_options) : null},
    enable_pay_now: ${e.enable_pay_now != undefined || null ? `"${e.enable_pay_now}"` : false},
    is_show_price_list: ${e.is_show_price_list != undefined || null ? `"${e.is_show_price_list}"` : false},
    notify_customer_email: ${e.notify_customer_email != undefined || null ? `"${e.notify_customer_email}"` : false},
    disable_ordering: ${e.disable_ordering != undefined || null ? `"${e.disable_ordering}"` : false},
    checkout_additional_charges: ${e.checkout_additional_charges}, custom_alert: "${e.custom_alert}"
  },where: { id: { _eq: "${customerId}"} } ){
      affected_rows
    }
  }
  `
     nhost.graphql.request(CUSTOMERMUTATIONAPI)
       .then((response: any) => {
        if(response?.data?.update_customers){
          const customerGrpUpdate = () => {
            const findCusGrp = customerGrps?.filter((grp:any) => {
              const addCusId = selectedCusGrp?.find((slect:any) => {
                if(slect == grp?.id){
                  const cusGrpId = grp?.id
                  const idToAdd = formProps?.initialValues?.id
                  const existingIdsSet:any = new Set(grp.customer_ids.split(","));
                  existingIdsSet.add(idToAdd);
                  const updatedCustomerIds = [...existingIdsSet].join(",");
                  UPDATE_CUSTOMER_GROUPS(cusGrpId, updatedCustomerIds)
                  .then(() => {
                  })
                  .catch((error:any) => {
                    console.error("Error updating customer_ids:", error.message);
                  });
                }
              });
        
              const valuesNotInStartCusGrp = startCusGrp.filter((value:any) => !selectedCusGrp.includes(value));
              if(valuesNotInStartCusGrp?.length > 0){
              const removeCusId = valuesNotInStartCusGrp?.find((notSlect:any) => {
                if(notSlect == grp?.id){
                  const cusGrpId = grp?.id
                  const idToDelete = formProps?.initialValues?.id
        
                  const existingIdsSet:any = new Set(grp.customer_ids.split(","));
                  existingIdsSet.delete(idToDelete);
                  const updatedCustomerIds = [...existingIdsSet].join(",");
                  UPDATE_CUSTOMER_GROUPS(cusGrpId,updatedCustomerIds)
                    .then(() => {
                    })
                    .catch((error:any) => {
                      console.error("Error updating customer_ids:", error.message);
                    });
                }
              })
            }
            })
            tableQueryResult?.refetch();
            closeEditModal();
            open?.({
              key: "success",
              type: "success",
              message: "",
              
              description: `Customer has been updated`,
            });
            setTimeout(() => {
              close("success");
            }, 5000);
          }
         customerGrpUpdate();
        }
        else{
          console.log("error");   
          open?.({
            key: "error",
            type: "error",
            message: "",
            description: `Something Went Wrong`,
          });
          setTimeout(() => {
            close("error");
          }, 5000);
        }
       });
   }
  
   const handlePayOnline = () => {    
     setOnlinePay(!onlinePay)
   }
    
   const validateCompanyName = (_:any, value:any) => {
    if (!/^[\w+.-]+@\w+(\.\w+)*(\s*,\s*[\w+.-]+@\w+(\.\w+)*)*$/.test(value)) {
      return Promise.reject('Please enter valid email address');
    }
    return Promise.resolve();
  };
  
  const handleSelect = (e:any)=>{
    const filteredStatus = tableData?.data?.filter((param:any) => param?.sage_status_code === e);
  
    if (filteredStatus) {
        setStatusCode(filteredStatus[0]?.sage_status_code);
        setStatus(filteredStatus[0]?.account_status_name);
    } else {
        setStatusCode(""); // handle the case when no matching status is found
        setStatus("");
    }
  }
    return (
      <>
        <Modal
          {...modalProps}
          footer = {false}
          visible={editModalVisible}
          onCancel={closeEditModal}
          title="Show Customer"
        >
          <Form
            {...formProps}
            ref={formRef}
            // name="basic"
            wrapperCol={{ span: 23 }}
            // onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            onFinish={(e: any) => {
              createCustomer(e);
            }}
          >
            <Tabs defaultActiveKey="1" onChange={onChange}>
              <TabPane tab="Customer details" key="1">
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      label="Customer Name"
                      
                    >
                      <Input
                        readOnly
                        placeholder="Enter the customer name"
                        onChange={(e) => onChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    {/* <Form.Item
                    name="fullname"
                    label="Full Name"
                    rules={[{ required: true, message: 'Please input your Full Name!' }]}>
  
                    <Input placeholder="Enter the Full Name" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item> */}
  
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="Activity"
                          name="activity"
                          
                          style={{ width: "100%" }}
                        >
                          <Select defaultValue="Others" onChange={onChange} disabled = {true}>
                            <Select.Option value="Others" name="activity">
                              Others
                            </Select.Option>
                            <Select.Option value="Retailer" name="activity">
                              Retailer
                            </Select.Option>
                            <Select.Option value="Distributor" name="activity">
                              Distributor
                            </Select.Option>
                            <Select.Option value="Vip" name="activity">
                              Vip
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="specify_activity"
                          label="Specify activity"
                          
                        >
                          <Input readOnly/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      name="email"
                      label="Email"
                      
                      // rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                      <Input
                        placeholder="Enter the email"
                        onChange={(e) => onChange}
                        style={{ width: "100%" }}
                        readOnly
                        type="email"
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                      />
                    </Form.Item>
                    {/* <Form.Item
                      name="notify_customer_email"
                      valuePropName="checked"
                    >
                      <Checkbox onChange={onChangeCheckboxEmail} disabled = {true}>
                        Notify customer by email
                      </Checkbox>
                    </Form.Item>
                    <Form.Item name="is_active" valuePropName="checked">
                      <Checkbox onChange={onChangeCheckboxActive} disabled = {true}>
                        Is active
                      </Checkbox>
                    </Form.Item>
                    <Form.Item name="disable_ordering" valuePropName="checked">
                      <Checkbox onChange={onChangeCheckboxDisableOrder} disabled = {true}>
                        Disable Ordering
                      </Checkbox>
                    </Form.Item>
                    <Form.Item name="is_show_price_list" valuePropName="checked">
                      <Checkbox disabled = {true}>Enable PriceList</Checkbox>
                    </Form.Item> */}
                    {/* <Form.Item  name="enable_pay_now" valuePropName="checked">
                    <Checkbox>Enable Online Pay Option</Checkbox>
                  </Form.Item> */}
                    {user?.defaultRole === "admin" ? (
                      <Form.Item
                        label="Price list"
                        name="price_list"
                        // rules={[{ required: true }]}
                        style={{ width: "100%" }}
                      ></Form.Item>
                    ) : (
                      ""
                    )}
                    <Form.Item
                      label="Price list"
                      name="price_list"
                      // rules={[{ required: true }]}
                      style={{ width: "100%" }}
                    >
                      <Select {...priceListSelectProps} showSearch={false} disabled = {true}/>
                    </Form.Item>
                    {/* <Form.Item
                      name="min_order_value"
                      label="Minimum order value"
                      rules={[]}
                    >
                      <Input
                        type="number"
                        placeholder="Enter the minimum order value"
                        onChange={(e) => onChange}
                        style={{ width: "100%" }}
                        readOnly
                      />
                    </Form.Item> */}
                    {/* <Form.Item
                      name="phone"
                      label="Phone"
                      // rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                      <Input
                        className="custom-input"
                        type="number"
                        addonBefore={prefixSelector}
                        placeholder="Enter the phone number"
                        onChange={(e) => onChange}
                        style={{ width: "100%" }}
                        readOnly
                      />
                    </Form.Item> */}
                    <Form.Item
                      name="address"
                      label="Address"
                      // rules={[{ required: true, message: 'Please input your address!' }]}
                    >
                      <Input
                        placeholder="Enter the phone address"
                        onChange={(e) => onChange}
                        style={{ width: "100%" }}
                        readOnly
                      />
                    </Form.Item>
                    <Form.Item
                      name="address_line_2"
                      label="Address line 2 "
                      rules={[]}
                    >
                      <Input
                        placeholder="Enter the phone address line 2"
                        onChange={(e) => onChange}
                        style={{ width: "100%" }}
                        readOnly
                      />
                    </Form.Item>
                    <Form.Item name="city" label="City" rules={[]}>
                      <Input
                        placeholder="Enter the city"
                        onChange={(e) => onChange}
                        style={{ width: "100%" }}
                        readOnly
                      />
                    </Form.Item>
  
                    <Form.Item name="province" label="Province" rules={[]}>
                      <Input
                        placeholder="Enter the phone province"
                        onChange={(e) => onChange}
                        style={{ width: "100%" }}
                        readOnly
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Country"
                      name="country"
                      rules={[]}
                      style={{ width: "100%" }}
                    >
                      <Select defaultValue="India" onChange={onChange} disabled = {true}>
                        <Select.Option value="India" name="country">
                          India
                        </Select.Option>
                        <Select.Option value="USA" name="country">
                          USA
                        </Select.Option>
                        <Select.Option value="UK" name="country">
                          UK
                        </Select.Option>
                        <Select.Option value="Dubai" name="country">
                          Dubai
                        </Select.Option>
                        <Select.Option value="Canada" name="country">
                          Canada
                        </Select.Option>
                      </Select>
                    </Form.Item>
  
                    <Form.Item name="postal_code" label="Postal Code" rules={[]}>
                      <Input
                        placeholder="Enter the phone postal code"
                        onChange={(e) => onChange}
                        style={{ width: "100%" }}
                        readOnly
                      />
                    </Form.Item>
  
                    {/* <Form.Item name="website" label="Website">
                      <Input
                        placeholder="Enter the phone postal website"
                        onChange={(e) => onChange}
                        style={{ width: "100%" }}
                        readOnly
                      />
                    </Form.Item>
  
                    <Form.Item
                      name="company_number"
                      label="Company Number"
                      rules={[]}
                    >
                      <Input
                        className="input-number-no-spinner"
                        type="number"
                        placeholder="Enter the postal company number"
                        onChange={(e) => onChange}
                        style={{ width: "100%" }}
                        readOnly
                      />
                    </Form.Item> */}
  
                    {/* <Form.Item name="vat_number" label="VAT Number" rules={[]}>
                      <Input
                        placeholder="Enter the VAT Number"
                        onChange={(e) => onChange}
                        style={{ width: "100%" }}
                        readOnly
                      />
                    </Form.Item> */}
  
                    <Form.Item
                      name="customer_reference_code"
                      label="Customer Reference Code"
                    >
                      <Input
                        placeholder="Enter the customer reference code"
                        onChange={(e) => onChange}
                        readOnly
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
  
                    {/* <Form.Item
                    name="privacy_group"
                    label="Customer Groups">
  
                    <Input placeholder="Enter the privacy group" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item> */}
                    {/* <Form.Item
                      name="privacy_group"
                      label="Customer Groups"
                      rules={[]}
                    >
                      <div style={{ display: "flex" }}>
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: "100%" }}
                          placeholder=""
                          disabled = {true}
                          onSearch={handleProductSearch}
                          filterOption={false}
                          defaultValue={selectedCusGrp}
                          onChange={handleSelectChange}
                        >
                          {customerGrps?.map((e: any, i: any) => {
                            return <Option key={e?.id}>{e?.group_name}</Option>;
                          })}
                        </Select>
                      </div>
                    </Form.Item> */}
                    
                <Form.Item name="default_tax_id" label="Default Tax Id" style={{pointerEvents: "none"}}>
                  <Select {...taxSelectProps} disabled = {true} />
                </Form.Item>
                <Form.Item name="discount_type" label="Discount Type">
                  <Input type="text" readOnly />
                </Form.Item>
                <Form.Item name="discount_type" label="Discount Rate">
                  <Input type="text" readOnly />
                </Form.Item>
                  </Col>
                </Row>
                {/* <hr />
                <Row>
                  <Col span={12}>
                    <Form.Item name="admin_description" label="Admin Description">
                      <TextArea rows={4} style={{ width: "100%" }} readOnly/>
                    </Form.Item>
                  </Col>
                </Row> */}
              </TabPane>
              {/* <TabPane tab="Payment Options" key="7">
                <Form.Item name="enable_pay_now" valuePropName="checked">
                  <Checkbox onChange={() => handlePayOnline()} disabled = {true}>
                    Enable Online Pay Option
                  </Checkbox>
                </Form.Item>
                {onlinePay ? (
                  <Form.Item
                    label="Payment type"
                    name="payment_options"
                    rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <Select
                      placeholder="Select Payment type"
                      onChange={onChange}
                      mode="multiple"
                      disabled = {true}
                    >
                      {paymentOptions?.length > 0 ? (
                        paymentOptions?.map((pay: any) => {
                          return (
                            <Select.Option value={pay?.name}>
                              {pay?.name}
                            </Select.Option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </Select>
                  </Form.Item>
                ) : (
                  <></>
                )}
              </TabPane> */}
              <TabPane tab="Customer Account Details" key="8">
                <Form.Item name="sage_acc_status_number" label="Sage Account Status">
                  <Select showSearch = {false} onChange={(e)=>handleSelect(e)} disabled ={true}>
                    {
                      tableData?.data?.map((option: any, index: any)=>{
                        return(
                        <Option key={index} value={option?.sage_status_code}>
                          {option?.sage_status_code} - {option?.account_status_name}
                        </Option>)
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item name="credit_limit" label="Credit Limit">
                  <Input type="text" readOnly />
                </Form.Item>
                <Form.Item name="credit_position" label="Credit Position">
                  <Input type="text" readOnly />
                </Form.Item>
                <Form.Item name="total_invoice_amount" label="Total Invoice Amount">
                  <Input type="text" readOnly />
                </Form.Item>
                <Form.Item name="total_credit_amount" label="Total Credit Amount">
                  <Input type="text" readOnly />
                </Form.Item>
                <Form.Item name="total_payment_amount" label="Total Payment Amount">
                  <Input type="text" readOnly />
                </Form.Item>
                <Form.Item name="total_balance_amount" label="Total Balance Amount">
                  <Input type="text" readOnly />
                </Form.Item>
              </TabPane>
              <TabPane tab="Address" key="2">
                <div>
                  <Form.List name="addresses">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <div>
                              <Form.Item
                                {...restField}
                                name={[name, "address"]}
                                label="Address Line 1"
                                rules={[{ required: true }]}
                              >
                                <Input placeholder="Address Line 1" readOnly/>
                              </Form.Item>
                              {/* <Form.Item
                              {...restField}
                              name={[name, 'address_line_2']}
                              label="Address Line 2"
                              rules={[{ required: true, }]} >
                              <Input placeholder="Address Line 2" />
                            </Form.Item> */}
                            </div>
                            <div>
                              <Form.Item
                                {...restField}
                                name={[name, "city"]}
                                label="City Province"
                              >
                                <Input placeholder="city province" readOnly />
                              </Form.Item>
                              {/* <Form.Item
                              {...restField}
                              name={[name, 'city']}
                              label="City Province"
                            >
                              <Input placeholder="city province" />
                            </Form.Item> */}
                            </div>
                            <Form.Item
                              {...restField}
                              name={[name, "country"]}
                              label="Country"
                              style={{ width: "200px" }}
                              rules={[{ required: true }]}
                            >
                              <Select disabled = {true}>
                                <Select.Option value="india" name="country">
                                  India
                                </Select.Option>
                                <Select.Option value="US" name="country">
                                  US
                                </Select.Option>
                                <Select.Option value="russia" name="country">
                                  Russia
                                </Select.Option>
                                <Select.Option value="china" name="country">
                                  China
                                </Select.Option>
                                `{" "}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "postal_code"]}
                              label="Postal Code"
                              rules={[]}
                            >
                              <Input placeholder="Postal Code" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "is_active"]}
                              valuePropName="checked"
                            >
                              <Checkbox disabled = {true}>Active</Checkbox>
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        {/* <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => add()}
                            block
                            icon={false}
                            style={{ width: "16%" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PlusOutlined style={{ marginRight: "5px" }} /> Add
                              Address
                            </div>
                          </Button>
                        </Form.Item> */}
                      </>
                    )}
                  </Form.List>
                </div>
              </TabPane>
              {user?.defaultRole == "developer" ? (
                <>
                  <TabPane tab="Billing" key="3">
                    <Row>
                      <Col span={12}>
                        <Form.Item>
                          <Checkbox
                            checked={checkAddress}
                            onChange={onChangeCheckbox}
                            disabled = {false}
                          >
                            Same as contact details
                          </Checkbox>
                        </Form.Item>
                        {checkAddress == false ? (
                          <>
                            <Form.Item
                              name="address"
                              label="Address"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your address!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter the phone address"
                                onChange={(e) => onChange}
                                style={{ width: "100%" }}
                                readOnly
                              />
                            </Form.Item>
                            <Form.Item
                              name="address_line_2"
                              label="Address line 2"
                              rules={[]}
                            >
                              <Input
                                placeholder="Enter the phone address line 2"
                                onChange={(e) => onChange}
                                style={{ width: "100%" }}
                                readOnly
                              />
                            </Form.Item>
  
                            <Form.Item
                              name="city"
                              label="City"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your city!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter the phone city"
                                onChange={(e) => onChange}
                                readOnly
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
  
                            <Form.Item
                              name="province"
                              label="Province"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your province!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter the phone province"
                                onChange={(e) => onChange}
                                readOnly
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
  
                            <Form.Item
                              label="Country"
                              name="country"
                              rules={[]}
                              style={{ width: "100%" }}
                            >
                              <Select defaultValue="India" onChange={onChange} disabled = {true}>
                                <Select.Option value="India" name="country">
                                  India
                                </Select.Option>
                                <Select.Option value="USA" name="country">
                                  USA
                                </Select.Option>
                                <Select.Option value="UK" name="country">
                                  UK
                                </Select.Option>
                                <Select.Option value="Dubai" name="country">
                                  Dubai
                                </Select.Option>
                                <Select.Option value="Canada" name="country">
                                  Canada
                                </Select.Option>
                              </Select>
                            </Form.Item>
  
                            <Form.Item
                              name="postal_code"
                              label="Postal Code"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your postal code!",
                                },
                              ]}
                            >
                              <Input
                                readOnly
                                placeholder="Enter the phone postal code"
                                onChange={(e) => onChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
  
                            <Form.Item
                              name="phone"
                              label="Phone"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your phone number!",
                                },
                              ]}
                            >
                              <Input
                                readOnly
                                placeholder="Enter the phone number"
                                onChange={(e) => onChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
  
                            <Form.Item
                              name="name"
                              label="Company Name"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please input your postal company name!",
                                },
                              ]}
                            >
                              <Input
                                readOnly
                                placeholder="Enter the postal company name"
                                onChange={(e) => onChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
  
                            <Form.Item
                              name="fullname"
                              label="Full Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your postal full name!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter the postal full name"
                                readOnly
                                onChange={(e) => onChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
  
                            {/* <Form.Item
                              name="vat_number"
                              label="VAT Number"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your VAT Number!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter the VAT Number"
                                readOnly
                                onChange={(e) => onChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item> */}
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Sales Rep" key="4">
                    <div>
                      <Form.List name="sales_rep">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space
                                key={key}
                                style={{ display: "flex", marginBottom: 8 }}
                                align="baseline"
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, "sales_rep_name"]}
                                  label="Sales Rep"
                                  style={{ width: "200px" }}
                                >
                                  <Select disabled = {false}>
                                    <Select.Option
                                      value="Please select value"
                                      name="sales_rep"
                                    >
                                      please select the value
                                    </Select.Option>
                                    `{" "}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "commission"]}
                                  label="Commission"
                                >
                                  <Input type="number" defaultValue="0.0" />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                />
                              </Space>
                            ))}
                            {/* <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => add()}
                                block
                                icon={false}
                                style={{ width: "16%" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <PlusOutlined style={{ marginRight: "5px" }} />{" "}
                                  Add Sales Rep
                                </div>
                              </Button>
                            </Form.Item> */}
                          </>
                        )}
                      </Form.List>
                    </div>
                  </TabPane>
                  <TabPane tab="Email Settings" key="5">
                    <Row>
                      <Col span={12}>
                        <Form.Item name="order_as_XLS" valuePropName="checked">
                          <Checkbox
                          disabled = {true}
                          // checked={checkboxOrderXls} onChange={onChangeCheckboxOrderXls}
                          >
                            Attach new order as XLS to email
                          </Checkbox>
                        </Form.Item>
                        <Form.Item name="order_as_PDF" valuePropName="checked">
                          <Checkbox
                          disabled = {true}
                          //  checked={checkboxOrderPdf} onChange={onChangeCheckboxOrderPdf}
                          >
                            Attach order as PDF to email
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          name="new_order_notification"
                          label="Email for new orders notification"
                          rules={[
                            {
                              required: true,
                              message: "Please input your primary color!",
                            },
                          ]}
                        >
                          <Input
                            readOnly
                            onChange={(e) => onChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                        <p>
                          You can add more than one email separated by commas. For
                          example
                          email1@example.com,email2@example.com,email3@example.com
                        </p>
  
                        <Form.Item
                          name="receive_email_notify"
                          valuePropName="checked"
                        >
                          <Checkbox
                          disabled = {true}
                          // checked={checkboxReceiveEmail} onChange={onChangeCheckboxReceiveEmail}
                          >
                            Receive email notifications
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          name="outgoing_email"
                          label="Bcc outgoing emails to customers"
                          // rules={[{ required: true, message: 'Please input your Bcc outgoing emails to customers!' }]}
                        >
                          <Input
                            onChange={(e) => onChange}
                            style={{ width: "100%" }}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Customer homepage products" key="6">
                    <div>
                      <p>
                        Products added in this section will appear in the customer
                        homepage (when logged in) for quick ordering
                      </p>
                      <Form.List name="customer_homepage_products">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space
                                key={key}
                                style={{ display: "flex", marginBottom: 8 }}
                                align="baseline"
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, "code"]}
                                  label="Code"
                                  style={{ width: "200px" }}
                                >
                                  <Input hidden />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "search_product"]}
                                  label="Product"
                                >
                                  <Input placeholder="Search" />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                />
                              </Space>
                            ))}
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => add()}
                                block
                                icon={false}
                                style={{ width: "35%" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <PlusOutlined style={{ marginRight: "5px" }} />{" "}
                                  Add Customer Homepage Product
                                </div>
                                Add Customer Homepage Product
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </div>
                  </TabPane>
                </>
              ) : (
                ""
              )}
              {/* <TabPane tab="Additional Charge" key= "6">
                <Form.Item
                  label="Additional Charge"
                  name="checkout_additional_charges"
                  style={{ width: "100%" }}
                >
                  <Select
                  disabled = {true}
                    placeholder="Select Charge type"
                    onChange={onChange}
                    mode="multiple"
                    {...chargeSelectProps}
                  />
                </Form.Item>
              </TabPane> */}
            </Tabs>
            <Form.Item name="updated_by" initialValue={user?.id}>
              <Input type="hidden" />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  };
  