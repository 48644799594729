import {useEffect, useState} from "react";
import {
    IResourceComponentsProps,
    useExport,
    CrudFilters,
    HttpError,
  } from "@pankod/refine-core";
import { useGetIdentity, useNotification } from "@pankod/refine-core";
import { Button, CreateButton, DateField, DatePicker, EditButton, Form, FormProps, Icons, Input, List, Popconfirm, RefreshButton, Space, Table, getDefaultSortOrder, useModalForm, useTable } from "@pankod/refine-antd"
import { IFaq, IPostFilterVariables } from "interfaces";
import { DELETE_ABOUTUS } from "query";
import { CreateAboutus } from "./create";
import { EditAboutus } from "./edit";
import { ReloadOutlined } from "@ant-design/icons";
const {
    PlusOutlined,
    MailOutlined,
    AppstoreOutlined,
    SettingOutlined,
    CheckOutlined,
    CloseOutlined,
  } = Icons;
  const { RangePicker } = DatePicker;

export const AboutusList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const { open, close } = useNotification()
  const userId = user?.metadata?.org_id;

  const [maxViewOrder, setMaxViewOrder] = useState<any>(0)


  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IFaq, HttpError, IPostFilterVariables>({
    resource: "aboutus",
    initialSorter: [
        {
            field: "created_at",
            order: "desc",
        },
    ],
    permanentFilter: [
        {
            field: "ob_org_id",
            operator: "eq",
            value: userId,
        },
    ],
    metaData: {
        fields: ["id", "title", "ob_org_id", "content", "is_show", "view_order", "created_at", "updated_at","content_banners",],
    },
    onSearch: (params) => {
        const filters: CrudFilters = [];
        const { name, created_at } = params;

        filters.push(
            {
                field: "title",
                operator: "contains",
                value: name !== '' ? name : null,
            },
        );

        return filters;
    },
});
let datacount = tableQueryResult?.data?.total;

const { formProps, modalProps, show , close: createClose } = useModalForm({
    resource: "aboutus",
    action: "create",
    redirect: false,
    autoSubmitClose: true,
    autoResetForm: true,
  });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,
        close: editClose
    } = useModalForm({
        resource: "aboutus",
        action: "edit",
        redirect: false,
        autoSubmitClose: true,
        autoResetForm: true,
        metaData: {
            fields: [
                "id", "title", "ob_org_id", "content", "is_show", "view_order", "created_at", "updated_at","content_banners",],
        },
    });

    const handleDelete = (data: any) => {
      DELETE_ABOUTUS(data).then((repsone) => {
          if (repsone) {
            open({
                key: "delete_success",
                type: "success",
                message: "",
                description: "Successfully Deleted"
            });
            setTimeout(() => {
              close("delete_success");
            }, 2000);
            tableQueryResult?.refetch();
          }
        });
      };

      useEffect(() => {
        if(tableProps?.dataSource){
        const maxViewOrder = tableProps?.dataSource?.reduce((max:any, item:any) => {
          const viewOrder = item.view_order;
          return viewOrder > max ? viewOrder : max;
        }, 0);
        if(maxViewOrder){
          setMaxViewOrder(maxViewOrder)
        }
      }
    },[tableProps]); 
    return (
        <List
            title="Orderbit About Us"
            pageHeaderProps={{
                extra: [
                <Filter formProps={searchFormProps} />,
                <RefreshButton
                  style={{ marginTop: "15px" }}
                  type="primary"
                  children={<div style={{ display: "flex", alignItems: "center" }}>
                    <ReloadOutlined style={{ marginRight: "5px" }} />
                    Refresh
                  </div>}
                  icon={false}
                  onClick={() => tableQueryResult?.refetch()}
                />,
                <CreateButton
                  style={{ marginTop: "15px" }}
                  type="primary"
                  children={<div style={{ display: "flex", alignItems: "center" }}>
                    <PlusOutlined style={{ marginRight: "5px" }} />
                    Add About Us Content
                  </div>}
                  icon={false}
                  resourceName="store/news"
                  onClick={() => show()}
                />,
              ],
            }}
        >
            <span>Records: {datacount}</span>

            <Table
                style={{ marginTop: "5px" }}
                {...tableProps}
                rowKey="id"
                size="small"
            >
                <Table.Column dataIndex="title" title="Title" />
                <Table.Column dataIndex="view_order" title="View Order" />
                <Table.Column
          title="Show"
          dataIndex="is_show"
          render={(value) =>
            value == true ? (
              <CheckOutlined style={{ color: "#6ead65" }} />
            ) : (
              <CloseOutlined style={{ color: "#ff7875" }} />
            )
          }
        />
        <Table.Column
          dataIndex="content"
          title="Content"
          render={(value) => (
            <span style={{ overflowWrap: "anywhere" }}>{value}</span>
          )}
        />
        <Table.Column
          dataIndex="created_at"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
        <Table.Column<IFaq>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                size="small"
                type="link"
                icon={null}
                recordItemId={record.id}
                resourceName="store/news"
                onClick={() => editShow(record.id)}
              />
              <Popconfirm
                title="Delete?"
                onConfirm={() => handleDelete(record.id)}
              >
                <a style={{ color: "red" }}>Delete</a>
              </Popconfirm>
            </Space>
          )}
        />
            </Table>

            <CreateAboutus modalProps={modalProps} formProps={formProps} viewOrderMax ={maxViewOrder} close={createClose}/>
            <EditAboutus modalProps={editModalProps} formProps={editFormProps} close={editClose}/>
        </List>
    )
}
const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    return (
      <Form layout="vertical" {...formProps} style={{ display: "flex" }}>
        <Form.Item label="Search" name="name">
          <Input
            allowClear
            placeholder="Title"
            prefix={<Icons.SearchOutlined />}
          />
        </Form.Item>
        <Form.Item style={{ marginLeft: "5px", marginTop: "21px" }}>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Form.Item>
      </Form>
    );
  };
