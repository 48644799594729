import { DateField, List, RefreshButton, Select, ShowButton, Space, Table, useModalForm, useSelect, useTable } from "@pankod/refine-antd"
import { IResourceComponents, useGetIdentity } from "@pankod/refine-core"
import { IInvoiceStatus } from "interfaces";
import { InvoiceDetailShow } from "./show";
import { useEffect, useState } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { GET_ORDERS } from "query";

export const InvoiceList: React.FC<IResourceComponents> = () => {
    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;
    const [showModal, setShowModal] = useState<boolean>(false);
    const [invoiceId, setInvoiceId] = useState<any>();
    const [favouriteFilters, setFavouriteFilters] = useState<any>([]);
    const [orders, setOrders] = useState<any>(null);

    const { tableProps, sorter, tableQueryResult } = useTable<IInvoiceStatus>({
        resource: "invoices",
        initialPageSize: 10,
        initialSorter: [{
            field: "created_at",
            order: "desc",
        }],
        permanentSorter: [{
            field: "created_at",
            order: "desc",
        }],
        permanentFilter: [
            ...favouriteFilters,
            {
                field: "ob_org_id",
                operator: "eq",
                value: userId,
            }
        ],
        metaData: {
            fields: ["id", "invoice_date", "orderids", "invoicetype",
                "price", "total", "status", "paymentmode", "isactive",
                "ob_org_id", "paymentreference", "created_at", "sage_invoice_no",
                "credit_amount", "advance_amount", "paid_amount", "is_paid", "outstanding",
            ]
        },
    });

    const { formProps: showFormProps, modalProps: showModalProps, show: ShowPopup } = useModalForm({
        resource: "invoices",
        redirect: false,
        action: "clone",
        metaData: {
            fields: ["id", "invoice_date", "orderids", "invoicetype",
                "price", "total", "status", "paymentmode", "isactive",
                "ob_org_id", "paymentreference", "created_at", "sage_invoice_no",
                "credit_amount", "advance_amount", "paid_amount", "is_paid", "outstanding",
            ]
        },
    });

    const { selectProps } = useSelect<any>({
        resource: "invoices",
        metaData: {
            fields: ["id", "orderids"],
        },
        optionLabel: "orderids",
        optionValue: "id",
        filters: [
            {
                field: "ob_org_id",
                operator: 'eq',
                value: userId,
            },
        ],

        onSearch: (value) => [
            {
                field: "orderids",
                operator: "contains",
                value: "%" + value + "%",
            },
            {
                field: "ob_org_id",
                operator: "eq",
                value: userId,
            },
        ],
    });

    useEffect(() => {
        if (invoiceId) {
            setFavouriteFilters([
                {
                    field: "id",
                    operator: "eq",
                    value: invoiceId,
                },
            ]);
        }
    }, [invoiceId]);

    const handleSearch = (e: any) => {
        setInvoiceId(e);
    };

    const handleOnClear = () => {
        setFavouriteFilters([]);
        setInvoiceId(null);
    };
    let datacount = tableQueryResult?.data?.total;
    const handleCloseShowModal = () => setShowModal(false);

    const getOrders = () => {        
        GET_ORDERS(userId).then((res:any) => {
          setOrders(res?.orders)
        })
      }

      useEffect(() => {
        if (userId) {
          getOrders();
        }    
      }, [userId])

    return (
        <List title="Invoices"
            pageHeaderProps={{
                extra: [
                    <Space>
                        <Select
                            placeholder="Select a OrderID"
                            style={{ width: 250, marginRight: 20, marginTop: 15 }}
                            onSelect={handleSearch}
                            clearIcon="*"
                            allowClear
                            value={invoiceId || null}
                            onClear={handleOnClear}
                            {...selectProps}
                        />
                        <RefreshButton
                        style={{ marginTop: "15px" }}
                        type="primary"
                        children={<div style={{ display: "flex", alignItems: "center" }}>
                            <ReloadOutlined style={{ marginRight: "5px" }} />
                            Refresh
                        </div>}
                        icon={false}
                        onClick={() => tableQueryResult?.refetch()} />,
                    </Space>
                ]
            }}
        >
            <span>Records: {datacount}</span>
            <Table {...tableProps} rowKey="id" size="small">
                <Table.Column title="Order ID" dataIndex="orderids" />
                <Table.Column title="Invoice Type" dataIndex="invoicetype" />
                <Table.Column title="Created At" dataIndex="created_at"
                    render={(value: any) => <DateField value={value} format="LL" />} />
                <Table.Column title="Price" dataIndex="total"
                    render={(value: any) => (<span>£{" "} {value?.toFixed(2)}</span>)} />

                <Table.Column title="Status" dataIndex="status" />
                <Table.Column title="Actions" align="center"
                    render={(value: any, record: any) => (
                        <Space>
                            <ShowButton resourceName={"orders"} hideText={true} style={{ border: "none" }}
                                onClick={() => {
                                    ShowPopup(record?.id);
                                    setShowModal(true);
                                }} />
                        </Space>
                    )}
                />
            </Table>
            <InvoiceDetailShow formProps={showFormProps} modalProps={showModalProps} showModalVisible={showModal} closeShowModal={handleCloseShowModal} orders={orders}/>
        </List>
    )
};