import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IOrderStatus, IOrg } from "interfaces";
import { useState } from "react";
const { TextArea } = Input;

type CreateProductStatusProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const CreateProductStatus: React.FC<CreateProductStatusProps> = ({
  modalProps,
  formProps,
}) => {
  const { data: user } = useGetIdentity();
  const [order, setOrder] = useState(false);
  const [view, setView] = useState(false);
  const { selectProps: OBOrderStatusSelectProps } = useSelect<IOrderStatus>({
    resource: "order_statuses",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name", "sort", "bg_color", "ob_org_id", "notify_customer"],
    },
    sort: [{ field: "name", order: "asc", },]
  });
  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const onChange = (key: string) => {
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setOrder(e.target.checked);
  };
  const onChangeViewCheckbox = (e: CheckboxChangeEvent) => {
    setView(e.target.checked);
  };
  return (
    <Modal {...modalProps} title="Create Product Status">
      <Form {...formProps}
        wrapperCol={{ span: 23 }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input your name!' }]}>

          <Input placeholder="Enter the name" onChange={(e) => onChange} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="view_order"
          label="View Order"
          style={{ marginTop: "20px" }}
        >

          <InputNumber placeholder="Enter the order count" defaultValue="0" onChange={(e) => onChange} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="can_order" valuePropName="checked"
          style={{ marginTop: "20px" }}
        >
          <Checkbox
          // name='can_order' checked={order} onChange={onChangeCheckbox}
          >Can Order?</Checkbox>
        </Form.Item>
        <Form.Item name="product_grid_view"
          style={{ marginTop: "20px" }}
        >
          <Checkbox
          //  name='product_grid_view' checked={view} onChange={onChangeViewCheckbox}
          >Show at product grid view</Checkbox>
        </Form.Item>
        <Form.Item
          name="bg_color"
          label="Background Color"
          rules={[{ required: true, }]}
          style={{ marginTop: "20px" }}
        >

          <Input onChange={(e) => onChange} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="text_color"
          label="Text Color"
          style={{ marginTop: "20px" }}
          rules={[{ required: true, }]}>

          <Input onChange={(e) => onChange} style={{ width: "100%" }} />
        </Form.Item>
        {user?.defaultRole === "admin" ?
          <Form.Item
            label="Orderbit Organisation"
            name="ob_org_id"
            rules={[{ required: true, }]}
            style={{ marginTop: "20px" }}
          >
            <Select {...OBOrgSelectProps} />
          </Form.Item> :
          <Form.Item
            name="ob_org_id"
            initialValue={user?.metadata?.org_id}
            style={{ marginTop: "20px" }}
          >
            <Input hidden />
          </Form.Item>
        }

        <Form.Item name="created_by"
          initialValue={user?.id}
        >
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
